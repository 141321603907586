import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { PolicyStatsModel } from '@cogent/shared/models/policies/policy-stats.model';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { Address, Entity, JobItemDetail, PhoneNumber, Policy, PolicyAdjustment, PolicyExclusion, PolicyOption, PolicyServiceOffering, PrepaidServiceFee, PromotionCodeSummary, Tag } from '@upkeeplabs/models/cogent';
import { SearchResultModel } from '@cogent/shared/models/common/search-result.model';
import { PolicyClaimMetricModel } from '@cogent/shared/models/service/policy-claim-metric.model';
import { PolicyExpenseByTradeModel } from '@cogent/shared/models/policies/policy-expense-by-trade.model';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { PolicyCoverageModel } from '@cogent/shared/models/sales/policy-coverage.model';
import { PolicyRenewalModel } from '@cogent/shared/models/policies/policy-renewal.model';
import { FileModel } from '@cogent/shared/models/common/file.model';
import { ClaimSummaryModel } from '@cogent/shared/models/service/claim-summary.model';
import { CompanyRegion } from '@cogent/shared/models/common/company-states-and-regions.model';
import { ExtendedPolicyServiceOffering, MarketingSource, PaymentSummary, PolicyCanceledReason, PolicyRenewalSummary, PolicySummaryV2, StripeCard } from "@upkeeplabs/models/cogent";
import { CancellationInfo } from "@cogent/shared/models/policies/cancellation-info.model";
import { WorkOrderSummaryClient } from '@cogent/client/shared/models/service/work-order-summary-client.model';
import { CoverageType, PlanClient } from "@cogent/client/shared/models/plans/plan-client.model";
import { PolicyHistoryItemClient } from '@cogent/client/shared/models/policies/policy-history-item-client.model';
import { UpdatePolicyCoverageArgs } from '@cogent/shared/models/policies/update-policy-coverage-args.model';
import { NewPolicy } from '@cogent/shared/models/sales/new-policy.model';


export class TaskSummary {
    constructor(public openTasks = 0, public completedTasks = 0) { }
}


@Injectable({ providedIn: 'root' })
export class PolicyApiService {


    constructor(private api: ApiService) { }

    saveUpdatedPolicyCoverage(args: UpdatePolicyCoverageArgs) {
        return this.api.postSingleDotNet(`Policy/update-coverage`, args);
    }

    savePortalPolicyCoverage(args: UpdatePolicyCoverageArgs) {
        return this.api.postSingleDotNet(`Policy/update-coverage-from-portal`, args);
    }

    getStreetViewUrl(policyId: string) {
        if (!policyId) {
            return null;
        }
        const url = ApiService.endPointDotNet + 'policy/' + policyId + '/StreetView';
        return url;
    }

    getPromotionCodeSummary(id: string) {
        return this.api.getSingleNode(`PromotionCodeSummary/${id}`, {}, () => new PromotionCodeSummary());
    }

    getPolicyFirstEffectiveDate(id: string) {
        return this.api.getSingleNode(`Policy/policy-start-date/${id}`);
    }

    generateRenewalBatch(ids: string[]) {
        return this.api.postSingleNode(`Policy/Renewal-Letters`, { policyIds: ids });
    }

    getPoliciesForPropertyAddressAndCustomer(propertyId: string, customerId: string) {
        return this.api.getArrayNode(`PolicySummaryV2`, { holderId_eq: customerId, propertyId_eq: propertyId }, () => new PolicySummaryV2());
    }

    getPoliciesByCustomerId(customerId: string) {
        const holder = this.api.getArrayNode(`PolicySummaryV2`, { holderId_eq: customerId, nextPolicyId_eq: '{{null}}' }, () => new PolicySummaryV2());
        return holder;
    }

    getPoliciesByCoHolderId(customerId: string) {
        const holder = this.api.getArrayNode(`PolicySummaryV2`, { coHolderId_eq: customerId, nextPolicyId_eq: '{{null}}' }, () => new PolicySummaryV2());
        return holder;
    }

    async getPoliciesForCustomer(customerId: string, customerName: string) {
        const holder = await this.api.getArrayNode(`PolicySummaryV2`, { holderId_eq: customerId, nextPolicyId_eq: '{{null}}' }, () => new PolicySummaryV2());
        const coholder = await this.api.getArrayNode(`PolicySummaryV2`, { coHolderName_eq: customerName, nextPolicyId_eq: '{{null}}' }, () => new PolicySummaryV2());
        //return holder;
        return holder.concat(coholder);
    }

    getHolderId(id: string) {
        return this.api.getSingleDotNet(`Policy/${id}/get-holder-id`);
    }

    hasDocument(id: string, type: string): Promise<boolean> {
        return this.api.getSingleDotNet(`Policy/${id}/has-document/${type}`);
    }

    uploadDocument(id: string, type: string, document: FileModel) {
        return this.api.postSingleDotNet(`Policy/${id}/upload-document/${type}`, document);
    }

    updatePolicyHolderId(id: string, holderId: string) {
        return this.api.patchSingleDotNet(`Policy/${id}/update-customer-id/${holderId}`, null);
    }

    updatePolicyCoHolderId(id: string, coHolderId: string) {
        return this.api.patchSingleDotNet(`Policy/${id}/update-co-holder-id/${coHolderId}`, null);
    }

    getPolicyStats() {
        return this.api.getArrayDotNet(`PolicyMetric`);
    }

    getPolicyFirstEffective(policyId: string) {
        return this.api.getSingleDotNet(`Policy/${policyId}/first-effective-date`);
    }

    async getPlanAndOptions(policyId: string) {
        const planId = await this.api.getSingleDotNet(`Policy`, { id_eq: policyId, select: 'planId' });
        const plan = await this.api.getSingleDotNet(`Plan`, { id_eq: planId, select: 'name,versionId' });
        const options = await this.api.getArrayDotNet(`PolicyOption`, { policyId_eq: policyId }, () => new PolicyOption());
        return {
            plan,
            options
        };
    }

    deleteContract(id: string) {
        return this.api.deleteDotNet(`policy/${id}/delete-contract`);
    }

    completeRenewalBatchPrint(batchId: string) {
        return this.api.patchSingleDotNet(`Policy/complete-renewal-print-batch/${batchId}`, null);
    }

    setPolicyAutoRenew(policyId: string, card: StripeCard) {
        const expDate = (new Date(card.exp_year, card.exp_month - 1, 1)).toISOString();
        return this.api.patchSingleDotNet(`Policy/${policyId}/update-auto-renewal?stripeCardId=${card.id}&creditCardExpirationDate=${expDate}`, null);
    }

    requestVerificationCodeByEmailOrText(emailOrPhone: string) {
        return this.api.postSingleNode(`policy/request-verification-code`, { emailOrPhone });
    }

    validateVerificationCode(emailOrPhone: string, verificationCode: number) {
        return this.api.postSingleNode(`policy/validate-verification-code`, { emailOrPhone, verificationCode });
    }

    getPolicyRepairItems(policyId: string) {
        return this.api.getArrayDotNet(`Policy/${policyId}/repair-items`);
    }

    getRenewalsToPrint(): Promise<string[]> {
        return this.api.getArrayDotNet(`PoliciesToRenewPrint`, { take: 10, select: 'id', paperless_eq: false });
    }

    getRenewalCards(startDate: Date, endDate: Date, cardType: string) {
        return this.api.getSingleNode('Policy/Renewal-Cards', { startDate, endDate, cardType });
    }

    markRenewalCards(policyIds: string[], cardType: string) {
        return this.api.postVoidNode('Policy/Renewal-Cards', { policyIds, cardType });
    }

    getPolicyCreditAmount(policyId: string): Promise<number> {
        return this.api.getSingleDotNet(`AccountingItem/${policyId}/credit-balance`);
    }

    getPolicyHeatMap(areaId: string) {
        return this.api.getArrayDotNet(`policy/heat-map/${areaId}`);
    }

    updatePropertyManagerSendPreferences(policyId: string, alwaysSendDocumentsToHomeowner: boolean, alwaysSendDocumentsToPropertyManager: boolean) {
        return this.api.patchSingleDotNet(`policy/${policyId}`, {
            alwaysSendDocumentsToHomeowner,
            alwaysSendDocumentsToPropertyManager
        });
    }

    getThankYouPostCardsToPrint(startDate: Date, endDate: Date, selectedAreaIds: string[]): Promise<string[]> {
        return this.api.getArrayDotNet(`ThankYouPostCardToPrint`, {
            createdDate_gte: startDate, createdDate_lte: endDate, select: 'id',
            areaId_contains: selectedAreaIds
        });
    }

    generateThankYouPostCardBatch(ids: string[]) {
        return this.api.postSingleDotNet(`policy/batch-thank-you-post-card-printing`, ids);
    }

    completeThankYouPostCardBatchPrint(batchId: string) {
        return this.api.patchSingleDotNet(`policy/complete-thank-you-post-card-print-batch/${batchId}`, null);
    }



    getPolicyStatus(policyId: string) {
        return this.api.getSingleDotNet(`Policy/${policyId}`, { select: 'Status' });
    }

    getPromotionCode(code: string, coverageType: CoverageType): Promise<PromotionCodeSummary> {
        return this.api.getSingleDotNet(`PromotionCode/internal/${code}/${coverageType}`, null, () => new PromotionCodeSummary());
    }

    updateEffectiveDate(id: string, editEffectiveDate: Date, expirationDate: Date) {
        let monthsOfCoverage: number
        monthsOfCoverage = (expirationDate.getFullYear() - editEffectiveDate.getFullYear()) * 12;
        monthsOfCoverage -= editEffectiveDate.getMonth();
        monthsOfCoverage += expirationDate.getMonth();
        monthsOfCoverage <= 0 ? 0 : monthsOfCoverage;
        return this.api.patchDotNet(`policy/${id}`, { effectiveDate: editEffectiveDate, expirationDateOverride: expirationDate, monthsOfCoverage });
    }

    updateExpirationDate(id: string, expirationDate: Date) {
        return this.api.patchDotNet(`policy/${id}`, { expirationDateOverride: expirationDate });
    }

    replaceTags(policyId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Policy/${policyId}/replace-tags`, tags);
    }

    generateAutoRenewals() {
        return this.api.postVoidDotNet(`Policy/generate-auto-renewals`);
    }

    addTags(policyId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Policy/${policyId}/add-tags`, tags);
    }

    removeTags(policyId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Policy/${policyId}/remove-tags`, tags);
    }

    getContractsToPrint(startDate: Date, endDate: Date, selectedAreaIds: string[]): Promise<string[]> {
        return this.api.getArrayDotNet(`Policy`, {
            effectiveDate_gte: startDate, effectiveDate_lte: endDate, contractPrintDate_eq: '{{null}}', select: 'id',
            areaId_contains: selectedAreaIds
        });
    }

    completeContractBatchPrint(batchId: string) {
        return this.api.patchSingleDotNet(`policy/complete-contract-print-batch/${batchId}`, null);
    }

    generateContractBatch(ids: string[]) {
        return this.api.postSingleDotNet(`Policy/batch-contract-printing`, ids);
    }

    updatePolicyMarketingSource(id: string, marketingSourceId: string) {
        return this.api.patchDotNet(`policy/${id}`, { marketingSourceId });
    }

    updateBillTo(id: string, billTo: string) {
        return this.api.patchDotNet(`policy/${id}`, { billTo });
    }

    updateMlsNumber(id: string, mlsNumber: string) {
        return this.api.patchDotNet(`policy/${id}`, { mlsNumber });
    }

    savePolicyAdjustment(policyAdjustment: PolicyAdjustment) {
        return this.api.postSingleDotNet(`PolicyAdjustment`, policyAdjustment);
    }

    deletePolicyAdjustment(policyAdjustment: PolicyAdjustment) {
        return this.api.deleteDotNet(`PolicyAdjustment/${policyAdjustment.id}`);
    }


    getPolicyTags(policyId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`Policy/${policyId}/tags`);
    }

    getStreetViewUrl300x300(policyId: string): string {
        if (!policyId) {
            return null;
        }
        const url = ApiService.endPointDotNet + 'policy/' + policyId + '/StreetView?height=300&width=300';
        return url;
    }

    getPolicyTenants(policyId: string): Promise<Entity[]> {
        return this.api.getArrayDotNet(`Policy/${policyId}/get-tenants`, null, () => new Entity());
    }

    markRenewableStatus(policyId: string, renewable: boolean) {
        return this.api.patchDotNet(`policy/${policyId}`, { DoNotRenew: renewable });
    }

    getPolicyServiceOffering(workOrderItemId: string) {
        return this.api.getArrayNode(`policy/extended-policy-service-offering/${workOrderItemId}`, null, () => new ExtendedPolicyServiceOffering());
    }

    getPolicyReport(startDate: Date, endDate: Date, areas: CompanyRegion[], statuses: string[], plans: PlanClient[], employees: Entity[], coverageTypes: string[], marketingSources: MarketingSource[],
        excludedAreas: CompanyRegion[], excludedStatues: string[], excludedPlans: PlanClient[], excludedEmployees: Entity[], coverageTypesExcluded: string[], marketingSourcesExcluded: MarketingSource[],
        salesRegions: CompanyRegion[], salesRegionsExcluded: CompanyRegion[]) {

        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);
        const params = {
            CreatedDate_gte: startDate,
            CreatedDate_lte: endDate,
            properties:
                'id,CoverageType,PolicyNumber,monthsOfCoverage,createdDate,EffectiveDate,PlanName,Status,InitiatorType,BasePrice,BasePriceRecurring,TotalPayments,TotalPrice,TotalPriceRecurring,IsMonthly,AccountExecutiveName,MarketingSourceName,SalesRegionName'
        } as any;

        if (areas && areas.length > 0) {
            let areasString = '';
            areas.forEach(region => {
                if (areasString) {
                    areasString += ',';
                }
                areasString += region.id;
            });
            params.ServiceProTerritoryId_contains = areasString;
        }

        if (excludedAreas && excludedAreas.length > 0) {
            let areasString = '';
            excludedAreas.forEach(region => {
                if (areasString) {
                    areasString += ',';
                }
                areasString += region.id;
            });
            params.ServiceProTerritoryId_ncontains = areasString;
        }

        if (salesRegions && salesRegions.length > 0) {
            let areasString = '';
            salesRegions.forEach(region => {
                if (areasString) {
                    areasString += ',';
                }
                areasString += region.id;
            });
            params.SalesTerritoryId_contains = areasString;
        }
        if (salesRegionsExcluded && salesRegionsExcluded.length > 0) {
            let areasString = '';
            salesRegionsExcluded.forEach(region => {
                if (areasString) {
                    areasString += ',';
                }
                areasString += region.id;
            });
            params.SalesTerritoryId_ncontains = areasString;
        }

        if (employees && employees.length > 0) {
            let qString = '';
            employees.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.AccountExecutiveId_contains = qString;
        }

        if (statuses && statuses.length > 0) {
            let qString = '';
            statuses.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.status_contains = qString;
        }

        if (plans && plans.length > 0) {
            let qString = '';
            plans.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.PlanId_contains = qString;
        }

        if (coverageTypes && coverageTypes.length > 0) {
            let qString = '';
            coverageTypes.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.coverageType_contains = qString;
        }

        if (marketingSources && marketingSources.length > 0) {
            let qString = '';
            marketingSources.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.marketingSourceId_contains = qString;
        }




        if (excludedEmployees && excludedEmployees.length > 0) {
            let qString = '';
            excludedEmployees.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.AccountExecutiveId_ncontains = qString;
        }

        if (excludedStatues && excludedStatues.length > 0) {
            let qString = '';
            excludedStatues.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.status_ncontains = qString;
        }
        if (excludedPlans && excludedPlans.length > 0) {
            let qString = '';
            excludedPlans.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.PlanId_ncontains = qString;
        }
        if (coverageTypesExcluded && coverageTypesExcluded.length > 0) {
            let qString = '';
            coverageTypesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            params.coverageType_ncontains = qString;
        }

        if (marketingSourcesExcluded && marketingSourcesExcluded.length > 0) {
            let qString = '';
            marketingSourcesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.marketingSourceId_ncontains = qString;
        }

        return this.api.getArrayNode('PolicySummaryV2', params, () => new PolicySummary());
    }

    updateDefaultPolicyCard(policyId: string, cardId: string, expMonth: number, expYear: number): Promise<any> {
        const expDate = new Date(expYear, expMonth, 1);
        return this.api.patchDotNet(`policy/${policyId}`, { stripeCardId: cardId, creditCardExpirationDate: expDate });
    }

    updateCustomerPortalPolicyCreditCard(policyId: string, cardId: string, expMonth: number, expYear: number) {
        //update-stripe-card
        //public ApiResponse<bool> UpdateStripeCard(Guid id, [FromQuery] string stripeCardId, [FromQuery] DateTime creditCardExpirationDate)
        const expDate = new Date(expYear, expMonth, 1).toISOString();
        return this.api.patchDotNet(`policy/${policyId}/update-stripe-card?stripeCardId=${cardId}&creditCardExpirationDate=${expDate}`, null);
    }

    clearDefaultPolicyCard(policyId: string) {
        return this.api.patchDotNet(`policy/${policyId}`, { stripeCardId: null, creditCardExpirationDate: null });
    }

    async getContractPdfUrl(policyId: string) {
        if (!policyId) { return null; }
        return ApiService.endPointNode + 'policy/contract/pdf/' + policyId;
    }

    async getIsNodeJsContract(policyId: string) {
        const policy = await this.api.getSingleNode("policy", { select: "planId", id_eq: policyId }, () => new Policy());
        const plan = await this.api.getSingleNode("Plan", { select: "NodeJsContract", id_eq: policy.planId });
        return plan.nodeJsContract;
    }

    getContractHtmlUrl(policyId: string) {
        if (!policyId) { return null; }

        return ApiService.endPointDotNet + 'policy/' + policyId + '/contract/html';
    }

    getNextPolicyNumber(): Promise<number> {
        return this.api.getSingleDotNet<number>('Policy/NextNumber');
    }

    invoicePolicy(id: string) {
        return this.api.patchDotNet(`Policy/${id}/Invoice`, null);
    }

    async saveNewPolicy(policy: NewPolicy): Promise<Policy> {
        const newPolicy = await this.api.postSingleDotNet('Policy/CreateNew', policy, null, () => new Policy());

        const promises = [];

        // promises.push(this.api.patch("Policy/" + newPolicy.id + "/Invoice", null));

        // if (policy.options && policy.options.length) {
        //     policy.options.forEach(option => {
        //         for (let i = 0; i < option.quantity; i++) {
        //             const policyOption = new PolicyOption(UtilitiesService.newid(),
        //                 newPolicy.id, option.planItemId, option.price, option.priceRecurring);

        //             promises.push(this.api.postId('PolicyOption', policyOption));
        //         }
        //     });
        // }

        // await Promise.all(promises);
        return newPolicy;
    }

    async addPromoCode(policyId: string, promoCodeId: string) {
        await this.api.patchSingleDotNet(`Policy/${policyId}/Add-Promo-Code/${promoCodeId}`, null)
    }

    async addPolicyExclusion(exclusion: PolicyExclusion): Promise<void> {
        await this.api.postIdDotNet("PolicyExclusion", exclusion);
    }

    cancelPolicy(policyId: string, cancellationInfo: CancellationInfo) {
        return this.api.patchDotNet(`Policy/${policyId}/cancel-policy`, cancellationInfo);
    }

    changePlanOnPolicy(policyId: string, newPlanId: string, monthly: boolean) {
        return this.api.patchDotNet("Policy/" + policyId + "/change-plan/" + newPlanId + "/" + monthly, null);
    }

    async deletePolicyExclusion(policyExclusionId: string): Promise<void> {
        await this.api.deleteDotNet("PolicyExclusion/" + policyExclusionId);
    }

    deletePolicy(id: string) {
        return this.api.deleteNode(`policy/${id}`);
    }

    undeletePolicy(id: string) {
        return this.api.unDeleteNode(`policy/${id}`);
    }

    findPolicies(searchFor: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet("Search/Policy", { searchFor }, () => new SearchResultModel());
    }

    findPoliciesWithoutLogins(searchFor: string): Promise<any[]> {
        return this.api.getArrayNode('search/policies-without-login', { searchFor });
    }

    findPoliciesForCustomer(customerId: string): Promise<SearchResultModel[]> {
        return this.api.getArrayDotNet(`search/Policy?key2=${customerId.toLowerCase()}&searchFor=`);
    }

    get(id: string) {
        return this.api.getSingleDotNet("Policy/" + id, null, () => new Policy());
    }

    getContractBase64(policyId: string) {
        return this.api.getSingleStringDotNet("Policy/" + policyId + "/contract/base64");
    }

    getCoverage(policyId: string) {
        return this.api.getArrayDotNet("Policy/" + policyId + "/Coverage", null, () => new PolicyCoverageModel());
    }

    getCurrentUserStats(): Promise<PolicyStatsModel[]> {
        return this.api.getArrayDotNet('Policy/MyStats', null, () => new PolicyStatsModel());
    }

    getCustomerClaims(customerId: string) {
        return this.api.getArrayDotNet<ClaimSummaryModel>("WorkOrderSummary",
            {
                properties: "Id,CreatedDate,ItemName,ContractorName,DateClosed,Status,Number,ItemId,CancelledDate",
                holderId_eq: customerId
            }, () => new ClaimSummaryModel());
    }

    getPolicyCanceledReasons(coverageType: string = null) {
        const params: any = {
            orderBy: 'sort,reason',
        };
        if (coverageType === 'Homeowner') {
            params.availableOnHomeownerCoverage_eq = true;
        } else if (coverageType === 'RealEstate') {
            params.availableOnBuyerCoverage_eq = true;
        } else if (coverageType === 'Sellers') {
            params.availableOnSellerCoverage_eq = true;
        } else if (coverageType === 'Renewal') {
            params.availableOnRenewalCoverage_eq = true;
        }
        return this.api.getArrayNode('PolicyCanceledReason', params, () => new PolicyCanceledReason());
    }

    savePolicyCanceledReason(reason: PolicyCanceledReason) {
        return this.api.postSingleNode(`PolicyCanceledReason`, reason);
    }

    deletePolicyCanceledReason(reasonId: string) {
        return this.api.deleteNode(`PolicyCanceledReason/${reasonId}`);
    }

    unDeletePolicyCanceledReason(reasonId: string) {
        return this.api.unDeleteNode(`PolicyCanceledReason/${reasonId}`);
    }

    undoPolicyCancellation(policyId: string) {
        return this.api.patchSingleDotNet(`policy/${policyId}/undo-cancel`, null);
    }
    getPolicyClaims2(policyId: string) {
        return this.api.getArrayNode<WorkOrderSummaryClient>("WorkOrderSummary",
            {
                policyId_eq: policyId, cancelledDate_eq: '{{null}}'
            }, () => new WorkOrderSummaryClient());
    }

    getPolicyPayments2(policyId: string) {
        return this.api.getArrayNode<PaymentSummary>(`PaymentSummary`, { policyId_eq: policyId, voidDate_eq: '{{null}}', orderby: 'CreatedDate Desc' });
    }

    getPolicyJobItemDetails(policyId: string) {
        return this.api.getArrayNode<JobItemDetail>(`JobItemDetail`, { policyId_eq: policyId, deletedDate_eq: '{{null}}' });
    }

    updateJobItemDetail(jobItemDetail: JobItemDetail){
        return this.api.postSingleNode(`JobItemDetail`, jobItemDetail);
    }

    getPolicyClaims(policyId: string) {
        return this.api.getArrayDotNet<WorkOrderSummaryClient>("WorkOrderSummary",
            {
                properties: "Id,CreatedDate,ItemName,ContractorName,DateClosed,Status,Number,ItemId,CancelledDate,ClaimId,Type,LinesJson",
                policyId_eq: policyId
            }, () => new WorkOrderSummaryClient());
    }

    getPolicyClaimsByPolicyNumber(policyNumber: number, propertyId: string) {
        return this.api.getArrayDotNet<ClaimSummaryModel>("WorkOrderSummary",
            {
                properties: "Id,CreatedDate,ItemName,ContractorName,DateClosed,Status,Number,ItemId,CancelledDate,ClaimId,Type,StatusSort,DateCompleted,TotalCashOuts,TotalPurchaseOrders,TotalContractorInvoices,LinesJson",
                policyNumber_eq: policyNumber,
                propertyId_eq: propertyId,
            }, () => new ClaimSummaryModel());
    }

    async getPolicyWorkOrderLinesByPolicyNumber(policyNumber: number, propertyId: string) {
        const items = await this.api.getArrayDotNet<ClaimSummaryModel>("WorkOrderLineSummary",
            {
                properties: "Id,WorkOrderId,CreatedDate,ItemName,ContractorName,Status,Number,ItemId,CancelledDate,ClaimId,Type,StatusSort,DateCompleted,TotalCashOuts,TotalPurchaseOrders,TotalContractorInvoices,DenialDate",
                policyNumber_eq: policyNumber,
                propertyId_eq: propertyId,
            }, () => new ClaimSummaryModel());

        for (const item of items) {
            item.id = item.workOrderId;
        }
        return items;
    }

    getPolicyPrepaidServiceFees(policyId: string): Promise<PrepaidServiceFee[]> {
        return this.api.getArrayDotNet(`PrepaidServiceFee`, { policyId_eq: policyId }, () => new PrepaidServiceFee());
    }


    async getPolicyClaimCounts(policyId: string) {
        const claimsMetric = new PolicyClaimMetricModel();
        claimsMetric.totalClaims = await this.api.countDotNet("WorkOrderSummary", { policyId_eq: policyId });
        claimsMetric.openClaims = await this.api.countDotNet("WorkOrderSummary", { datecompleted_eq: '{{null}}', policyId_eq: policyId, status_ncontains: ['Cancelled', 'Paid', 'Invoice Accepted', 'Invoiced', 'Complete'] });
        return claimsMetric;
    }

    getPolicyExclusions(policyId: string) {
        return this.api.getArrayDotNet("PolicyExclusion", { policyId_eq: policyId }, () => new PolicyExclusion());
    }

    getPolicyExpensesByTrade(policyId: string) {
        return this.api.getArrayDotNet<PolicyExpenseByTradeModel>("Policy/" + policyId + "/expenses-by-trade", null, () => new PolicyExpenseByTradeModel());
    }

    getPolicyHistory(policyId: string) {
        return this.api.getArrayDotNet<PolicyHistoryItemClient>("Policy/" + policyId + "/history", null, () => new PolicyHistoryItemClient());
    }

    getPolicyOptions(policyId: string) {
        return this.api.getArrayDotNet("Policy/" + policyId + "/PolicyOptions");
    }

    getRenewedPolicy(id: string) {
        return this.api.getSingleDotNet(`policy`, { previousPolicyId_eq: id, CanceledDate_eq: '{{null}}' });
    }

    getPolicy(id: string) {
        return this.api.getSingleNode(`policy`, { id_eq: id });
    }

    async getPolicySummaryV2(id: string) {
        return this.api.getSingleNode(`PolicySummaryV2`, { id_eq: id }, () => new PolicySummaryV2());
    }

    async getPolicySummaryV2ByNumber(policyNumber: string) {
        return this.api.getSingleNode(`PolicySummaryV2`, { policyNumber_eq: policyNumber, nextPolicyId_eq: '{{null}}' }, () => new PolicySummaryV2());
    }

    async getPolicySummary(id: string) {
        let summary = await this.api.getSingleDotNet("PolicySummary/" + id, null, () => new PolicySummary());
        summary.options = await this.api.getArrayDotNet("PolicyOption", { policyId_eq: id }, () => new PolicyOption());
        summary.adjustments = await this.api.getArrayDotNet(`PolicyAdjustmentSummary`, { policyId_eq: id }, () => new PolicyAdjustment());

        if (summary.propertyAddress) {
            summary.propertyAddress = this.api.convertToType(summary.propertyAddress, () => new Address());
        }
        summary.holder = this.api.convertToType(summary.holder, () => new Entity());
        if (summary.entityPhoneNumber) {
            if (summary.entityPhoneNumber.faxNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Fax';
                phoneNumber.number = summary.entityPhoneNumber.faxNumber;
                summary.holder.phoneNumbers.push(phoneNumber);
            }
            if (summary.entityPhoneNumber.homeNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Home';
                phoneNumber.number = summary.entityPhoneNumber.homeNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
            if (summary.entityPhoneNumber.mobileNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Mobile';
                phoneNumber.number = summary.entityPhoneNumber.mobileNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
            if (summary.entityPhoneNumber.officeNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Office';
                phoneNumber.number = summary.entityPhoneNumber.officeNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
        }
        return summary;
    }

    async getPolicySummaryActive(id: string) {
        let summary = await this.api.getSingleDotNet("PolicySummary/" + id, null, () => new PolicySummary());

        //GET POLICY BY MOST RECENT EFFECTIVE DATE
        if (summary.status === 'Expired') {
            summary = await this.api.getSingleDotNet(`Policy/${summary.policyNumber}/most-recent`, null, () => new PolicySummary());
        }

        summary.options = await this.api.getArrayDotNet("PolicyOption", { policyId_eq: id }, () => new PolicyOption());
        summary.adjustments = await this.api.getArrayDotNet(`PolicyAdjustmentSummary`, { policyId_eq: id }, () => new PolicyAdjustment());

        if (summary.propertyAddress) {
            summary.propertyAddress = this.api.convertToType(summary.propertyAddress, () => new Address());
        }
        summary.holder = this.api.convertToType(summary.holder, () => new Entity());
        if (summary.entityPhoneNumber) {
            if (summary.entityPhoneNumber.faxNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Fax';
                phoneNumber.number = summary.entityPhoneNumber.faxNumber;
                summary.holder.phoneNumbers.push(phoneNumber);
            }
            if (summary.entityPhoneNumber.homeNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Home';
                phoneNumber.number = summary.entityPhoneNumber.homeNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
            if (summary.entityPhoneNumber.mobileNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Mobile';
                phoneNumber.number = summary.entityPhoneNumber.mobileNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
            if (summary.entityPhoneNumber.officeNumber) {
                const phoneNumber = new PhoneNumber();
                phoneNumber.type = 'Office';
                phoneNumber.number = summary.entityPhoneNumber.officeNumber;
                summary.holder.phoneNumbers.push(phoneNumber);

            }
        }
        return summary;
    }

    updateManuallSuspended(id: string, manuallySuspended: boolean) {
        return this.api.patchSingleDotNet(`Policy/${id}`, { manuallySuspended });
    }

    isPolicyRenewed(id: string): Promise<boolean> {
        return this.api.getSingleDotNet(`Policy/${id}/is-renewed`);
    }

    getRenewalPolicySummary(id: string): Promise<PolicySummary> {
        return this.api.getSingleDotNet(`Policy/${id}/renewal-policy-summary`, null, () => new PolicySummary());
    }

    getRenewalPolicySummary2(id: string): Promise<PolicyRenewalSummary> {
        return this.api.getSingleNode(`Policy/Renewal-Summary/${id}`, null, () => new PolicyRenewalSummary());
    }

    async getPolicySummariesForHolder(holderId: string) {
        const policySummaries = await this.api.getArrayDotNet("PolicySummary", { where: 'holder.id="' + holderId + '" || coHolder.id="' + holderId + '"' }, () => new PolicySummary());
        policySummaries.forEach(ps => {
            if (ps.propertyAddress) {
                ps.propertyAddress = this.api.convertToType(ps.propertyAddress, () => new Address());
            }
            ps.holder = this.api.convertToType(ps.holder, () => new Entity());
        });
        return policySummaries;
    }

    async getPolicyTaskCounts(policyId: string) {
        const result = new TaskSummary();
        result.openTasks = await this.api.countDotNet("Task", { policyId_eq: policyId, completedDate_eq: "{{null}}" });
        result.completedTasks = await this.api.countDotNet("Task", { policyId_eq: policyId, completedDate_neq: '{{null}}' });
        return result;
    }



    getPolicyTotalDue(policyId: string) {
        return this.api.getSingleDotNet<number>("Policy/" + policyId + "/total-due");
    }

    getPolicyPastDue(policyId: string) {
        return this.api.getSingleDotNet<number>("Policy/" + policyId + "/total-past-due");

    }

    getPolicyCancellationInfo(policyId: string, cancellationDate: Date, waiveAdminFee: boolean, proRata: boolean) {
        return this.api.getSingleDotNet<CancellationInfo>(`policy/${policyId}/get-cancellation-balance`, {
            cancellationDate,
            waiveAdminFee,
            proRata,
        }, () => new CancellationInfo());
    }

    getPolicyCreditCardPayments(id: string) {
        return this.api.getArrayNode(`PaymentSummary`,
            { policyId_eq: id, type_eq: "CreditCard", firstInvoiceType_eq: "Premium", deletedDate_eq: "{{null}}", voidDate_eq: "{{null}}" },
            () => new PaymentSummary());
    }

    getPolicyWorkOrderItems(policyId: string) {
        return this.api.getArrayDotNet("PolicyServiceOffering/" + policyId, null, () => new PolicyServiceOffering());
    }

    getPolicyWorkOrderItems2(policyId: string) {
        return this.api.getArrayNode(`policy/policy-service-offering/${policyId}`, null, () => new PolicyServiceOffering());
    }

    getWorkOrderItemDetail(workOrderId: string) {
        return this.api.getSingleDotNet("PolicyServiceOffering/work-order/" + workOrderId);
    }

    getWorkOrderItemDetailByWorkOrderLine(workOrderLineId: string) {
        return this.api.getSingleDotNet(`PolicyServiceOffering/work-order-line/${workOrderLineId}`);
    }

    getWeeklyStats() {
        return this.api.getArrayDotNet<PolicyStatsModel>("Policy/Weekly-Stats", null, () => new PolicyStatsModel());
    }

    removePolicyExclusion(policyExclusionId: string) {
        return this.api.patchDotNet("PolicyExclusion/" + policyExclusionId, { removalDate: new Date() });
    }

    renewPolicy(policyId: string, policyRenewal: PolicyRenewalModel) {
        return this.api.postSingleDotNet<Policy>("Policy/" + policyId + "/Renew", policyRenewal, null, () => new Policy());
    }

    resetRenewalOffering(policyId: string) {
        return this.api.deleteNode(`policy/${policyId}/reset-renewal-offerings`)
    }

    getRenewalPolicy(policyId: string) {
        return this.api.getSingleNode(`policy`, { previousPolicyId_eq: policyId });
    }

    savePolicyOptions(policyId: string, policyOptions: PolicyOption[]) {
        if (policyOptions) {
            policyOptions.forEach(pi => {
                pi.policyId = policyId;
                if (!pi.id) {
                    pi.id = UtilitiesService.newid();
                }
                if (pi.price == null) {
                    pi.price = 0;
                }
                if (pi.priceRecurring == null) {
                    pi.priceRecurring = 0;
                }
            });
        }
        return this.api.patchDotNet("Policy/" + policyId + "/PolicyOptions", { options: policyOptions });
    }

    updateBuyersAgent(policyId: string, buyersAgentId: string) {
        return this.api.patchDotNet("Policy/" + policyId, { agentId: buyersAgentId });
    }

    updateClosingAgent(policyId: string, closingAgentId: string) {
        this.api.patchDotNet("Policy/" + policyId, { closingOfficerId: closingAgentId });
    }

    updateClosingDate(policy: Policy) {
        return this.api.patchDotNet("Policy/" + policy.id, { estimatedClosingDate: policy.estimatedClosingDate });
    }

    updateCoverageType(policyId: string, coverageType: string) {
        return this.api.patchDotNet(`Policy/${policyId}`, { coverageType });
    }

    updateEscrowNumber(policy: Policy) {
        return this.api.patchDotNet("Policy/" + policy.id, { updateEscrowNumber: policy.escrowNumber });
    }

    updateInitiatorType(policy: Policy) {
        return this.api.patchDotNet("Policy/" + policy.id, { initiatorType: policy.initiatorType });
    }

    updateAccountExecutive(policy: Policy) {
        return this.api.patchDotNet("Policy/" + policy.id, { accountExecutiveId: policy.accountExecutiveId });
    }

    updateSellersAgent(policyId: string, sellerAgentId: string) {
        return this.api.patchDotNet("Policy/" + policyId, { sellerAgentId });
    }

    updatePropertyManager(addressId: string, propertyManagerId: string) {
        return this.api.patchDotNet(`Address/${addressId}`, { propertyManagerId });
    }

    updateAutoRenewStatus(policyId: string, isRenewing: boolean, card: StripeCard, reason: string = null) {
        const cardExpirationDate = card != null ? (new Date(card.exp_year, card.exp_month - 1, 1)).toISOString() : null
        return this.api.patchSingleNode(`policy/set-auto-renew/${policyId}`, { isRenewing, cardId: card?.id, cardExpirationDate, reason })
    }

    generatePolicyAltKey(id: string) {
        return this.api.patchSingleNode(`policy/assign-policy-alt-key/${id}`, null)
    }
}

