import { Component, OnInit } from '@angular/core';
import { ContractorsService } from '@upkeeplabs/service-pros/app/services/contractors.service';
import { ContractorKPIModel } from '@upkeeplabs/service-pros/app/model/contractors/contractor-kpi.model';
import { EntityApiService } from "@cogent/client/shared/services/api/entity-api.service";
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { DialogsService } from '@cogent/client/shared/services/dialog-service/dialog.service';
import { ContractorApiService } from '@cogent/client/shared/services/api/contractor-api.service';
import Chart from 'chart.js/auto';
//import { ContractorKPI } from '@upkeeplabs/models/cogent';
import { ContractorKPI } from '@cogent/shared/models/contractor-relations/contractor-k-p-i.model';
@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
    contractorTrades: any[];
    selectedTradeId: string;
    startDate: Date = UtilitiesService.monthStart(new Date());
    endDate: Date = UtilitiesService.monthEnd(new Date());
    kpis: ContractorKPIModel[];
    accData: any[];
    showQuerySelection = false;
    showCriteria = false;
    accKpis: ContractorKPI[] = [];
    currentAcc: ContractorKPI;
    claimCountChart: any;
    accHistoryChart: any;

    constructor(
        private contractorsService: ContractorsService,
        private dialogService: DialogsService,
        private contractorApi: ContractorApiService,
        private entityApi: EntityApiService
    ) { }


    MONTHS = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
    ];

    ngOnInit() {
        this.entityApi.getLoggedInUser().then(user => {
            this.contractorsService.getContractorTrades(user.id).then(contractorTrades => {
                this.contractorTrades = contractorTrades;
                if (this.contractorTrades.length > 0) {
                    this.selectedTradeId = this.contractorTrades[0].tradeId;
                }
                // this.refreshAll();
            });
        });
        setTimeout(() => this.showCriteria = true, 500);
    }

    loading = false;
    refreshAll() {
        this.kpis = null;
        this.loading = true;
        const startDate = new Date(this.startDate);

        this.entityApi.getLoggedInUser().then(user => {

            this.contractorApi.getCostsByContractor(user.id).then(results => {
                this.accKpis = [];
                const today = new Date();
                const nextMonth = new Date(today.setMonth(today.getMonth() + 1));
                let endOfMonth = nextMonth;
                for (let i = 0; i < 6; i++) {
                    endOfMonth = new Date(endOfMonth.setDate(0));
                    let kpi = new ContractorKPI();
                    kpi.reportPeriod = new Date(endOfMonth.toLocaleDateString());
                    kpi.reportMonth = endOfMonth.getMonth() + 1;
                    kpi.reportYear = endOfMonth.getFullYear();

                    const cost = results[0].filter(i => i.month == kpi.reportMonth && i.year == kpi.reportYear).reduce((sum, current) => sum + current.amount, 0);
                    kpi.claimCost = cost ? cost.toFixed(2) : 0;

                    const claims = results[1].filter(i => i.month == kpi.reportMonth && i.year == kpi.reportYear).reduce((sum, current) => sum + current.claims, 0);
                    kpi.claimCount = claims ? claims : 0;

                    this.accKpis.push(kpi);
                }
                this.accKpis = this.accKpis.sort((a, b) => a.reportPeriod < b.reportPeriod ? -1 : 1);
                this.currentAcc = this.accKpis[this.accKpis.length - 1];

                this.setClaimCountChart();
                this.setACCHistoryChart();
            });

            this.contractorsService.getContractorKPIs(user.id, this.selectedTradeId, startDate, this.endDate).then(kpis => {
                this.kpis = kpis;
                this.loading = false;
            });
        });
    }

    setACCHistoryChart() {
        const canvas = document.getElementById('acc-history-chart');
        if (!canvas) {
            setTimeout(() => this.setACCHistoryChart(), 1000);
            return;
        }

        const config = {
            type: 'line',
            data: {
                labels:
                    this.accKpis.map(i => this.MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
                datasets: [
                    {
                        data: this.accKpis.map(i => i.averageClaimCost.toFixed(2)),
                        fill: false,
                    },
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        display: false,
                    }
                },
                title: { display: false, text: 'Chart.js Line Chart' },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    x:
                    {
                        grid: { display: true }
                    },
                    y:
                    {
                        grid: { display: true }
                    }
                }
            }
        };

        const ctx = (canvas as HTMLCanvasElement).getContext('2d');
        if (this.accHistoryChart) {
            this.accHistoryChart.destroy();
        }
        this.accHistoryChart = new Chart(ctx, config as any);
    }

    setClaimCountChart() {
        const canvas = document.getElementById('claim-count-chart');
        if (!canvas) {
            setTimeout(() => this.setClaimCountChart(), 1000);
            return;
        }

        const config = {
            type: 'line',
            data: {
                labels:
                    this.accKpis.map(i => this.MONTHS[i.reportMonth - 1] + ' ' + i.reportYear),
                datasets: [
                    {
                        data: this.accKpis.map(i => i.claimCount),
                        fill: false,
                        borderColor: 'rgb(252, 186, 3)',
                    },
                ]
            },
            options: {
                responsive: true,
                plugins: {
                    legend: {
                        position: 'right',
                        display: false,
                    }
                },
                title: { display: false, text: 'Chart.js Line Chart' },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                },
                hover: { mode: 'nearest', intersect: true },
                scales: {
                    x:
                    {
                        grid: { display: true }
                    },
                    y:
                    {
                        grid: { display: true }
                    }
                }
            }
        };

        const ctx = (canvas as HTMLCanvasElement).getContext('2d');
        if (this.claimCountChart) {
            this.claimCountChart.destroy();
        }
        this.claimCountChart = new Chart(ctx, config as any);
    }

    get currentContractorScore() {
        if (!this.kpis || !this.kpis[this.kpis.length - 1]) {
            return null;
        }

        return this.kpis[this.kpis.length - 1].contractorRatingLetter;
    }



}
