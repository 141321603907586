export class FilterCapabilities {
    constructor(
        public filterFieldName: string,
        public queueQueryField: string,
        public editorType: string,
        public notInQueryField: string = null,
        public queueType: string = null,
        public notSetField: string = null,
        public isSetField: string = null) { }

    sourceCustomTable: string;
    sourceCustomTableDescription: string;
    sourceCustomTableIdField: string;
}

export class ObjectSummaryField {
    name: string;
    description: string;
    navigateUrl: string;
    dependentFields: string[];
    displayType: string;
    isDate: boolean;
    filterCapabilities: FilterCapabilities;
    dataType: string;
    required: boolean;
    editable: boolean;
    values: string;
    defaultValue: string;
    udfValue: string;

    get valuesArray() {
        if (!this.values) {
            return [];
        }

        return this.values.split('\r');
    }

    public static getFieldsByQueueType(queueType: string): ObjectSummaryField[] {
        if (queueType === 'Service') {
            return this.getWorkOrderSummaryFields();
        } else if (queueType === 'Policy') {
            return this.getPolicySummaryFields();
        } else if (queueType === 'Entity') {
            return this.getEntitySummaryFields();
        } else if (queueType === 'Task') {
            return this.getTaskSummaryFields();
        } else if (queueType === 'ContractorInvoice') {
            return this.getContractorInvoiceSummaryFields();
        } else if (queueType === 'PurchaseOrder') {
            return this.getPurchaseOrderSummaryFields();
        } else if (queueType === 'Invoice') {
            return this.getInvoiceSummaryFields();
        } else if (queueType === 'WorkOrderLine') {
            return this.getWorkOrderLineSummaryFields();
        } else if (queueType === 'CashOut') {
            return this.getCashOutSummaryFields();
        } else if (queueType === 'Quote') {
            return this.getQuoteSummaryFields();
        } else if (queueType === 'MaintenanceService') {
            return this.getMaintenanceServiceCustomerPropertyServiceSummaryFields();
        }
    }

    public static getQuoteSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Quote Id' },
            { name: 'name', description: 'Name', navigateUrl: '/quote/{{id}}' },
            { name: 'email', description: 'Email', displayType: 'email' },
            { name: 'addressId', description: 'Address Id' },
            { name: 'selectedPlanId', description: 'Selected Plan Id' },
            { name: 'planName', description: 'Plan Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('planId', 'selectedPlans', 'PLANS', 'selectedPlansExcluded', 'Quote') },
            { name: 'promotionCode', description: 'Promotion Code' },
            { name: 'address1', description: 'Address 1' },
            { name: 'address2', description: 'Address 2' },
            { name: 'city', description: 'City' },
            { name: 'state', description: 'State' },
            { name: 'postalCode', description: 'Postal Code' },
            { name: 'source', description: 'Source' },
            { name: 'basePrice', description: 'Base Price' },
            { name: 'basePriceRecurring', description: 'Base Price Recurring' },
            { name: 'phone', description: 'Phone', displayType: 'tel' },
            { name: 'firstName', description: 'First Name' },
            { name: 'lastName', description: 'Last Name' },
            { name: 'coverageType', description: 'Coverage Type', filterCapabilities: new FilterCapabilities('coverageType', 'selectedCoverageTypes', 'COVERAGE-TYPES', 'selectedCoverageTypesExcluded', 'Policy') },
            { name: 'createdDate', description: 'Created Date', filterCapabilities: new FilterCapabilities('CreatedDate', 'createdDateRange', 'DATE', null, 'Quote') }

        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];

    }

    public static getCashOutSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Cash Out Id' },
            { name: 'workOrderId', description: 'Job Id' },
            { name: 'createdById', description: 'Created By Id' },
            { name: 'createdDate', description: 'Date', isDate: true, filterCapabilities: new FilterCapabilities('CreatedDate', 'createdDateRange', 'DATE', null, 'CashOut') },
            { name: 'amount', description: 'Amount' },
            { name: 'contractorId', description: 'Service Pro Id' },
            { name: 'exportDate', description: 'Export Date', isDate: true, filterCapabilities: new FilterCapabilities('ExportDate', 'exportDateRange', 'DATE', null, 'CashOut') },
            { name: 'areaId', description: 'Area Id' },
            { name: 'policyId', description: 'Policy Id' },
            { name: 'workOrderItemName', description: 'Job Item', filterCapabilities: new FilterCapabilities('workOrderItemId', 'selectedItems', 'WORK-ORDER-ITEM', 'selectedItemsExcluded', 'CashOut') },
            { name: 'contractorName', description: 'Service Pro', filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Service', 'isContractorNull') },
            { name: 'workOrderNumber', description: 'Job #' },
            { name: 'cashOutAccountId', description: 'QB Account Id' },
            { name: 'cashOutCashAccountId', description: 'QB Cash Account Id' },
            { name: 'address1', description: 'Address 1' },
            { name: 'address2', description: 'Address 2' },
            { name: 'city', description: 'City' },
            { name: 'state', description: 'State' },
            { name: 'postalCode', description: 'Postal Code' },
            { name: 'nameOnCheck', description: 'Name On Check' },
            { name: 'number', description: 'Cash Out #' },
            { name: 'holderId', description: 'Holder Id' },
            { name: 'type', description: 'Type', filterCapabilities: new FilterCapabilities('type', 'type', 'CASH-OUT-TYPE', null, 'CashOut') },
            { name: 'description', description: 'Description' },
            { name: 'invoiceAppliedAmount', description: 'Invoice Applied Amount' },
            { name: 'policyNumber', description: 'Subscription #' },
            { name: 'contractorEmail', description: 'Service Pro Email', displayType: 'email' },
            { name: 'propertyAddress1', description: 'Property Address 1' },
            { name: 'propertyAddress2', description: 'Property Address 2' },
            { name: 'propertyCity', description: 'Property City' },
            { name: 'propertyState', description: 'Property State' },
            { name: 'propertyPostalCode', description: 'Property Postal Code' },
            { name: 'assignedToUserId', description: 'Assigned To User Id' },
            { name: 'assignedToUserName', description: 'Assigned To', filterCapabilities: new FilterCapabilities('AssignedToUserId', 'assignedEmployees', 'EMPLOYEE-SELECT', null, 'CashOut', 'assignedToNotSet', 'assignedToSet') },
            { name: 'comparableUrl', description: 'Comparable Url' },
            { name: 'cashOutEmail', description: 'Cash Out Email', displayType: 'email' },
            { name: 'contactName', description: 'Contact Name' },
            { name: 'contactPhone', description: 'Job Contact Phone', displayType: 'tel' },
            { name: 'contactMobile', description: 'Job Contact Mobile', displayType: 'tel' },
            { name: 'customerName', description: 'Customer Name' },
            { name: 'customerEmail', description: 'Customer Email', displayType: 'email' },
            { name: 'customerHomeNumber', description: 'customerHomeNumber', displayType: 'tel' },
            { name: 'customerMobileNumber', description: 'customerMobileNumber', displayType: 'tel' },
            { name: 'customerContactNumber', description: 'Contact Number', displayType: 'tel' }
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getPolicySummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Subscription Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: null },
            { name: 'planName', description: 'Plan Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('planId', 'selectedPlans', 'PLANS', 'selectedPlansExcluded', 'Policy') },
            { name: 'policyNumber', description: 'Subscription #', navigateUrl: '/subscription/{{id}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'serviceFeeAmount', description: 'Service Fee', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'status', description: 'Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('status', 'selectedPolicyStatuses', 'POLICY-STATUS', 'selectedPolicyStatusesExcluded', 'Policy') },
            { name: 'basePrice', description: 'Base Price', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'basePriceRecurring', description: 'Base Price Recurring', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalPrice', description: 'Total Price Recurring', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'isMonthly', description: 'Monthly', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'renewed', description: 'Renewed', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'effectiveDate', description: 'Effective Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('EffectiveDate', 'effectiveDateRange', 'DATE', null, 'Policy', 'effectiveDate_notset', 'effectiveDate_isset') },
            { name: 'canceledDate', description: 'Cancelled Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'totalCashOuts', description: 'Cash Outs', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalContactorInvoices', description: 'Service Pro Invoices', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalPurchaseOrders', description: 'Purchase Orders', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalPayments', description: 'Amount Paid', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'escrowNumber', description: 'Escrow #', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'estimatedClosingDate', description: 'Estimated Closing', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('EstimatedClosingDate', 'estimatedClosingDateRange', 'DATE', null, 'Policy', 'estimatedClosingDate_notset', 'estimatedClosingDate_isset') },
            { name: 'initiatorType', description: 'Initiator Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'expirationDate', description: 'Expiration Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('ExpirationDate', 'expirationDateRange', 'DATE', null, 'Policy') },
            { name: 'coverageType', description: 'Coverage Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('coverageType', 'selectedCoverageTypes', 'COVERAGE-TYPES', 'selectedCoverageTypesExcluded', 'Policy') },
            { name: 'canRenew', description: 'Can Renew', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'accountExecutiveName', description: 'Account Executive', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('AccountExecutiveId', 'selectedAccountExecutives', 'EMPLOYEE-SELECT', 'selectedAccountExecutivesExcluded', 'Policy', 'aeNotSet') },
            { name: 'createdDate', description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('CreatedDate', 'createdDateRange', 'DATE', null, 'Policy') },
            { name: 'lastContact', description: 'Last Contact', isDate: true, filterCapabilities: new FilterCapabilities('LastContact', 'LastContact', 'DATE', null, 'Policy') },
            { name: 'createdByName', description: 'Created By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('CreatedById', 'selectedEmployees', 'EMPLOYEE-SELECT', 'selectedEmployeesExcluded', 'Policy') },
            { name: 'doNotRenew', description: 'Do Not Renew', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'monthsOfCoverage', description: 'Months of Coverage', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'language', description: 'Language', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'titleInvoiceAmount', description: 'Title Invoice Amt', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'profitMargin', description: 'Profit Margin', navigateUrl: null, dependentFields: ['totalPayments', 'totalContractorInvoices', 'totalPurchaseOrders', 'totalCashOuts'], displayType: null, isDate: false },
            { name: 'totalRevenue', description: 'Total Revenue', navigateUrl: null, dependentFields: ['totalPayments'], displayType: null, isDate: false },
            { name: 'totalExpenses', description: 'Total Expenses', navigateUrl: null, dependentFields: ['totalContractorInvoices', 'totalPurchaseOrders', 'totalCashOuts'], displayType: null, isDate: false },
            { name: 'salesTerritoryName', description: 'Sales Territory', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('SalesTerritoryId', 'selectedSalesRegions', 'SALES-TERRITORIES', 'selectedSalesRegionsExcluded', 'Policy') },
            { name: 'marketingSourceName', description: 'Marketing Source', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('MarketingSourceId', 'selectedMarketingSources', 'MARKETING-SOURCES', 'selectedMarketingSourcesExcluded', 'Policy') },
            { name: 'salesAreaName', description: 'Sales Area', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('SalesAreaId', 'selectedRegions', 'SALES-AREAS', 'selectedRegionsExcluded', 'Policy') },
            { name: 'salesAreaId', description: 'Sales Area Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerName', description: 'Customer Name', navigateUrl: null, dependentFields: ['holder'], displayType: null, isDate: false },
            { name: 'customerLastName', description: 'Customer Last Name', dependentFields: ['holder'] },
            { name: 'customerFirstName', description: 'Customer First Name', dependentFields: ['holder'] },
            { name: 'customerEmail', description: 'Customer Email', navigateUrl: null, dependentFields: ['holder'], displayType: 'email', isDate: false },
            { name: 'customerHomeNumber', description: 'Customer Home #', navigateUrl: null, dependentFields: ['EntityPhoneNumber'], displayType: 'tel', isDate: false },
            { name: 'customerMobileNumber', description: 'Customer Mobile #', navigateUrl: null, dependentFields: ['EntityPhoneNumber'], displayType: 'tel', isDate: false },
            { name: 'propertyAddress1', description: 'Property Address', navigateUrl: null, dependentFields: ['PropertyAddress'], displayType: null, isDate: false },
            { name: 'propertyAddress2', description: 'Property Address 2', navigateUrl: null, dependentFields: ['PropertyAddress'], displayType: null, isDate: false },
            { name: 'propertyCity', description: 'Property City', navigateUrl: null, dependentFields: ['PropertyAddress'], displayType: null, isDate: false },
            { name: 'propertyState', description: 'Property State', navigateUrl: null, dependentFields: ['PropertyAddress'], displayType: null, isDate: false },
            { name: 'propertyPostalCode', description: 'Property Postal Code', navigateUrl: null, dependentFields: ['PropertyAddress'], displayType: null, isDate: false },
            { name: 'renewalNote', description: 'Renewal Note', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, editable: true },
            { name: 'closingOfficerName', description: 'Closing Officer Name', navigateUrl: null, dependentFields: ['ClosingOfficer'], displayType: null, isDate: false },
            { name: 'closingOfficerEmail', description: 'Closing Officer Email', navigateUrl: null, dependentFields: ['ClosingOfficer'], displayType: null, isDate: false },
            { name: 'buyersAgentName', description: 'Buyers Agent' },
            { name: 'sellersAgentName', description: 'Sellers Agent' },
            { name: 'createdSource', description: 'Platform Source', filterCapabilities: new FilterCapabilities('createdSource', 'selectedCreatedSource', 'CREATED-SOURCE', 'selectedCreatedSourceExcluded', 'Policy') },
            { name: 'hasPayments', description: 'Has Payments', filterCapabilities: new FilterCapabilities('hasPayments', 'hasPayments', 'BOOL', null, 'Policy') },
            { name: 'noPhoneNumber', description: 'No Phone Number', filterCapabilities: new FilterCapabilities('noPhoneNumber', 'noPhoneNumber', 'BOOL', null, 'Policy') },
            { name: 'renewalEmployeeId', description: 'Renewal Employee Id', navigateUrl: null, dependentFields: null, displayType: null, filterCapabilities: new FilterCapabilities('RenewalEmployeeId', 'selectedRenewalEmployees', 'EMPLOYEE-SELECT', 'selectedRenewalEmployeesExcluded', 'Policy') },
            { name: 'renewalEmployeeThumbnailUrl', description: 'Renewal Employee Image', navigateUrl: null, dependentFields: ['renewalEmployeeId'], displayType: 'img', isDate: false },
            { name: 'salesTerritoryName', description: 'Sales Territory' },
            { name: 'tags', description: 'Tags', displayType: 'Tags' },
            { name: 'mlsNumber', description: 'MLS #' },
            { name: 'billTo', description: 'Bill To' },
            { name: 'pastDueServiceFeeInvoices', description: '# of Service Fee Invoice Past Due' },
            { name: 'serviceFeeInvoiceMinimumDueDate', description: 'Oldest Past Due Service Fee Invoice Due Date' },
            { name: 'pastDuePremiumInvoices', description: '# of Premium Invoice Past Due' },
            { name: 'premiumInvoiceMinimumDueDate', description: 'Oldest Past Due Premium Invoice Due Date', filterCapabilities: new FilterCapabilities('premiumInvoiceMinimumDueDate', 'premiumInvoiceMinimumDueDate', 'DATE', null, 'Policy') },
            { name: 'hasPastDuePremiumInvoices', description: 'Has Past Due Premium Invoices', filterCapabilities: new FilterCapabilities('hasPastDuePremiumInvoices', 'hasPastDuePremiumInvoices', 'BOOL', null, 'Policy') },
            { name: 'hasPastDueServiceFeeInvoices', description: 'Has Past Due Service Fee Invoices', filterCapabilities: new FilterCapabilities('hasPastDueServiceFeeInvoices', 'hasPastDueServiceFeeInvoices', 'BOOL', null, 'Policy') },
            { name: 'hasStripeCard', description: 'Has Stripe Card', filterCapabilities: new FilterCapabilities('hasStripeCard', 'hasStripeCard', 'BOOL', null, 'Policy') },

        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getEntitySummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'name', description: 'Name', navigateUrl: '/service-pro-relations/{{id}}/overview', dependentFields: null, displayType: null, isDate: false },
            { name: 'email', description: 'Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'address1', description: 'Mailing Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'city', description: 'Mailing City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'state', description: 'Mailing State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'postalCode', description: 'Mailing Postal Code', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'type', description: 'Entity Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('type', 'type', 'ENTITY-TYPE', 'notType', 'Entity') },
            { name: 'assignedEmployeeId', description: 'Assigned Employee Id' },
            { name: 'assignedEmployeeName', description: 'Assigned Employee', dependentFields: ['assignedEmployeeId', 'assignedEmployeeName'], filterCapabilities: new FilterCapabilities('assignedEmployeeId', 'selectedEmployees', 'EMPLOYEE-SELECT', 'selectedEmployeesExcluded', 'Entity') },
            { name: 'homeNumber', description: 'Home Phone #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'mobileNumber', description: 'Mobile Phone #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'workNumber', description: 'Work Phone #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'liabilityInsuranceExpirationDate', description: 'Liability Exp', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'rocLicenseExpirationDate', description: 'ROC License Exp', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workmansCompExpirationDate', description: 'Workman\'s Comp Exp', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'servicerAgreementExpirationDate', description: 'Agreement Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'additionalInsuranceExpirationDate', description: 'Additional Insured', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'inactive', description: 'Inactive', filterCapabilities: new FilterCapabilities('inactive', 'inactive', 'BOOL', null, 'Entity') }
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getPurchaseOrderSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Purchase Order Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'purchaseOrderNumber', description: 'Purchase Order #', navigateUrl: '/purchasing/purchase-order/{{workOrderId}}/{{id}}', dependentFields: ['workOrderId', 'purchaseOrderNumber'], displayType: null, isDate: false },
            { name: 'name', description: 'Vendor Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('distributorId', 'selectedDistributors', 'VENDOR', 'selectedDistributorsExcluded', 'PurchaseOrder') },
            { name: 'workOrderId', description: 'Job Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'amount', description: 'Amount', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'email', description: 'Vendor Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'approvedDate', description: 'Approved Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('approvedDate', 'dateRange2', 'DATE', null, 'PurchaseOrder', 'dateRange2IsNull', 'dateRange2IsNotNull') },
            { name: 'lastHomeownerNotificationDate', description: 'Homeowner Notified', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('lastHomeownerNotificationDate', 'dateRange2', 'DATE', null, 'PurchaseOrder', 'dateRange2IsNull', 'dateRange2IsNotNull') },
            { name: 'lastServiceProNotificationDate', description: 'Service Pro Notified', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('lastHomeownerNotificationDate', 'dateRange2', 'DATE', null, 'PurchaseOrder', 'dateRange2IsNull', 'dateRange2IsNotNull') },
            { name: 'createdDate', description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('createdDate', 'createdDateRange', 'DATE', null, 'PurchaseOrder') },
            { name: 'sentDate', description: 'Sent Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('sentDate', 'dateRange3', 'DATE', null, 'PurchaseOrder', 'dateRange3IsNull', 'dateRange3IsNotNull') },
            { name: 'distributorId', description: 'Vendor Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderNumber', description: 'Job #', navigateUrl: '/service/claim-detail/{{workOrderId}}', dependentFields: ['workOrderId', 'workOrderNumber'], displayType: null, isDate: false },
            { name: 'workOrderItemId', description: 'Job Item Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderItemName', description: 'Job Item', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('workOrderItemId', 'selectedItems', 'WORK-ORDER-ITEM', 'selectedItemsExcluded', 'PurchaseOrder') },
            { name: 'workOrderItemThumbnailUrl', description: 'Item', navigateUrl: null, dependentFields: ['workOrderItemId'], displayType: 'img', isDate: false },
            { name: 'tradeId', description: 'Trade Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'tradeName', description: 'Trade Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('tradeId', 'selectedTrades', 'TRADE', 'selectedTradesExcluded', 'PurchaseOrder') },
            { name: 'createdById', description: 'Created By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdByName', description: 'Created By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('createdById', 'selectedEmployees', 'EMPLOYEE-SELECT', 'selectedEmployeesExcluded', 'PurchaseOrder') },
            { name: 'createdByEmail', description: 'Created By Email', navigateUrl: null, dependentFields: null, displayType: 'email', isDate: false },
            { name: 'createdByThumbnailUrl', description: 'Created By Image', navigateUrl: null, dependentFields: ['createdById'], displayType: 'img', isDate: false },
            { name: 'receivedDate', description: 'Received Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('receivedDate', 'dateRange4', 'DATE', null, 'PurchaseOrder', 'dateRange4IsNull', 'dateRange4IsNotNull') },
            { name: 'approvedById', description: 'Approved By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'sentById', description: 'Sent By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'receivedById', description: 'Received By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'approvedByName', description: 'Approved By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'sentByName', description: 'Sent By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'receivedByName', description: 'Received By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'approvedByThumbnailUrl', description: 'Approved By', navigateUrl: null, dependentFields: ['approvedById'], displayType: 'img', isDate: false },
            { name: 'sentByThumbnailUrl', description: 'Sent By', navigateUrl: null, dependentFields: ['sentById'], displayType: 'img', isDate: false },
            { name: 'receivedByThumbnailUrl', description: 'Received By', navigateUrl: null, dependentFields: ['receivedById'], displayType: 'img', isDate: false },
            { name: 'areaId', description: 'Area Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'areaName', description: 'Area Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('AreaId', 'selectedRegions', 'REGIONS', 'selectedRegionsExcluded', 'PurchaseOrder') },
            { name: 'purchaseOrderStatusId', description: 'Status Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: null },
            { name: 'status', description: 'Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('purchaseOrderStatusId', 'status', 'PURCHASE-ORDER-STATUS', 'notStatus', 'PurchaseOrder', 'statusNotSet', 'statusSet') },
            { name: 'holderName', description: 'Holder Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: null },
            { name: 'workOrderCreatedDate', description: 'Job Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('workOrderCreatedDate', 'workOrderCreatedDate', 'DATE', null, 'PurchaseOrder') },
            { name: 'formattedContractorPhoneNumber', description: 'Service Pro Phone #', dependentFields: ['contractorPhone'] },
            { name: 'partNumbers', description: 'Part #\'s' },
            { name: 'notes', description: 'Notes', editable: true },
            { name: 'trackingNumber', description: 'Tracking #', editable: true },
            { name: 'assignedToUserId', description: 'Assigned To User Id' },
            { name: 'assigedToName', description: 'Assigned To', filterCapabilities: new FilterCapabilities('AssignedToUserId', 'assignedEmployees', 'EMPLOYEE-SELECT', null, 'PurchaseOrder', 'assignedToNotSet', 'assignedToSet') },
            { name: 'workOrderStatus', description: 'Job Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('WorkOrderStatusId', 'selectedWorkOrderStatuses', 'WORK-ORDER-STATUS', 'selectedWorkOrderStatusesExcluded', 'PurchaseOrder', 'notSet', 'isSet') },
            { name: 'holderEmail', description: 'Holder Email', displayType: 'email' },
            { name: 'contractorName', description: 'Service Pro Name', filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Service', 'isContractorNull') },
            { name: 'contractorEmail', description: 'Service Pro Email', displayType: 'email' },
            { name: 'vendorNumber', description: 'Vendor Invoice #', editable: true },
            { name: 'contactEmail', description: 'Contact Email' },
            { name: 'contactName', description: 'Contact Name' },
            { name: 'contactPhone', description: 'Contact Phone' },
            { name: 'contactMobile', description: 'Contact Mobile' },
            { name: 'estimatedDeliveryDate', description: 'ETA', editable: true, isDate: true },
            { name: 'formattedEstimatedDeliveryDate', description: 'Short ETA', dependendFields: ['estimatedDeliveryDate'] },
            { name: 'contractorPhone', description: 'Contractor #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'cashOutOfferAmount', description: 'Cash Out Offer' },
            { name: 'isReturn', description: 'Is Return', filterCapabilities: new FilterCapabilities('isReturn', 'isReturn', 'BOOL', null, 'PurchaseOrder') },
            { name: 'propertyAddress1', description: 'Property Address 1' },
            { name: 'propertyAddres2', description: 'Prpoerty Address 2' },
            { name: 'propertyCity', description: 'Property City' },
            { name: 'propertyState', description: 'Property State' },
            { name: 'propertyPostalCode', description: 'Property Zip' },
            { name: 'catalogNumber', description: 'Catalog #' }
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getTaskSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Task Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'taskNumber', description: 'Task #', navigateUrl: '/users/tasks/{{id}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'createdDate', description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('createdDate', 'createdDateRange', 'DATE', null, 'Task') },
            // { name: 'dueDate', description: 'Due Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'title', description: 'Title', navigateUrl: '/users/tasks/{{id}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'description', description: 'Description', navigateUrl: null, dependentFields: null, displayType: 'html', isDate: false },
            { name: 'completedDate', description: 'Completed Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('completedDate', 'dateRange2', 'DATE', null, 'Task') },
            { name: 'ownerId', description: 'Owner Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'ownerThumbnailUrl', description: 'Owner Image', navigateUrl: null, dependentFields: ['ownerId'], displayType: 'img', isDate: false },
            { name: 'ownerName', description: 'Owner Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('ownerId', 'selectedEmployees', 'EMPLOYEE-SELECT', 'selectedEmployeesExcluded', 'Task', 'employeeNotSet', 'employeeNotSetExcluded') },
            { name: 'createdById', description: 'Created By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdByThumbnailUrl', description: 'Creator Image', navigateUrl: null, dependentFields: ['createdById'], displayType: 'img', isDate: false },
            { name: 'createdByName', description: 'Created By Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('createdById', 'selectedCreatedBy', 'EMPLOEE-SELECT', 'selectedCreatedByExcluded', 'Task') },
            { name: 'createdByEmail', description: 'Created By Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'followUp', description: 'Follow Up', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'followUpDate', description: 'Follow Up Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'isOverDue', description: 'Is Over Due', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'priority', description: 'Priority', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'taskQueueId', description: 'Task Queue Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'taskQueueName', description: 'Task Queue Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('taskQueueId', 'selectedTaskQueues', 'TASK-QUEUE', 'selectedTaskQueuesExcluded', 'Task') },
            { name: 'taskEntityEmail', description: 'Task Entity Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyHolderId', description: 'Subscription Holder Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'areaName', description: 'Area', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('AreaId', 'selectedRegions', 'REGIONS', 'selectedRegionsExcluded', 'Task') },
            { name: 'areaId', description: 'Area Id' },
            { name: 'policyHolderName', description: 'Holder Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyHolderMobile', description: 'Holder Mobile Number', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'policyHolderHome', description: 'Holder Home Number', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'workOrderItemId', description: 'Job Item Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderItemName', description: 'Job Item', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('workOrderItemId', 'selectedItems', 'WORK-ORDER-ITEM', 'selectedItemsExcluded', 'Task') },
            { name: 'workOrderItemThumbnailUrl', description: 'Job Item', navigateUrl: null, dependentFields: ['workOrderItemId'], displayType: 'img', isDate: false },
            { name: 'workOrderType', description: 'Job Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contactName', description: 'Contact Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contactPhone', description: 'Contact Home Number', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'contactMobile', description: 'Contact Mobile Number', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'contactEmail', description: 'Contact Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderId', description: 'Job Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderNumber', description: 'Job #', navigateUrl: '/service/claim-detail/{{workOrderId}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'tradeId', description: 'Trade Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'tradeName', description: 'Trade Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('tradeId', 'selectedTrades', 'TRADE', 'selectedTradesExcluded', 'Task') },
            { name: 'contractorId', description: 'Service Pro Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorName', description: 'Service Pro Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Task') },
            { name: 'contractorEmail', description: 'Service Pro Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorOffice', description: 'Service Pro Office #', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorMobile', description: 'Service Pro Mobile #', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorThumbnailUrl', description: 'Service Pro', navigateUrl: null, dependentFields: ['contractorId'], displayType: 'img', isDate: false },
            { name: 'propertyThumbnailUrl', description: 'Property', navigateUrl: null, dependentFields: ['policyId'], displayType: 'img', isDate: false },
            { name: 'status', description: 'Task Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('status', 'selectedTaskStatus', 'TASK-STATUS', 'selectedTaskStatusExcluded', 'Task') },
            { name: 'slaStatus', description: 'SLA Status', navigateUrl: null, dependentFields: ['status'], displayType: null, isDate: false },
            { name: 'percentComplete', description: 'Percent Complete', navigateUrl: null, dependentFields: null, displayType: 'percent', isDate: false },
            { name: 'policyNumber', description: 'Subscription #', navigateUrl: '/subscription/{{policyId}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'createdByOfficeNumber', description: 'Created By Office #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'createdByMobileNumber', description: 'Created By Mobile #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'createdByHomeNumber', description: 'Created By Home #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'createdByContactPhone', description: 'Created By Contact #', navigateUrl: null, dependentFields: ['createdByOfficeNumber', 'createdByMobileNumber', 'createdByHomeNumber'], displayType: 'tel', isDate: false },
            { name: 'timeOpen', description: 'Time Open', navigateUrl: null, dependentFields: ['createdDate'], displayType: null, isDate: false },
            { name: 'effort', description: 'Effort', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'tagIds', description: 'Tag Ids', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderLineId', description: 'Job Line Id' },
            { name: 'tags', description: 'Tags', displayType: 'Tags', dependentFields: ['tagsJson'] },
            { name: 'propertyAddress', description: 'Address' },
            { name: 'propertyCity', description: 'City' },
            { name: 'propertyState', description: 'State' },
            { name: 'propertyPostalCode', description: 'Postal Code' } 
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getContractorInvoiceSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Invoice Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderId', description: 'Job Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdById', description: 'Created By Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdDate', description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('createdDate', 'createdDateRange', 'DATE', null, 'ContractorInvoice') },
            { name: 'partsCost', description: 'Parts Cost', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'laborCost', description: 'Labor Cost', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'tax', description: 'Tax', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'amount', description: 'Invoice Total', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'paidDate', description: 'Paid Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'contractorInvoiceNumber', description: 'Service Pro Invoice #', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'approvedDate', description: 'Approved Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('approvedDate', 'dateRange2', 'DATE', null, 'ContractorInvoice') },
            { name: 'contractorId', description: 'Service Pro Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'exportDate', description: 'Export Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('exportDate', 'dateRange3', 'DATE', null, 'ContractorInvoice') },
            { name: 'areaId', description: 'Area Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'areaName', description: 'Area', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('areaId', 'selectedRegions', 'REGIONS', 'selectedRegionsExcluded', 'ContractorInvoice') },
            { name: 'policyId', description: 'Policy Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderItemName', description: 'Job Item', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('itemId', 'selectedItems', 'WORK-ORDER-ITEM', null, 'ContractorInvoice') },
            { name: 'contractorName', description: 'Service Pro Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'ContractorInvoice') },
            { name: 'workOrderNumber', description: 'Job #', navigateUrl: '/service/claim-detail/{{workOrderId}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorAddress1', description: 'Service Pro Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorCity', description: 'Service Pro City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorState', description: 'Service Pro State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorPostalCode', description: 'Service Pro Postal Code', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'receivedDate', description: 'Received Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'tradeId', description: 'Trade Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'authorizationLimit', description: 'Autho Limit', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'itemId', description: 'Job Item Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workOrderItemThumbnailUrl', description: 'Item', navigateUrl: null, dependentFields: ['itemId'], displayType: 'img', isDate: false },
            { name: 'dateCompleted', description: 'Date Completed', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'tradeName', description: 'Trade Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('tradeId', 'selectedTrades', 'TRADE', 'selectedTradesExcluded', 'ContractorInvoice') },
            { name: 'workOrderType', description: 'Job Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('workOrderType', 'selectedCallTypes', 'JOB-TYPE', 'selectedCallTypesExcluded', 'ContractorInvoice') },
            { name: 'status', description: 'Invoice Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('status', 'selectedContractorInvoiceStatuses', 'CONTRACTOR-INVOICE-STATUS', 'selectedContractorInvoiceStatusesExcluded', 'PurchaseOrder') },

        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getInvoiceSummaryFields(): ObjectSummaryField[] {
        const items = [

            { name: 'id', description: 'Invoice Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'invoiceNumber', description: 'Invoice #', navigateUrl: '/subscriptions/{{policyId}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'customerName', description: 'Customer Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerAddress1', description: 'Customer Mailing Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerAddressCity', description: 'Customer Mailing City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerAddressState', description: 'Customer Mailing State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerAddressPostalCode', description: 'Customer Mailing Postal Code', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'customerEmail', description: 'Customer Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'amount', description: 'Amount', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'amountDue', description: 'Amount Due', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'dueDate', description: 'Due Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'policyAddress1', description: 'Subscription Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyAddressCity', description: 'Subscription City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyAddressState', description: 'Subscription State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyAddressPostalCode', description: 'Subscription Postal Code', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'companyName', description: 'Company Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'companyId', description: 'Company Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'companyAddress1', description: 'Company Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'companyAddressCity', description: 'Company City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'companyAddressState', description: 'Company State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdDate', description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('createdDate', 'createdDateRange', 'DATE', null, 'Invoice') },
            { name: 'holderId', description: 'Holder Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'voidDate', description: 'Void Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true },
            { name: 'policyId', description: 'Subscription Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'policyNumber', description: 'Subscription #', navigateUrl: '/subscription/{{policyId}}', dependentFields: ['policyId', 'policyNumber'], displayType: null, isDate: false },
            { name: 'description', description: 'Description', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'loginId', description: 'Login Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'areaName', description: 'Area', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('areaId', 'selectedRegions', 'REGIONS', 'selectedRegionsExcluded', 'Invoice') },
            { name: 'status', description: 'Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('status', 'selectedContractorInvoiceStatuses', 'INVOICE-STATUS', 'selectedContractorInvoiceStatusesExcluded', 'Invoice') },
            { name: 'invoiceType', description: 'Invoice Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('invoiceType', 'selectedInvoiceTypes', 'INVOICE-TYPE', 'selectedInvoiceTypesExcluded', 'Invoice') },
            { name: 'customerHomeNumber', description: 'Customer Home Phone', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'customerMobileNumber', description: 'Customer Mobile Phone', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'customerContactNumber', description: 'Customer Contact Phone', navigateUrl: null, dependentFields: ['customerHomeNumber', 'customerMobileNumber'], displayType: 'tel', isDate: false },
            { name: 'exportDate', description: 'Export Date', navigateUrl: null, dependentFields: null, displayType: null, isDate: true, filterCapabilities: new FilterCapabilities('exportDate', 'dateRange3', 'DATE', null, 'Service') },
            { name: 'isMonthly', description: 'Is Monthly', filterCapabilities: new FilterCapabilities('isMonthly', 'isMonthlySelected', 'BOOL', null, 'Invoice') },
            { name: 'effectiveDate', desription: 'Effective Date', isDate: true, filterCapabilities: new FilterCapabilities('effectiveDate', 'effectiveDateRange', 'DATE', null, 'Invoice', 'createdDateNotSet', 'createdDateIsSet') },
            { name: 'policyCreatedDate', description: 'Subscription Created', isDate: true, filterCapabilities: new FilterCapabilities('policyCreatedDate', 'policyCreatedDateRange', 'DATE', null, 'Invoice') },
            { name: 'expirationDate', description: 'Expiration Date', isDate: true, filterCapabilities: new FilterCapabilities('expirationDate', 'expirationDateRange', 'DATE', null, 'Invoice') },
            { name: 'coverageType', description: 'Coverage Type', filterCapabilities: new FilterCapabilities('coverageType', 'selectedCoverageTypes', 'COVERAGE-TYPES', 'selectedCoverageTypesExcluded', 'Invoice') },
            { name: 'policyStatus', description: 'Subscription Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('policyStatus', 'selectedPolicyStatuses', 'POLICY-STATUS', 'selectedPolicyStatusesExcluded', 'Policy') },
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getMaintenanceServiceCustomerPropertyServiceSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Id' },
            { name: 'maintenanceServiceCustomerPropertyId', description: 'Property Id' },
            { name: 'price', description: 'Price' },
            { name: 'contractorName', description: 'Contractor Name', filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Service', 'isContractorNull', 'isContractorSet') },
            { name: 'contractorId', description: 'Contractor Id' },
            { name: 'effectiveDate', description: 'Effective Date', filterCapabilities: new FilterCapabilities('effectiveDate', 'dateRange2', 'DATE', null, 'Service', 'effectiveDateNotSet', 'effectiveDateIsSet') },
            { name: 'expirationDate', description: 'Expiration Date', filterCapabilities: new FilterCapabilities('effectiveDate', 'dateRange3', 'DATE', null, 'Service', 'expirationDateNotSet', 'expirationDateIsSet') },
            { name: 'cancellationDate', description: 'Cancellation Date', filterCapabilities: new FilterCapabilities('cancellationDate', 'dateRange4', 'DATE', null, 'Service', 'cancellationDateNotSet', 'cancellationDateIsSet') },
            { name: 'maintenanceServiceOfferingId', description: 'Offering Id' },
            { name: 'shortSummary', description: 'Summary' },
            { name: 'name', description: 'Name', filterCapabilities: new FilterCapabilities('maintenanceServiceId', 'selectedMaintenanceServices', 'MAINT-SERVICES', 'selectedMaintenanceServicesExcluded', 'Service') },
            { name: 'contractLanguage', description: 'Contract Language' },
            { name: 'color', description: 'Color' },
            { name: 'maintenanceServiceId', description: 'Maintenance Service Id' },
            { name: 'frequencyName', description: 'Frequency Name' },
            { name: 'preferredDay', description: 'Preferred Day' },
            { name: 'propertyAddress1', description: 'Property Address 1' },
            { name: 'propertyAddress2', description: 'Property Address 2' },
            { name: 'propertyCity', description: 'Property City' },
            { name: 'propertyState', description: 'Property State' },
            { name: 'propertyPostalCode', description: 'Property Postal Code' },
            { name: 'customerName', description: 'Customer Name', navigateUrl: '/subscription/maint-service-customer-property/{{maintenanceServiceCustomerPropertyId}}' },
            { name: 'customerEmail', description: 'Customer Email' },
            { name: 'customerPhoneNumber', description: 'Customer Phone #' },
            { name: 'latitude', description: 'Latitude' },
            { name: 'longitude', description: 'Longitude' },
            { name: 'cost', description: 'Cost' },
            { name: 'preferredDayName', description: 'Preferred Day Name' },
            { name: 'customerId', description: 'Customer Id' },
            { name: 'contractorEmail', description: 'Contractor Email' },
            { name: 'contactViaEmail', description: 'Contact Via Email' },
            { name: 'contactViaSMSText', description: 'Contact Via SMS' },
            { name: 'contractorNumber', description: 'Contractor Number' },
            { name: 'addressId', description: 'Address Id' },
            { name: 'schedulingBufferDays', description: 'Scheduling Buffer Days' },
            { name: 'startDate', description: 'Start Date' },
            { name: 'pricePerMonth', description: 'Price/Month' },
            { name: 'visitsPerYear', description: 'Visits/Year' },
            { name: 'monthlyPaymentDate', description: 'Monthly Payment Date' },
            { name: 'createdDate', description: 'Created Date', filterCapabilities: new FilterCapabilities('createdDate', 'dateRange5', 'DATE', null, 'Service', 'createdDateDateNotSet', 'createdDateDateIsSet') },
            { name: 'firstAppointment', description: '1st Appointment', filterCapabilities: new FilterCapabilities('firstAppointment', 'firstAppointment', 'DATE', null, 'Service', 'firstAppointmentNotSet', 'firstAppointmentIsSet') },
            { name: 'maintenanceServicePriceId', description: 'Maintenance Service Price Id' },
            { name: 'appointmentLines', description: 'Appointment Lines' },
            { name: 'isOneTime', description: 'Is One-time' },
            { name: 'addOnFunctionId', description: 'Add On Function Id' },
            { name: 'costPerMonth', description: 'Cost/Month' },
            { name: 'status', description: 'Status', filterCapabilities: new FilterCapabilities('status', 'status', 'POLICY-STATUS', null, 'Service', 'selectedServiceStatusNotSet', 'statusIsSet') },
            { name: 'allowChangeFrequency', description: 'Allow Change Frequency' },
            { name: 'noRefundOnCancellation', description: 'No Refund On Cancellation' },
            { name: 'lottieAnimationUrl', description: 'Lottie URL' },
            { name: 'pastDueMaintInvoices', description: 'Past Due Maintenance Service Invoices' },
            { name: 'maintInvoiceMinimumDueDate', description: 'Oldest Invoice Due Date' },
            { name: 'hasPastDueMaintenanceServiceInvoices', description: 'Has PastDue Maintenance Service Invoices', filterCapabilities: new FilterCapabilities('hasPastDueMaintenanceServiceInvoices', 'hasPastDueMaintenanceServiceInvoices', 'BOOL', null, 'Service') }

        ];
        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getWorkOrderLineSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'id', description: 'Id' },
            { name: 'workOrderId', description: 'Job Id' },
            { name: 'line', description: 'Line #' },
            { name: 'itemId', description: 'Item Id' },
            { name: 'authorizationLimit', description: 'Authorization Limit' },
            { name: 'dateCompleted', description: 'Date Completed', isDate: true, filterCapabilities: new FilterCapabilities('dateCompleted', 'dateRange2', 'DATE', null, 'Service') },
            { name: 'resolution', description: 'Resolution' },
            { name: 'cancelledDate', description: 'Cancelled Date', isDate: true, filterCapabilities: new FilterCapabilities('cancelledDate', 'dateRange3', 'DATE', null, 'Service') },
            { name: 'cancelledSentDate', description: 'Cancelled Sent Date', isDate: true, filterCapabilities: new FilterCapabilities('cancelledSentDate', 'dateRange4', 'DATE', null, 'Service') },
            { name: 'totalCashOuts', description: 'Cash Outs' },
            { name: 'totalContractorInvoices', description: 'Invoices' },
            { name: 'totalPurchaseOrders', description: 'Purchase Orders' },
            { name: 'workOrderStatusId', description: 'Work Order Status Id' },
            { name: 'authorizationAmount', description: 'Autho Amount' },
            { name: 'authorizationLabor', description: 'Autho Labor' },
            { name: 'authorizationExportDate', description: 'Autho Export Date' },
            { name: 'authorizationApprovedDate', description: 'Autho Approved Date', isDate: true, filterCapabilities: new FilterCapabilities('authorizationApprovedDate', 'dateRange5', 'DATE', null, 'Service') },
            { name: 'authorizationDeniedDate', description: 'Autho Denied Date', isDate: true, filterCapabilities: new FilterCapabilities('authorizationDeniedDate', 'dateRange6', 'DATE', null, 'Service') },
            { name: 'createdDate', description: 'Line Created', isDate: true, filterCapabilities: new FilterCapabilities('createdDate', 'dateRange7', 'DATE', null, 'Service') },
            { name: 'lastModifiedDate', description: 'Line Last Modified', isDate: true, filterCapabilities: new FilterCapabilities('lastModifiedDate', 'dateRange8', 'DATE', null, 'Service') },
            { name: 'createdById', description: 'Created By Id' },
            { name: 'lastModifiedById', description: 'Last Modified By Id' },
            { name: 'brandId', description: 'Brand Id' },
            { name: 'itemName', description: 'Item Name', filterCapabilities: new FilterCapabilities('itemId', 'selectedItems', 'WORK-ORDER-ITEM', 'selectedItemsExcluded', null, 'Service') },
            { name: 'iconClass', description: 'Status Icon' },
            { name: 'status', description: 'Job Status', filterCapabilities: new FilterCapabilities('workOrderStatusId', 'selectedWorkOrderStatuses', 'WORK-ORDER-STATUS', 'selectedWorkOrderStatusesExcluded', 'Service') },
            { name: 'percentComplete', description: '% Complete' },
            { name: 'workOrderNumber', description: 'Job #', navigateUrl: '/service/claim-detail/{{id}}' },
            { name: 'contractorName', description: 'Contractor Name', filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Service', 'isContractorNull') },
            { name: 'contractorId', description: 'Service Pro Id' }
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }

    public static getWorkOrderSummaryFields(): ObjectSummaryField[] {
        const items = [
            { name: 'number', description: 'Job #', navigateUrl: '/service/claim-detail/{{id}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'itemName', description: 'Item', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('itemId', 'selectedItems', 'WORK-ORDER-ITEM', 'selectedItemsExcluded', null, 'Service') },
            { name: 'propertyAddress', description: 'Property Address', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'propertyCity', description: 'Property City', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'propertyState', description: 'Property State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'propertyPostalCode', description: 'Property Postal Code', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'homeownerName', description: 'Homeowner', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contactName', description: 'Contact', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contactPhone', description: 'Contact Phone #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'contactEmail', description: 'Contact Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contactMobile', description: 'Contact Mobile #', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'additionalNotes', description: 'Notes', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorName', description: 'Service Pro', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('contractorId', 'selectedContractors', 'CONTRACTOR', 'selectedContractorsExcluded', 'Service', 'isContractorNull', 'isContractorNotNull') },
            { name: 'contractorPhone', description: 'Service Pro Phone #', navigateUrl: null, dependentFields: null, displayType: 'tel', isDate: false },
            { name: 'dateClosed', description: 'Date Closed', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'status', description: 'Status', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('workOrderStatusId', 'selectedWorkOrderStatuses', 'WORK-ORDER-STATUS', 'selectedWorkOrderStatusesExcluded', 'Service') },
            { name: 'createdDate', isDate: true, description: 'Created Date', navigateUrl: null, dependentFields: null, displayType: null, filterCapabilities: new FilterCapabilities('createdDate', 'createdDateRange', 'DATE', null, 'Service') },
            { name: 'authorizationLimit', description: 'Autho Limit', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'type', description: 'Job Type', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('type', 'selectedCallTypes', 'JOB-TYPE', 'selectedCallTypesExcluded', 'Service') },
            { name: 'scheduledDate', isDate: true, description: 'Appointment Date', navigateUrl: null, dependentFields: null, displayType: null, filterCapabilities: new FilterCapabilities('scheduledDate', 'dateRange3', 'DATE', null, 'Service', 'scheduledDateNotSet', 'scheduledDateIsSet') },
            { name: 'scheduledStartWindow', description: 'Appointment Start', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'scheduledEndWindow', description: 'Appointment End', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'dateCompleted', isDate: true, description: 'Job Complete Date', navigateUrl: null, dependentFields: null, displayType: null, filterCapabilities: new FilterCapabilities('dateCompleted', 'dateRange2', 'DATE', null, 'Service') },
            { name: 'dispatchDate', isDate: true, description: 'Dispatch Date', navigateUrl: null, dependentFields: null, displayType: null, },
            { name: 'sentDate', isDate: true, description: 'Date Sent', navigateUrl: null, dependentFields: null, displayType: null, },
            { name: 'cancelledDate', isDate: true, description: 'Date Cancelled', navigateUrl: null, dependentFields: null, displayType: null, },
            { name: 'tradeName', description: 'Trade', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('tradeId', 'selectedTrades', 'TRADE', 'selectedTradesExcluded', 'Service') },
            { name: 'createdByName', description: 'Created By', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('createdById', 'selectedEmployees', 'EMPLOYEE-SELECT', 'selectedEmployeesExcluded', 'Service') },
            { name: 'policyNumber', description: 'Subscription #', navigateUrl: '/subscription/{{policyId}}', dependentFields: null, displayType: null, isDate: false },
            { name: 'totalCashOuts', description: 'Cash Out Amt', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalContractorInvoices', description: 'Service Pro Bill Amt', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'totalPurchaseOrders', description: 'Purchase Order Amt', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'workPerformed', description: 'Work Performed', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'homeownerEmail', description: 'Subscription Holder\'s Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'createdByEmail', description: 'Created By Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'statusDate', isDate: true, description: 'Last Status Date', navigateUrl: null, dependentFields: null, displayType: null, },
            { name: 'propertyThumbnailUrl', description: 'Property', navigateUrl: null, dependentFields: ['policyId'], displayType: 'img', isDate: false },
            { name: 'contractorThumbnailUrl', description: 'Service Pro', navigateUrl: null, dependentFields: ['contractorId'], displayType: 'img', isDate: false },
            { name: 'workOrderItemThumbnailUrl', description: 'Item', navigateUrl: null, dependentFields: ['itemId'], displayType: 'img', isDate: false },
            { name: 'createdByThumbnailUrl', description: 'Created By', navigateUrl: null, dependentFields: ['createdById'], displayType: 'img', isDate: false },
            { name: 'timeInStatus', description: 'Time In Status', navigateUrl: null, dependentFields: ['statusDate'], displayType: null, isDate: false },
            { name: 'contractorEmail', description: 'Service Pro Email', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'id', description: 'Job Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'marketAreaName', description: 'Area Name', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('areaId', 'selectedRegions', 'REGIONS', 'selectedRegionsExcluded', 'Service') },
            { name: 'marketStateName', description: 'State', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'contractorPreferredContactMethod', description: 'Service Pro Preferred Contact', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'itemPriority', description: 'Item Priority', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'technicianId', description: 'Technician Id', navigateUrl: null, dependentFields: null, displayType: null, isDate: false },
            { name: 'appointmentDescription', description: 'Appointment Description', navigateUrl: null, dependentFields: ['scheduledDate', 'scheduledStartWindow', 'scheduledEndWindow'], displayType: null, isDate: false },
            { name: 'assignedToName', description: 'Assigned To', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, filterCapabilities: new FilterCapabilities('AssignedToUserId', 'assignedEmployees', 'EMPLOYEE-SELECT', null, 'Service') },
            { name: 'assignedToThumbnailUrl', description: 'Assigned To', navigateUrl: null, dependentFields: ['assignedToUserId'], displayType: 'img', isDate: false },
            { name: 'internalNote', description: 'Note', navigateUrl: null, dependentFields: null, displayType: null, isDate: false, editable: true },
            { name: 'effectiveDate', description: 'Effective Date', isDate: true },
            { name: 'expirationDate', description: 'Expiration Date', isDate: true },
            { name: 'effectiveDescription', desription: 'Effective Range' },
            { name: 'surveyScore', description: 'Survey Score' },
            { name: 'linesJson', description: 'Lines JSON' },
            { name: 'tags', description: 'Tags', displayType: 'Tags', dependentFields: ['tagsJson'] },
            { name: 'tagsValue', description: 'Tag List' },
            { name: 'cashOutOfferAmount', descriptipion: 'Cash Out Offer' },
            { name: 'whiteGlove', description: 'Is White Glove', filterCapabilities: new FilterCapabilities('whiteGlove', 'whiteGlove', 'BOOL', null, 'Service') },
        ];

        return items.sort((a, b) => a.description > b.description ? 1 : -1) as ObjectSummaryField[];
    }
}
