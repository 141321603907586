import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injectable, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { RatingModule } from "ngx-rating";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule, Routes } from '@angular/router';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { WorkOrderDetailComponent } from '@upkeeplabs/service-pros/app/pages/work-order-detail/work-order-detail';
import { ClaimSummaryComponent } from '@upkeeplabs/service-pros/app/parts/claims/claim-summary/claim-summary';
import { AutoHeightDirective, AutoMinHeightDirective } from '@upkeeplabs/service-pros/app/directives/HeightDirective';
import { NgxMaskDirective } from 'ngx-mask';
import { RecaptchaModule } from 'ng-recaptcha-2';
import { WizardStepIndicatorComponent } from './parts/wizard-step-indicator/wizard-step-indicator.component';
import { ViewCoverageDialogComponent } from './parts/view-coverage/view-coverage.component';
// import { EnterAuthoLineItemComponent } from './parts/enter-autho-line-item/enter-autho-line-item.component';
import { ContractorsHomeComponent } from './pages/contractors-home/contractors-home.component';
import { AccViewComponent } from './parts/contractors/acc-view/acc-view.component';
import { RecallViewComponent } from './parts/contractors/recall-view/recall-view.component';
import { CallTypesViewComponent } from './parts/contractors/call-types-view/call-types-view.component';
import { SurveyViewComponent } from './parts/contractors/survey-view/survey-view.component';
import { WorkOrderSearchComponent } from './parts/contractors/work-order-search/work-order-search.component';
import { WorkOrdersViewComponent } from './parts/contractors/work-orders-view/work-orders-view.component';
import { UpdatesNeededComponent } from './pages/updates-needed/updates-needed.component';
import { ContractorBillingComponent } from './pages/contractor-billing/contractor-billing.component';
import { ContractorSettingsComponent } from './pages/contractor-settings/contractor-settings.component';
import { EnterAuthorizationComponent } from './parts/contractors/enter-authorization/enter-authorization.component';
import { WorkOrderSearchPageComponent } from './pages/work-order-search-page/work-order-search-page.component';
import { ContractorStatementsComponent } from './parts/contractors/contractor-statements/contractor-statements.component';
import { ViewContractorStatementComponent } from './pages/view-contractor-statement/view-contractor-statement.component';
import { CsvImportComponent } from './pages/csv-import/csv-import.component';
import { JobsBoardComponent } from './pages/jobs-board/jobs-board.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { WorkOrderReportComponent } from './pages/work-order-report/work-order-report.component';
import { DashboardContractorKpiChartComponent } from './parts/dashboard-contractor-kpi-chart/dashboard-contractor-kpi-chart.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { StartupService } from './startup.service';
import { BillIndividualWorkOrderComponent } from './parts/contractors/bill-individual-work-order/bill-individual-work-order.component';
import { environment } from '../environments/environment';
import { AuthGuardService } from '@upkeeplabs/authentication';
import { ContractorDocumentsComponent } from './pages/contractor-documents/contractor-documents.component';
import { EntityDocumentsComponent } from './parts/entity-documents/entity-documents.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { AccWidgetComponent } from './parts/acc-widget/acc-widget.component';
import { PendingOffersComponent } from './pages/pending-offers/pending-offers.component';
import { ScheduleViewComponent } from './parts/schedule-view/schedule-view.component';
import { OnlineChatComponent } from './pages/online-chat/online-chat.component';
import { ContractorReportCardComponent } from './pages/contractor-report-card/contractor-report-card.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { UpkeepPipesModule } from '@cogent/client/shared/pipes/upkeep-pipes/upkeep-pipes.module';
import { AuthorizationV4Component } from '@cogent/client/shared/components/autho/authorization-v4/authorization-v4.component';
import { AuthorizationV4WrapperComponent } from '@cogent/client/shared/components/autho/authorization-v4-wrapper/authorization-v4-wrapper.component';
import { CallTypeChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/call-type-chip-selection/call-type-chip-selection.module';
import { AppliancePartSearchModule } from '@cogent/client/shared/components/autho/appliance-part-search/appliance-part-search.module';
import { RegionChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/region-chip-selection/region-chip-selection.module';
import { SlaChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/sla-chip-selection/sla-chip-selection.module';
import { WorkOrderStatusChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/work-order-status-chip-selection/work-order-status-chip-selection.module';
import { TradeChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/trade-chip-selection/trade-chip-selection.module';
import { ContractorContactsComponent } from '@cogent/client/shared/components/contractors/contractor-contacts/contractor-contacts.component';
import { SwapableImageModule } from '@cogent/client/shared/components/misc/swapable-image/swapable-image.module';
import { AddressEditorComponent } from '@cogent/client/shared/components/misc/address-editor/address-editor.component';
import { BasicEntityEntryModule } from '@cogent/client/shared/components/entities/basic-entity-entry/basic-entity-entry.module';
import { DateRangeDisplayAndSelectorComponent } from '@cogent/client/shared/components/misc/date-range-display-and-selector/date-range-display-and-selector.component';
import { TaxReportComponent } from '@cogent/client/shared/components/contractors/tax-report/tax-report.component';
import { RichTextAndSnippetModule } from '@cogent/client/shared/components/misc/rich-text-and-snippet/rich-text-and-snippet.module';
import { ConfigureAreasComponent } from '@cogent/client/shared/components/contractors/configure-areas/configure-areas.component';
import { AppointmentSelectionComponent } from '@cogent/client/shared/components/service/appointment-selection/appointment-selection.component';
import { CheckCircleAnimatedComponent } from '@cogent/client/shared/components/misc/check-circle-animated/check-circle-animated.component';
import { QuestionWizardModule } from '@cogent/client/shared/components/functions/question-wizard/question-wizard.module';
import { TimePickerModule } from '@cogent/client/shared/components/misc/time-picker/time-picker.module';
import { GearsComponent } from '@cogent/client/shared/components/misc/gears/gears.component';
import { CalendarDayViewerModule } from '@cogent/client/shared/components/data-visualization/calendar-day-view/calendar-day-viewer.module';
import { AppointmentSchedulerComponent } from '@cogent/client/shared/components/service/appointment-scheduler/appointment-scheduler.component';
import { CircleWidgetComponent } from '@cogent/client/shared/components/data-visualization/circle-widget/circle-widget.component';
import { CircleWidgetSmallModule } from '@cogent/client/shared/components/data-visualization/circle-widget-small/circle-widget-small.module';
import { CustomFormDisplayComponent } from '@cogent/client/shared/components/functions/custom-form-display/custom-form-display.component';
import { HeightDirectivesModule } from '@cogent/client/shared/directives/height-directives/height-directives.module';
import { DisplayTotalModule } from '@cogent/client/shared/components/data-visualization/display-total/display-total.module';
import { DocumentViewerComponent } from '@cogent/client/shared/components/misc/document-viewer/document-viewer.component';
import { FillOutFormComponent } from '@cogent/client/shared/components/functions/fill-out-form/fill-out-form.component';
import { EntityCommunicationComponent } from '@cogent/client/shared/components/communication/entity-communication/entity-communication.component';
import { ReviewCircleAnimatedModule } from '@cogent/client/shared/components/visuals/review-circle-animated/review-circle-animated.module';
import { ServiceProTerritorySelectComponent } from '@cogent/client/shared/components/chip-selectors/service-pro-territory-select/service-pro-territory-select.component';
import { TakeQuestionnaireComponent } from '@cogent/client/shared/components/functions/take-questionnaire/take-questionnaire.component';
import { SmsLinkButtonComponent } from '@cogent/client/shared/components/communication/sms-link-button/sms-link-button.component';
import { WebSocketHandlerModule } from '@cogent/client/shared/components/website-parts/web-socket-handler/web-socket-handler.module';
import { WorkOrderItemChipSelectionModule } from '@cogent/client/shared/components/chip-selectors/work-order-item-chip-selection/work-order-item-chip-selection.module';
import { RegisterComponent } from './pages/register/register.component';
import { EmptyInboxIconComponent } from '@cogent/client/shared/components/visuals/empty-inbox-icon/empty-inbox-icon.component';
import { DatePickerWrapperComponent } from '@cogent/client/shared/components/misc/date-picker/date-picker-wrapper.component';
import { AddNoteComponent } from './pages/add-note/add-note.component';
import { EditJobItemsStatusComponent } from './pages/edit-job-items-status/edit-job-items-status.component'
import { FindUserNotificationsComponent } from './pages/find-user-notifications/find-user-notifications.component';
import { AuthorizeComponent } from './pages/authorize/authorize.component';
import { AddClosingItemComponent } from './pages/add-closing-item/add-closing-item.component';
import { AuthorizationPhotosComponent } from './parts/authorization-photos/authorization-photos.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AcceptMaintenanceJobComponent } from './pages/accept-maintenance-job/accept-maintenance-job.component';
import { MaintServicesHomeComponent } from './pages/maint-services-home/maint-services-home.component';
import { MaintServicesNavBarComponent } from './parts/maint-services-nav-bar/maint-services-nav-bar.component';
import { MaintServicesCustomerViewComponent } from './pages/maint-services-customer-view/maint-services-customer-view.component';
import { MaintServicesCancellationsComponent } from './pages/maint-services-cancellations/maint-services-cancellations.component';
import { MaintServicesCompleteAppointmentComponent } from './pages/maint-services-complete-appointment/maint-services-complete-appointment.component';
import { MaintServiceWorksheetComponent } from './parts/maint-service-worksheet/maint-service-worksheet.component';
import { MaintServicesBillingComponent } from './pages/maint-services-billing/maint-services-billing.component';
import { MaintServicesReportDashboardComponent } from './pages/maint-services-report-dashboard/maint-services-report-dashboard.component';
import { MaintServicesChangePreferredDayComponent } from './pages/maint-services-change-preferred-day/maint-services-change-preferred-day.component';
import { MaintenanceServiceCalendarComponent } from './pages/maintenance-service-calendar/maintenance-service-calendar.component';
import { OrderPartsComponent } from './pages/order-parts/order-parts.component';
import { OrderPartsAddIndividualComponent } from './pages/order-parts-add-individual/order-parts-add-individual.component';
import { BecomeAContractorComponent } from './pages/become-a-contractor/become-a-contractor.component';
import { ApplianceAuthoComponent } from './pages/appliance-autho/appliance-autho.component';
import { IncompleteAuthosComponent } from './pages/incomplete-authos/incomplete-authos.component';
import { MaintServiceReviewAddOnsComponent } from './pages/maint-service-review-add-ons/maint-service-review-add-ons.component';
import { MaintServicesOpenCustomerComponent } from './pages/maint-services-open-customer/maint-services-open-customer.component';
import { MaintServicesCancelAppointmentComponent } from './pages/maint-services-cancel-appointment/maint-services-cancel-appointment.component';
import { StartServiceRequestComponent } from './pages/start-service-request/start-service-request.component';
import { MaintServicesCreateInvoiceComponent } from './pages/maint-services-create-invoice/maint-services-create-invoice.component';
import { TagNoteComponent } from '@cogent/client/shared/components/contractors/tag-note/tag-note.component';
import { ApiFailureComponent } from '@cogent/client/shared/components/api-failure/api-failure.component';
import { AddOnRequestArgsViewerComponent } from '@cogent/client/shared/components/maintenance-service/add-on-request-args-viewer/add-on-request-args-viewer.component';
import { EmailComposeLinkComponent } from '@cogent/client/shared/components/communication/email-compose-link/email-compose-link.component';

export function startupServiceFactory(startupService: StartupService) {
    return () => startupService.load();
}


@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = {
        swipe: { velocity: 0.4, threshold: 20 },
        pinch: { enable: false },
        rotate: { enable: false }
    };
}

const appRoutes: Routes = [
    {
        path: 'auth',
        loadChildren: () => import('@upkeeplabs/authentication').then(mod => mod.AuthenticationModule)
    },
    {
        path: 'user-notifications',
        component: FindUserNotificationsComponent,
        data: {
            title: 'User Notifications',
            iconClass: 'email',
            description: 'User Notifications',
        }
    },
    {
        path: 'status-report',
        component: ContractorReportCardComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'work-order-detail/:id',
        component: WorkOrderDetailComponent,
        data: { title: 'Work Order Detail' },
    },
    {
        path: '',
        component: ContractorsHomeComponent,
        data: { title: 'Service Pro Home' },
        canActivate: [AuthGuardService]
    },
    {
        path: 'updated-needed',
        component: UpdatesNeededComponent,
        data: {
            title: 'Updated Needed'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'authorize/:id',
        component: AuthorizeComponent,
        data: {
            title: 'Get Authorization'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'pending-add-on/:id',
        component: MaintServiceReviewAddOnsComponent,
        data: {
            title: 'Review Add-On Request'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'billing',
        component: ContractorBillingComponent,
        data: {
            title: 'Billing',
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'register',
        component: RegisterComponent,
        data: {
            title: 'Register'
        }
    },
    {
        path: 'jobs',
        component: JobsBoardComponent,
        data: {
            title: 'Jobs Board'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'billing/csv-import',
        component: CsvImportComponent,
        data: {
            title: 'Import CSV File For Billing'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'billing/complete-import',
        component: CsvImportComponent,
        data: {
            title: 'Import Completed Jobs'
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'settings',
        component: ContractorSettingsComponent,
        data: {
            title: 'Settings'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'tax-report',
        component: TaxReportComponent,
        data: {
            title: 'Tax Report'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'search',
        component: WorkOrderSearchPageComponent,
        data: {
            title: 'Job Search'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'search/:searchFor',
        component: WorkOrderSearchPageComponent,
        data: {
            title: 'Job Search'
        },
        canActivate: [AuthGuardService]

    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: {
            title: 'Reset Password',
        }
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        data: {
            title: 'Calendar'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'jobs/report',
        component: WorkOrderReportComponent,
        data: {
            title: 'Jobs Report'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'reports',
        component: ReportsComponent,
        data: {
            title: 'Statistics'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'documents',
        component: ContractorDocumentsComponent,
        data: {
            title: 'Documents',
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'chat/:id/:name',
        component: OnlineChatComponent,
        data: {
            title: 'Chat'
        }
    },
    {
        path: 'accept-maintenance-job/:id',
        component: AcceptMaintenanceJobComponent,
        data: {
            title: 'Accept Job'
        }
    },
    {
        path: 'maintenance-service-customer-view/:id',
        component: MaintServicesCustomerViewComponent,
        data: {
            title: 'Customer View',
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'maintenance-service-billing',
        component: MaintServicesBillingComponent,
        data: {
            title: 'Billing'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'maintenance-service-report-dashboard',
        component: MaintServicesReportDashboardComponent,
        data: {
            title: 'Reports'
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'maintenance-service-cancellations',
        component: MaintServicesCancellationsComponent,
        data: {
            title: 'Recent Cancellations'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'maintenance-service-calendar',
        component: MaintenanceServiceCalendarComponent,
        data: {
            title: 'Calendar'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'order-parts/:id',
        component: OrderPartsComponent,
        canActivate: [AuthGuardService]
    },
    {
        path: 'pending-offers',
        component: PendingOffersComponent,
        data: {
            title: 'Pending Offers'
        },
        canActivate: [AuthGuardService],
    },
    {
        path: 'become-a-service-pro',
        component: BecomeAContractorComponent,
        data: {
            title: 'Become A Service Pro'
        }
    },
    {
        path: 'appliance-autho/:id',
        component: ApplianceAuthoComponent,
        data: {
            title: 'Appliance Autho'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'incomplete-authos',
        component: IncompleteAuthosComponent,
        data: {
            title: 'Incomplete Authorizations',

        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'configure-areas',
        component: ConfigureAreasComponent,
        data: {
            title: 'Configure Areas'
        },
        canActivate: [AuthGuardService]
    },
    {
        path: 'tag-note/:id',
        component: TagNoteComponent,
        data: {
            title: 'Tag Note'
        },

    },
    {
        path: 'test/:id',
        component: AuthorizationV4WrapperComponent,
        data: {
            title: 'Test Autho'
        }
    },
    {
        path: 'authorizations/:id',
        component: AuthorizationV4WrapperComponent,
        data: {
            title: 'Job Authorizations'
        }
    }
];


@NgModule({ declarations: [
        AppComponent,
        FindUserNotificationsComponent,
        WorkOrderDetailComponent,
        ClaimSummaryComponent,
        // PaymentSelectionComponent,
        AutoHeightDirective,
        AutoMinHeightDirective,
        WizardStepIndicatorComponent,
        ViewCoverageDialogComponent,
        // EnterAuthoLineItemComponent,
        ContractorsHomeComponent,
        AccViewComponent,
        RecallViewComponent,
        CallTypesViewComponent,
        SurveyViewComponent,
        WorkOrderSearchComponent,
        WorkOrdersViewComponent,
        UpdatesNeededComponent,
        ContractorBillingComponent,
        ContractorSettingsComponent,
        EnterAuthorizationComponent,
        WorkOrderSearchPageComponent,
        ContractorStatementsComponent,
        ViewContractorStatementComponent,
        CsvImportComponent,
        JobsBoardComponent,
        ReportsComponent,
        CalendarComponent,
        WorkOrderReportComponent,
        DashboardContractorKpiChartComponent,
        BillIndividualWorkOrderComponent,
        ContractorDocumentsComponent,
        EntityDocumentsComponent,
        // BasicEntityEntryComponent,
        ChangePasswordComponent,
        AccWidgetComponent,
        // TakeQuestionnaireComponent,
        // FillOutFormComponent,
        PendingOffersComponent,
        ScheduleViewComponent,
        OnlineChatComponent,
        ContractorReportCardComponent,
        RegisterComponent,
        ResetPasswordComponent,
        AddNoteComponent,
        EditJobItemsStatusComponent,
        AuthorizeComponent,
        AddClosingItemComponent,
        AuthorizationPhotosComponent,
        AcceptMaintenanceJobComponent,
        MaintServicesHomeComponent,
        MaintServicesNavBarComponent,
        MaintServicesCustomerViewComponent,
        MaintServicesCancellationsComponent,
        MaintServicesCompleteAppointmentComponent,
        MaintServiceWorksheetComponent,
        MaintServicesBillingComponent,
        MaintServicesReportDashboardComponent,
        MaintServicesChangePreferredDayComponent,
        MaintenanceServiceCalendarComponent,
        OrderPartsComponent,
        OrderPartsAddIndividualComponent,
        BecomeAContractorComponent,
        ApplianceAuthoComponent,
        IncompleteAuthosComponent,
        MaintServiceReviewAddOnsComponent,
        MaintServicesOpenCustomerComponent,
        MaintServicesCancelAppointmentComponent,
        StartServiceRequestComponent,
        MaintServicesCreateInvoiceComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
    ],
    bootstrap: [AppComponent], imports: [RouterModule.forRoot(appRoutes, {}),
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        MatAutocompleteModule, MatCheckboxModule, MatDatepickerModule, MatInputModule, MatRadioModule, MatSelectModule, MatFormFieldModule, NgxMatSelectSearchModule,
        MatSliderModule, MatSlideToggleModule, MatMenuModule, MatSidenavModule, MatToolbarModule, MatListModule, MatGridListModule,
        MatCardModule, MatStepperModule, MatTabsModule, MatExpansionModule, MatButtonModule, MatButtonToggleModule, MatChipsModule,
        MatIconModule, MatProgressSpinnerModule, MatProgressBarModule, MatDialogModule, MatTooltipModule, MatSnackBarModule,
        MatTableModule, MatSortModule, MatPaginatorModule, MatNativeDateModule,
        DragDropModule,
        NgxMaskDirective,
        RecaptchaModule,
        ReactiveFormsModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory
        }),
        CircleWidgetComponent,
        CircleWidgetSmallModule,
        EmailComposeLinkComponent,
        DatePickerWrapperComponent,
        DateRangeDisplayAndSelectorComponent,
        CallTypeChipSelectionModule,
        CustomFormDisplayComponent,
        DocumentViewerComponent,
        QuestionWizardModule,
        RegionChipSelectionModule,
        SlaChipSelectionModule,
        SwapableImageModule,
        ApiFailureComponent,
        TradeChipSelectionModule,
        WorkOrderItemChipSelectionModule,
        WorkOrderStatusChipSelectionModule,
        HeightDirectivesModule,
        BasicEntityEntryModule,
        DisplayTotalModule,
        TakeQuestionnaireComponent,
        FillOutFormComponent,
        CheckCircleAnimatedComponent,
        ConfigureAreasComponent,
        TimePickerModule,
        WebSocketHandlerModule,
        RichTextAndSnippetModule,
        AppointmentSchedulerComponent,
        ReviewCircleAnimatedModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        AppointmentSelectionComponent,
        UpkeepPipesModule,
        CalendarDayViewerModule,
        EmptyInboxIconComponent,
        AppliancePartSearchModule,
        AddressEditorComponent,
        ContractorContactsComponent,
        GearsComponent,
        EntityCommunicationComponent,
        AddOnRequestArgsViewerComponent,
        SmsLinkButtonComponent,
        ServiceProTerritorySelectComponent,
        TagNoteComponent], providers: [
        {
            // Provider for APP_INITIALIZER
            provide: APP_INITIALIZER,
            useFactory: startupServiceFactory,
            deps: [StartupService],
            multi: true
        },
        { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
        { provide: 'authConfig', useValue: environment.authConfig },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
