import { Injectable } from '@angular/core';
import { ApiService } from '@cogent/client/api';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { QueueQuery } from '@cogent/client/shared/models/object-queue.model';
import { PromiseObserverService } from '@cogent/client/shared/services/promise-observer-service'
import { PurchaseOrderSummaryClient } from '@cogent/client/shared/models/purchasing/purchase-order-summary-client.model';
import { InventoryPart, Item, PartToOrder, PartToOrderSummary, PartToOrderSummaryGroup, PurchaseOrder, PurchaseOrderItem, PurchaseOrderItemSummary, SpecAndAppliancePartDetail, Tag } from '@upkeeplabs/models/cogent';

@Injectable({ providedIn: 'root' })
export class PurchasingApiService {

    constructor(private api: ApiService) { }

    undeletePartToOrder(id: string) {
        return this.api.deleteNode(`PartToOrder/${id}`);
    }
    deletePartToOrder(id: string) {
        return this.api.deleteNode(`PartToOrder/${id}`);
    }
    getPurchaseOrderItem(originalPurchaseOrderItemId: string) {
        return this.api.getSingleDotNet(`PurchaseOrderItem/${originalPurchaseOrderItemId}`, null, () => new PurchaseOrderItem());
    }
    searchForItem(searchFor: string): Promise<Item[]> {
        return this.api.getArrayDotNet('item/find', { searchFor }, () => new Item());
    }

    getReliablePartDetail(partNumber: string, manufacturer: string) {
        return this.api.getSingleDotNet(`ApplianceParts/PartDetail/${partNumber}/${manufacturer}`);
    }

    getPartsToOrderForLine(workOrderLineId: string): Promise<PartToOrder[]> {
        return this.api.getArrayNode(`PartToOrder`, { workOrderLineId_eq: workOrderLineId }, () => new PartToOrder());
    }

    syncPartsToOrder(workOrderLineId: string, parts: PartToOrder[]) {
        return this.api.postNode(`purchasing/sync-parts-to-order/${workOrderLineId}`, parts);
    }

    placePartsOrders(groups: PartToOrderSummaryGroup[]) {
        return this.api.postNode(`purchasing/place-parts-orders`, groups);
    }

    getReliableModelDetail(modelNumber: string) {
        return this.api.getSingleDotNet(`ApplianceParts/ModelDetail`, { modelNumber });
    }

    getInventoryPartByPartNumber(partNumber: string) {
        return this.api.getSingleNode(`InventoryPart`, {partNumber_eq: partNumber},()=> new InventoryPart());
    }

    async getUnorderedPartsToOrder() {
        const parts = await this.api.getArrayNode(`PartToOrderSummary`, { dateOrdered_eq: '{{null}}' }, () => new PartToOrderSummary());
        for (const part of parts) {
            part.orderDetails = (await this.getAppliancePartDetails(part.partNumber, part.propertyPostalCode)).vendorDetails;
        }

        return parts;
    }

    getModelAssemblies(modelNumber: string, manufacturer: string, variation: string = null) {
        return this.api.getSingleNode('purchasing/model-detail', { modelNumber, manufacturer, variation });
    }

    // getModelAssembliesOrVariations(modelNumber: string, manufacturer: string) {
    //     return this.api.getArray2('purchasing/model-detail', { modelNumber, manufacturer });
    // }

    getModelDetailExplodedView(mfgCode: string, explodedViewId: string, assemblyId: string, modelId: string, variation) {
        return this.api.getSingleNode(`purchasing/model-detail-exploded-view`, { mfgCode, explodedViewId, assemblyId, modelId, variation });
    }

    getAppliancePartDetails(partNumber: string, postalCode: string) {
        return this.api.getSingleNode(`purchasing/part-detail`, { partNumber, postalCode }, () => new SpecAndAppliancePartDetail());
    }

    getSortedReliableWarehouses(postalCode: string) {
        return this.api.getSingleDotNet(`ApplianceParts/reliable-parts-warehouses/${postalCode}`);
    }

    getDiagramDetail(items: any[]) {
        return this.api.postSingleDotNet(`ApplianceParts/get-diagram-details`, items);
    }

    searchForPurchaseOrderItemByPartNumber(searchFor: string): Promise<PurchaseOrderItemSummary[]> {
        return this.api.getArrayDotNet(`PurchaseOrderItemSummary`, { partNumber_like: searchFor, orderby: 'PurchaseOrderNumber DESC' }, () => new PurchaseOrderItemSummary());
    }

    searchForItemByAction(searchFor: string, action: string): Promise<Item[]> {
        return this.api.getArrayDotNet('item/find/' + action, { searchFor }, () => new Item());
    }

    getTags(purchaseOrderId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`purchasing/PurchaseOrder/${purchaseOrderId}/tags`);
    }

    searchForItemByActionPolicyAndWorkOrderItem(searchFor: string, action: string, policyId: string, workOrderItemId: string): Promise<Item[]> {
        return this.api.getArrayDotNet(`item/find/${action}/${policyId}/${workOrderItemId}`, { searchFor }, () => new Item());
    }

    getPurchaseOrderSummaryById(id: string): Promise<PurchaseOrderSummaryClient> {
        return this.api.getSingleDotNet(`PurchaseOrderSummary/${id}`, null, () => new PurchaseOrderSummaryClient());
    }

    getPurchaseOrderItemsByVendor(vendorId: string) {
        return this.api.getArrayNode(`PurchaseOrderItemSummary`, { vendorId_eq: vendorId, orderby: 'PurchaseOrderNumber DESC' }, () => new PurchaseOrderItemSummary());
        //return this.api.getArray2(`purchasing/get-items-by-vendor`, { vendorId });
    }

    replacePurchaseOrderTags(purchaseOrderId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`purchasing/PurchaseOrder/${purchaseOrderId}/replace-tags`, tags);
    }

    updatePurchaseOrderWorkOrderId(purchaseOrderId: string, workOrderId: string) {
        return this.api.patchSingleDotNet(`PurchaseOrder/${purchaseOrderId}`, { workOrderId });
    }

    async savePartsToOrder(parts: PartToOrder[]) {
        const promises = [];

        for (const part of parts) {
            if (!part.cost) {
                part.cost = 0;
            }
            if (!part.quantity) {
                part.quantity = 0;
            }
            promises.push(this.api.postSingleNode(`PartToOrder`, part));
        }
        await Promise.all(promises);
    }


    addPurchaseOrderTags(taskId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`purchasing/PurchaseOrder/${taskId}/add-tags`, tags);
    }

    removePurchaseOrderTags(taskId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`purchasing/PurchaseOrder/${taskId}/remove-tags`, tags);
    }

    applianceSearch(searchFor: string,) {
        // return this.api.getSingle(`ApplianceParts/PartModelSearch`,{searchFor});
        return this.api.getSingleNode('purchasing/parts-search-transformed', { searchFor });
    }


    saveItem(item: Item): Promise<string> {
        return this.api.postIdDotNet('item', item);
    }

    deleteItem(itemId: string): Promise<any> {
        return this.api.deleteDotNet('item/' + itemId);
    }

    unDeleteItem(itemId: string): Promise<any> {
        return this.api.unDeleteDotNet(`item/${itemId}`);
    }

    getItemByNumber(itemNumber: string): Promise<Item[]> {
        return this.api.getArrayDotNet('item', { number_eq: itemNumber }, () => new Item());
    }

    getItems(sort: string, page: number, recordCount: number, direction: string, filter: string): Promise<Item[]> {
        const params: any = { orderby: sort + ' ' + direction, skip: page * recordCount, take: recordCount };

        if (filter) {
            params.where = `Number.Contains("${filter}") OR Description.Contains("${filter}")`;
        }
        return this.api.getArrayDotNet(`Item`, params, () => new Item());
    }


    getItemCount(filter: string) {
        const params: any = {};
        if (filter) {
            params.where = `Number.Contains("${filter}") OR Description.Contains("${filter}")`;
        }
        return this.api.getSingleDotNet('Item/count', params);
    }


    getWorkOrderPurchaseOrders(workOrderId: string): Promise<PurchaseOrderSummaryClient[]> {
        return this.api.getArrayDotNet('PurchaseOrderSummary', {
            workOrderId_eq: workOrderId,
            orderby: 'CreatedDate'
        });
    }

    getPurchaseOrder(id: string): Promise<PurchaseOrder> {
        return this.api.getSingleDotNet('PurchaseOrder/' + id, null, () => new PurchaseOrder());
    }

    // getPurchaseOrderItems(id: string) {
    //     return this.api.getArray2('PurchaseOrderLine', { purchaseOrderId_eq: id }, () => new PurchaseOrderItem());
    // }

    getPurchaseOrderSummary(id: string): Promise<PurchaseOrderSummaryClient> {
        return this.api.getSingleDotNet('PurchaseOrderSummary/' + id, null, () => new PurchaseOrderSummaryClient());
    }

    getPurchaseOrderItems(purchaseOrderId: string): Promise<PurchaseOrderItem[]> {
        return this.api.getArrayDotNet('PurchaseOrderItem/',
            { purchaseOrderId_eq: purchaseOrderId, orderby: 'CreatedDate' }, () => new PurchaseOrderItem());
    }

    getPurchaseOrderItemSummaries(purchaseOrderId: string): Promise<PurchaseOrderItemSummary[]> {
        return this.api.getArrayDotNet('PurchaseOrderItemSummary/',
            { purchaseOrderId_eq: purchaseOrderId }, () => new PurchaseOrderItemSummary());
    }

    getPurchaseOrderHtml(purchaseOrderId: string): Promise<string> {
        return this.api.getTextDotNet('purchasing/PurchaseOrder/' + purchaseOrderId + '/html');
    }

    getPurchaseOrderItemsForPolicyNumberAndItem(policyNumber: number, itemId: string, startDate: Date): Promise<PurchaseOrderItemSummary[]> {
        return this.api.getArrayDotNet(`PurchaseOrderItemSummary`, { policyNumber_eq: policyNumber, WorkOrderCreatedDate_gte: startDate, workOrderItemId_eq: itemId, orderby: 'WorkOrderCreatedDate' },
            () => new PurchaseOrderItemSummary());
    }

    deletePurchaseOrder(purchaseOrderId: string) {
        return this.api.deleteDotNet('PurchaseOrder/' + purchaseOrderId);
    }

    undoPurchaseOrderDelete(purchaseOrderId: string): Promise<any> {
        return this.api.unDeleteDotNet('PurchaseOrder/' + purchaseOrderId);
    }

    updateInventoryPart(inventoryPart: InventoryPart) {
        return this.api.postNode(`InventoryPart`, inventoryPart);
    }

    savePurchaseOrder(purchaseOrder: PurchaseOrder, items: PurchaseOrderItem[], itemsToDelete: PurchaseOrderItem[]): Promise<any> {

        items.forEach(item => item.purchaseOrderId = purchaseOrder.id);
        return new Promise((resolve) => {
            const observer = new PromiseObserverService(() => {
                resolve(null);
            });

            this.api.postIdDotNet('PurchaseOrder', purchaseOrder).then(() => {
                items.forEach(item => {
                    if (item.isNewItem && item.saveNewItem) {
                        const newItem = new Item();
                        newItem.id = UtilitiesService.newid();
                        item.itemId = newItem.id;
                        newItem.defaultAmount = item.unitPrice;
                        newItem.number = item.number;
                        newItem.description = item.description;
                        observer.addPromise(this.saveItem(newItem));
                    }
                    observer.addPromise(this.api.postIdDotNet('PurchaseOrderItem', item));

                });

                if (itemsToDelete) {
                    itemsToDelete.forEach(item => observer.addPromise(
                        this.api.deleteDotNet('PurchaseOrderItem/' + item.id)));
                }
            });
        });
    }

    savePurchaseOrderItem(purchaseOrderItem: PurchaseOrderItem) {
        return this.api.postSingleDotNet(`PurchaseOrderItem`, purchaseOrderItem);
    }



    getQueue(queueQuery: QueueQuery, fieldsToInclude: string[], start: number, take = 50): Promise<any> {

        let properties = '';
        if (fieldsToInclude.indexOf('id') === -1) {
            fieldsToInclude.push('id');
        }


        // Get the distinct fields values
        fieldsToInclude = Array.from(new Set(fieldsToInclude));
        if (fieldsToInclude) {
            fieldsToInclude.forEach(field => {
                if (properties !== '') {
                    properties += ',';
                }
                properties += field;
            });
        }

        const params = this.buildQueueQueryParams(queueQuery, properties, start, take);

        return this.api.getArrayDotNet(
            'PurchaseOrderSummary/queue', params, () => new PurchaseOrderSummaryClient());
    }

    getQueueItemCount(queueQuery: QueueQuery) {

        const params = this.buildQueueQueryParams(queueQuery, '', 0, 1000);

        delete params.take;
        delete params.skip;
        delete params.properties;
        delete params.orderby;

        return this.api.getSingleDotNet(`PurchaseOrderSummary/queue/count`, params);
    }

    changePurchaseOrderStatus(purchaseOrderId: string, statusId: string) {
        return this.api.patchSingleDotNet(`purchasing/purchaseorder/${purchaseOrderId}/change-status/${statusId}`, null)
    }

    private buildQueueQueryParams(queueQuery: QueueQuery, properties: string, start: number, take: number) {

        const params = {
            properties
        } as any;
        const createdDates = UtilitiesService.getDatesFromDateRange(queueQuery.createdDateRange);
        const approvalDates = UtilitiesService.getDatesFromDateRange(queueQuery.dateRange2);
        const sentDates = UtilitiesService.getDatesFromDateRange(queueQuery.dateRange3);
        const receivedDates = UtilitiesService.getDatesFromDateRange(queueQuery.dateRange4);

        if (createdDates[0]) {
            params.createdDate_gte = createdDates[0];
        }
        if (createdDates[1]) {
            params.createdDate_lte = createdDates[1];
        }
        if (!queueQuery.dateRange2IsNotNull && !queueQuery.dateRange2IsNull) {
            if (approvalDates[0]) {
                params.approvedDate_gte = approvalDates[0];
            }
            if (approvalDates[1]) {
                params.approvedDate_lte = approvalDates[1];
            }
        }

        if (!queueQuery.dateRange3IsNotNull && !queueQuery.dateRange3IsNull) {
            if (sentDates[0]) {
                params.sentDate_gte = sentDates[0];
            }
            if (sentDates[1]) {
                params.sentDate_lte = sentDates[1];
            }
        }
        if (!queueQuery.dateRange4IsNotNull && !queueQuery.dateRange4IsNull) {
            if (receivedDates[0]) {
                params.receivedDate_gte = receivedDates[0];
            }
            if (receivedDates[1]) {
                params.receivedDate_lte = receivedDates[1];
            }
        }

        if (queueQuery.dateRange2IsNotNull) {
            params.approvedDate_neq = '{{null}}';
        }
        if (queueQuery.dateRange2IsNull) {
            params.approvedDate_eq = '{{null}}';
        }
        if (queueQuery.dateRange3IsNotNull) {
            params.sentDate_neq = '{{null}}';
        }
        if (queueQuery.dateRange3IsNull) {
            params.sentDate_eq = '{{null}}';
        }
        if (queueQuery.dateRange4IsNotNull) {
            params.receivedDate_neq = '{{null}}';
        }
        if (queueQuery.dateRange4IsNull) {
            params.receivedDate_eq = '{{null}}';
        }

        if (queueQuery.selectedTrades && queueQuery.selectedTrades.length > 0) {
            let tradesString = '';
            queueQuery.selectedTrades.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_contains = tradesString;
        }
        if (queueQuery.selectedEmployees && queueQuery.selectedEmployees.length > 0) {
            let qString = '';
            queueQuery.selectedEmployees.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.OwnerId_contains = qString;
        }
        if (queueQuery.selectedRegions && queueQuery.selectedRegions.length > 0) {
            let qString = '';
            queueQuery.selectedRegions.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.AreaId_contains = qString;
        }

        if (queueQuery.selectedItems && queueQuery.selectedItems.length > 0) {
            let qString = '';
            queueQuery.selectedItems.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderItemId_contains = qString;
        }

        // Excluded

        if (queueQuery.selectedTradesExcluded && queueQuery.selectedTradesExcluded.length > 0) {
            let tradesString = '';
            queueQuery.selectedTradesExcluded.forEach(trade => {
                if (tradesString) {
                    tradesString += ',';
                }
                tradesString += trade.id;
            });

            params.TradeId_ncontains = tradesString;
        }
        if (queueQuery.selectedEmployeesExcluded && queueQuery.selectedEmployeesExcluded.length > 0) {
            let qString = '';
            queueQuery.selectedEmployeesExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.OwnerId_ncontains = qString;
        }

        if (queueQuery.selectedRegionsExcluded && queueQuery.selectedRegionsExcluded.length > 0) {
            let qString = '';
            queueQuery.selectedRegionsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.AreaId_ncontains = qString;
        }

        if (queueQuery.selectedItemsExcluded && queueQuery.selectedItemsExcluded.length > 0) {
            let qString = '';
            queueQuery.selectedItemsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });

            params.WorkOrderItemId_ncontains = qString;
        }
        if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
            let qString = '';
            queueQuery.selectedTags.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });
            params.tags = qString;
        }

        if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
            let qString = '';
            queueQuery.selectedTagsExcluded.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item.id;
            });
            params.excludedTags = qString;
        }

        params.skip = start;
        params.take = take;
        params.orderby = '';
        if (queueQuery.groupByField) {
            params.orderby = `${queueQuery.groupByField},`;
        }

        if (queueQuery.sortByField) {
            params.orderby += queueQuery.sortByField + (queueQuery.sortDescending ? ' descending' : '') + ', id';
        } else {
            params.orderby += 'createdDate,id';
        }

        return params;
    }
}
