import { keyColumn, column, dataSource } from "@upkeeplabs/linq";
import { Address } from "./address.model";
import { NumberType, PhoneNumber } from "./phone-number.model";
import { EntityRegionPostalCode } from "./entity-region-postal-code.model";
import { ZipCode } from "./zip-code.model";
import { Login } from "./login.model";

@dataSource()
export class Entity {
    @keyColumn() id: string;
    @column() name: string;
    @column() type: string;
    @column() parentId?: string;
    @column() addressId?: string;
    @column() deletedDate?: Date;
    @column() firstName: string;
    @column() lastName: string;
    @column() email: string;
    @column() lastModifiedDate?: Date;
    @column() inactiveDate: Date;
    @column() website: string;
    @column() settings: string;
    @column() yelpId: string;
    @column() yelpRating?: number;
    @column() lastYelpUpdate?: Date;
    @column() contactViaEmail: boolean;
    @column() contactViaSMSText: boolean;
    @column() contactViaDispatch?: boolean;
    @column() dispatchActivatedDate?: Date;
    @column() dispatchInitializedDate?: Date;
    @column() createdDate?: Date;
    @column() createdById?: string;
    @column() lastModifiedById?: string;
    @column() timeZoneId: string;
    @column() stripeId: string;
    @column() assignedEmployeeId?: string;
    @column() quickBooksId: string;
    @column() collectForCostOfClaimsOnCancellations: boolean;
    @column() roleId: string;
    @column() preferredContactMethod: string;
    @column() preferredLanguageId: string;
    @column() paperless: boolean;
    @column() ten99: boolean;
    @column() inactive: boolean;
    @column() loginId: string;
    @column() dBAName: string;
    @column() legalName: string;
    @column() licenseNumber: string;
    @column() hotelReimbursementRate: number;
    @column() paperlessPromptDate: Date;
    @column() salesRegionOverrideId: string;
    @column() payablesPaymentMethod: string;
    @column() authorizationLimitOverride?: number;
    @column() extension: string;
    @column() forwardCallsToMobile?: boolean;
    @column() accountUserName: string;
    @column() accountPassword: string;
    @column() alternateEmail: string;
    @column() nameOnCheck: string;
    @column() allowTimeSlotSelection: boolean;
    @column() ein: string;
    @column() preferredShipperId: string;
    @column() teamId: string;
    @column() isMaintenanceServiceContractor: boolean;
    @column() rate: number;
    @column() agentEmailIndex?: number;
    @column() apiUserName: string;
    @column() preferred?: boolean;
    @column() tierId?: string;
    @column() apiPassword: string;
    @column() excludeFromJumpBall?: boolean;
    @column() apiBaseUrl: string;
    @column() useLastInitial: boolean;
    @column() companyProvidesParts: boolean;
    @column() optIn: boolean;
    @column() birthday: Date;
    @column() partsOrderingType: string;
    @column() lastStripeCardSync?: Date;
    @column() optedOutTwilio?: boolean;
    
    address: Address;
    initialPassword = '';
    // homeNumber: PhoneNumber;
    // mobileNumber: PhoneNumber;
    login: Login;
    policyId: string;

    get title(): string {
        return '';
    }

    get isIndividual(): boolean {
        return this.type === 'Employee' || this.type === 'Customer' || this.type === 'Agent' || this.type === 'ClosingOfficer' || this.type === 'Technician' || this.type === 'Tenant';
    }

    get isProfessionalIndividual() {
        return this.type === 'Agent' || this.type === 'Technician' || this.type === 'ClosingOfficer';
    }

    selected: boolean;








    expanded?: boolean;

    // Client Side Only
    assignedEmployee: Entity[];
    zipCodes: string[];
    availableZipCodes: ZipCode[];
    parent: Entity;
    private SETTINGS_MODEL: any;
    private versionCount = 0;
    phoneNumbers: PhoneNumber[] = [];
    assignedPostalCodes: EntityRegionPostalCode[];
    defaultLaborRate: number;
    allowOnlineAutho: boolean;
    createdByName: string;

    private getNumberByType(type: NumberType): PhoneNumber {
        if (!this.phoneNumbers) {
            return null;
        }

        let returnValue: PhoneNumber = null;

        this.phoneNumbers.sort((a, b) => a.createdDate > b.createdDate ? 1 : -1).forEach(phoneNumber => {
            if (phoneNumber.numberTypeEnum === type) {
                returnValue = phoneNumber;
            }
        });

        return returnValue;
    }

    get mobileNumber(): PhoneNumber {
        let mobileNumber = this.getNumberByType(NumberType.Mobile);
        if (!mobileNumber) {
            mobileNumber = new PhoneNumber('', 'Mobile');
            this.phoneNumbers.push(mobileNumber);
        }
        return mobileNumber;
    }

    get homeNumber(): PhoneNumber {
        let homeNumber = this.getNumberByType(NumberType.Home);
        if (!homeNumber) {
            homeNumber = new PhoneNumber('', 'Home');
            this.phoneNumbers.push(homeNumber);
        }
        return homeNumber;
    }
    set homeNumber(value: PhoneNumber) {
        this.phoneNumbers.push(value);
    }

    get officeNumber(): PhoneNumber {
        let officeNumber = this.getNumberByType(NumberType.Work);
        if (!officeNumber) {
            officeNumber = new PhoneNumber('', 'Work');
            this.phoneNumbers.push(officeNumber);
        }
        return officeNumber;
    }

    get mobileNumberNumber(): string {
        return this.mobileNumber.number;
    }

    get homeNumberNumber(): string {
        return this.homeNumber.number;
    }

    get officeNumberNumber(): string {
        return this.officeNumber.number;
    }

    get faxNumberNumber(): string {
        return this.faxNumber.number;
    }

    get faxNumber(): PhoneNumber {
        let faxNumber = this.getNumberByType(NumberType.Fax);
        if (!faxNumber) {
            faxNumber = new PhoneNumber('', 'Fax');
            this.phoneNumbers.push(faxNumber);
        }
        return faxNumber;
    }

    get hasHomeNumber(): boolean {
        const homeNumber = this.getNumberByType(NumberType.Home);
        return homeNumber && homeNumber.number ? true : false;
    }

    children?: Entity[];

    get hasMobileNumber(): boolean {
        const mobileNumber = this.getNumberByType(NumberType.Mobile);
        return mobileNumber && mobileNumber.number ? true : false;
    }

    get hasWorkNumber(): boolean {
        const workNumber = this.getNumberByType(NumberType.Work);
        return workNumber && workNumber.number ? true : false;
    }

    get settingsModel(): any {
        if (this.settings && !this.SETTINGS_MODEL) {
            try {
                this.SETTINGS_MODEL = JSON.parse(this.settings);
            } catch (e) { this.SETTINGS_MODEL = {}; }
        }
        return this.SETTINGS_MODEL;
    }

    set settingsModel(value: any) {
        this.SETTINGS_MODEL = value;
        this.settings = JSON.stringify(value);
    }

    resetSettingsModel() {
        this.SETTINGS_MODEL = null;
    }


    public togglePinnedQuickLaunchMenuItem(menuItem: any): boolean {
        if (!this.settingsModel) {
            this.settingsModel = {};
        }

        if (!this.settingsModel.quickLaunchMenuItems) {
            this.settingsModel.quickLaunchMenuItems = [];
        }

        let results = false;
        const foundMenuItem = this.settingsModel.quickLaunchMenuItems.filter(i => i.url === menuItem.url)[0];
        if (foundMenuItem) {
            this.settingsModel.quickLaunchMenuItems.splice(this.settingsModel.quickLaunchMenuItems.indexOf(foundMenuItem), 1);
        } else {
            this.settingsModel.quickLaunchMenuItems.push(menuItem);
            results = true;
        }

        this.settingsModel = this.settingsModel;
        this.settings = JSON.stringify(this.settingsModel);

        return results;
    }

    isMenuItemPinned(url: string) {
        return this.settingsModel && this.settingsModel.quickLaunchMenuItems
            && this.settingsModel.quickLaunchMenuItems.length
            && this.settingsModel.quickLaunchMenuItems.filter(i => i.url === url).length > 0;
    }

    get displayName(): string {
        if (this.type === 'Employee') {
            if (this.useLastInitial) {
                return this.firstName + ' ' + this.lastName[0];
            }
            return this.firstName + ' ' + this.lastName;
        }
        return this.name;
    }



}

@dataSource()
export class EntityZipCode {
    @keyColumn() id: string;
    @column() zipCode: string;
}

@dataSource()
export class QuickbooksEntity {
    @keyColumn() id: string;
    @column() accountingAreaId: string;
    @column() name: string;
    @column() email: string;
    @column() entityQuickboksVendorId: string;
    @column() quickbooksVendorId: string;
    @column() displayName: string;
    @column() taxIdentifier: string;
    @column() vendor1099: boolean;
    @column() address1: string;
    @column() address2: string;
    @column() city: string;
    @column() state: string;
    @column() postalCode: string;
    @column() printOnCheckName: string;
    @column() primaryPhone: string;
}

@dataSource()
export class EntityQuickbooksVendor {
    @keyColumn() id: string;
    @column() entityId: string;
    @column() accountingAreaId: string;
    @column() quickbooksVendorId: string;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
}