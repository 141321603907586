import { keyColumn, column, dataSource, readOnlyColumn } from "@upkeeplabs/linq";

@dataSource()
export class InventoryPart {
    @keyColumn() id: string;
    @column() partNumber: string;
    @column() purchaseOrderId: string;
    @column() createdDate: Date;
    @column() lastModifiedDate: Date;
    @column() deletedDate: Date;
    @column() createdById: string;
    @column() lastModifiedById: string;
    @column() location: string;
    @column() description: string;
    @column() cost: number;
    @column() quantity: number;
    @column() quantityUsed: number;
    @readOnlyColumn() quantityRemaining: number;
    @column() vendorNumber: string;
    @column() originalPurchaseOrderId: string;


    // get quantityRemaining() {
    //     return this.quantity - (this.quantityUsed ?? 0);
    // }
}