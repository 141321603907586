import { AuthService } from '@cogent/client/auth';
import { Injectable } from "@angular/core";
import { ApiService } from '@cogent/client/api';
import { PolicySummary } from '@cogent/client/shared/models/policies/policy-summary.model';
import { ContractorSummary } from "@cogent/shared/models/contractor-relations/contractor-summary.model";
import { CompanyRegion, CompanyStatesAndRegions } from '@cogent/shared/models/common/company-states-and-regions.model';
import { Address, ContractorJobExclusionCondition, ContractorStateGrouping, ContractorTrade, ContractorTradeRegion, Entity, EntityACH, EntityConfiguration, EntityRegionPostalCode, EntityReportDefinition, EntitySummary, EntityZipCode, Language, Login, Permission, PhoneNumber, PhoneNumberLabel, Role, SmsSummary, Tag, TwilioNumber, ZipCode } from '@upkeeplabs/models/cogent';
import { UtilitiesService } from '@cogent/client/shared/logic/utilities';
import { SMSConversationSummary } from '@cogent/shared/models/common/sms-conversation-summary.model'
import { ContractorDocumentType } from '@upkeeplabs/models/cogent';
import { ContractorDocument } from '@upkeeplabs/models/cogent';
import { SlyBroadcastMessage } from '@cogent/shared/models/user/sly-broadcast.model';
import { MentionSummary } from '@cogent/shared/models/user/mention-summary.model';
import { MissionService } from '@cogent/client/shared/services/mission-service';
import { ContractorBrand, Task, TaskMessage, UserNotification, UserTextSnippet } from '@upkeeplabs/models/cogent';
import { County, EntityKeywordSearch, PolicySummaryV2, PromotionCode, State } from "@upkeeplabs/models/cogent";
import { NoteClient } from '@cogent/client/shared/models/common/note-client.model';

@Injectable({ providedIn: 'root' })
export class EntityApiService {
    deleteLogin() {
        return this.api.deleteDotNet(`account`);
    }
    constructor(
        private api: ApiService,
        private auth: AuthService,
        private missionService: MissionService,
    ) { }

    private cachedUser: Entity;
    private cachedUserToken: string;
    private gettingLoggedInUser = false;

    cachedRoutes: string[];
    cachedRoutesUserToken: string;

    saveEntity2(entity) {
        return this.api.postSingleNode(`Entity`, entity);
    }

    createActiveDirectoryUser(entity) {
        return this.api.postSingleNode(`Entity/create-active-directory-user`, entity);
    }

    getEmployeeBirthdays() {
        const startDate = UtilitiesService.dayBegin(new Date());
        const endDate = UtilitiesService.dayEnd(new Date());
        return this.api.getArrayNode(`entity`, { type_eq: 'Employee', birthday_gte: startDate, birthday_lte: endDate }, () => new Entity());
    }

    assignEmployeeToAmazonConnect(id: string) {
        return this.api.patchNode(`entity/assign-employee-to-amazon-connnect/${id}`, null);
    }

    getLoginByUserName(userName: string) {
        return this.api.getSingleNode(`entity/get-login-by-user-name/${userName}`,null,()=> new Login());
    }

    addEmployeeToSSOApp(id: string) {
        return this.api.patchNode(`entity/add-employee-to-sso-app/${id}`, null);
    }

    addEmployeeToDistributionGroups(id: string) {
        return this.api.patchNode(`entity/assign-employee-to-elevate/${id}`, null);
    }

    assignOffice365License(id: string) {
        return this.api.patchNode(`entity/assign-office-365-license/${id}`, null);
    }

    getEntityZipCodes(id: string) {
        return this.api.getArrayNode(`EntityZipCode`, { id_eq: id }, () => new EntityZipCode());
    }

    getStripeSetupIntent(entityId: string, type: 'us_bank_account' | 'card') {

        return this.api.getSingleNode(`stripe/${entityId}/setup-intent/${type}`);
    }

    getThumbnailUri(entityId): string {
        if (!entityId) {
            return null;
        }
        return ApiService.endPointDotNet + "entity/thumbnail/" + entityId;
    }

    syncWithQuickbooks(id: string) {
        return this.api.patchSingleDotNet(`Entity/${id}/sync-with-quickbooks`, null);
    }

    getCompanyInfo2() {
        return this.api.getSingleNode('entity/company-info');
    }

    getCompanyInfo2Test() {
        return this.api.postSingleNode('entity/company-info-test');
    }

    getPromotionCodeForEntity(entityId: string) {
        return this.api.getArrayNode(`PromotionCode`, { entityId_eq: entityId }, () => new PromotionCode());
    }

    getImageUrl(entityId): string {
        if (!entityId) {
            return 'https://upkeeplabs.blob.core.windows.net/doc-public/entity-pictures/anon-person.png';
        }
        return ApiService.endPointDotNet + 'entity/photo/' + entityId;
    }
    getWhiteImageUrl(entityId): string {
        if (!entityId) {
            return null;
        }
        return ApiService.endPointDotNet + "entity/photo/white/" + entityId;
    }

    getLoginById(id: string) {
        return this.api.getSingleDotNet(`login/${id}`);
    }

    getTagCreatedCountForRange(endpoint: string, tagIds: string[], startDate: Date, endDate: Date, ids: string[]) {
        const idString = ids.join(',');
        const tagIdsString = tagIds.join(',');
        return this.api.getSingleDotNet(`${endpoint}/count`, { tagId_contains: tagIdsString, createdDate_gte: startDate, createdDate_lte: endDate, createdById_contains: idString });
    }

    getAgentAverageWorkOrderRatings() {
        return this.api.getSingleDotNet(`Entity/customer-ratings-for-entity`);
    }

    async applyLabelToConversationSummary(summary: SMSConversationSummary) {
        const loggedInUser = await this.getLoggedInUser(false);
        const entityPhoneNumber = await this.api.getSingleDotNet(`EntityPhoneNumber`, { where: `StrippedHomeNumber = "${summary.correspondent}" OR StrippedMobileNumber = "${summary.correspondent}"`, take: 1, orderby: 'CreatedDate desc' });

        if (entityPhoneNumber) {
            const entity = await this.api.getSingleDotNet(`Entity/${entityPhoneNumber.id}`);
            if (entity) {

                await this.api.postSingleDotNet(`PhoneNumberLabel`, {
                    entityId: loggedInUser.id,
                    number: summary.correspondent,
                    name: entity.name,

                });
                summary.numberName = entity.name;
                return entity.name;
            }
        }

        await this.api.postSingleDotNet(`PhoneNumberLabel`, {
            entityId: loggedInUser.id,
            number: summary.correspondent,
            name: summary.correspondent
        });
        summary.numberName = summary.correspondent;

        return summary.correspondent;
    }

    async getMentionSummaries(take: number = 20): Promise<MentionSummary[]> {
        const loggedInUser = await this.getLoggedInUser();

        return this.api.getArrayDotNet(`MentionSummary`, {
            take,
            mentionedId_eq: loggedInUser.id,
            orderby: 'CreatedDate desc',
        }, () => new MentionSummary())
    }

    updateMentionAcknownleged(mentionId: string, acknowledged: boolean) {
        return this.api.patchSingleDotNet(`Mention/${mentionId}`, { acknowledged });
    }

    getGroupedFollowUpStats(startDate: Date, endDate: Date) {
        return this.api.getArrayDotNet(`FollowUpStat/get-grouped-stats`, { startDate, endDate });
    }

    async getCreatedMentionSummaries(take: number = 20): Promise<MentionSummary[]> {
        const loggedInUser = await this.getLoggedInUser();

        return this.api.getArrayDotNet(`MentionSummary`, {
            take,
            CreatedById_eq: loggedInUser.id,
            orderby: 'CreatedDate desc',
        }, () => new MentionSummary())
    }

    async getAgentPortalPolicies(): Promise<PolicySummary[]> {
        const loggedInUser = await this.getLoggedInUser();
        return this.api.getArrayDotNet(`PolicySummary`, {
            where: `(AgentId = "${loggedInUser.id}" OR SellerAgentId = "${loggedInUser.id}") AND CoverageType != "Renewal" AND (Status = "Active" OR Status = "Pending" OR Status = "Suspended")`,
            select: 'id,policyNumber,propertyAddress,planName,status,holder,effectivedate,expirationdate'
        })
    }

    async getMentionSummariesByDate(take: number = 20, startDate: Date, endDate: Date): Promise<MentionSummary[]> {
        const loggedInUser = await this.getLoggedInUser();

        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);

        return this.api.getArrayDotNet(`MentionSummary`, {
            take,
            mentionedId_eq: loggedInUser.id,
            orderby: 'CreatedDate desc',
            createdDate_gte: startDate,
            createdDate_lte: endDate,
        }, () => new MentionSummary())
    }

    async getCreatedMentionSummariesByDate(take: number = 20, startDate: Date, endDate: Date): Promise<MentionSummary[]> {
        const loggedInUser = await this.getLoggedInUser();

        startDate = UtilitiesService.dayBegin(startDate);
        endDate = UtilitiesService.dayEnd(endDate);

        return this.api.getArrayDotNet(`MentionSummary`, {
            take,
            CreatedById_eq: loggedInUser.id,
            orderby: 'CreatedDate desc',
            createdDate_gte: startDate,
            createdDate_lte: endDate,
        }, () => new MentionSummary())
    }

    async getEntityTree(id: string) {
        const results = await this.api.getSingleDotNet(`Entity/${id}/get-entity-tree`);
        this.setParent(results);
        return results;
    }

    private setParent(parent) {
        if (parent.children) {
            for (const child of parent.children) {
                child.parent = parent;
                this.setParent(child);
            }
        }
    }

    getEntityRegionPostalCodeByEntityIdAndPostalCode(entityId: string, postalCode: string) {
        return this.api.getSingleDotNet(`EntityRegionPostalCode`, { entityId_eq: entityId, postalCode_eq: postalCode });
    }

    getEntityZipCode(entityId: string, zipCode: string) {
        return this.api.getSingleNode("EntityZipCode", { id_eq: entityId, zipCode_eq: zipCode });
    }

    getZipCodesForEntity(entityId: string) {
        return this.api.getArrayNode(`entity/zip-code-for-entity/${entityId}`, null, () => new ZipCode());
    }

    updateContractorTradeRegion(contractorTradeId: string, postalCodes: ZipCode[]) {
        return this.api.postNode(`entity/update-contractor-trade-region`, { contractorTradeId, postalCodes });
    }

    goPaperless(entityId: string) {
        return this.api.patchDotNet(`Entity/${entityId}`, { paperless: true });
    }

    async getAgentPolicies(agentId: string) {
        // const buyer = await this.api.getArray(`PolicySummary`, { agentId_eq: agentId }, () => new PolicySummary());
        // const seller = await this.api.getArray(`PolicySummary`, { sellerAgentId_eq: agentId }, () => new PolicySummary());

        // return buyer.concat(seller).sort((a, b) => a.effectiveDate > b.effectiveDate ? 1 : -1);

        const buyersPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { AgentId_eq: agentId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const sellersPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { SellerAgentId_eq: agentId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const policies = buyersPolicies.concat(sellersPolicies);
        return this.getUniqueSorted(policies);
    }

    async getBranchPolicies(branchId: string) {
        // return this.api.getArray(`PolicySummary`, { BranchId_eq: branchId, orderby: 'effectiveDate' }, () => new PolicySummary());
        const buyersBranchPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { BuyersAgentBranchId_eq: branchId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const sellersBranchPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { SellersAgentBranchId_eq: branchId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const policies = buyersBranchPolicies.concat(sellersBranchPolicies);
        return this.getUniqueSorted(policies);
    }

    getUniqueSorted(policies: PolicySummaryV2[]): PolicySummaryV2[] {
        const unique: PolicySummaryV2[] = [];
        for (const policy of policies) {
            const existing = unique.find(p => p.id == policy.id);
            if (!existing) {
                unique.push(policy);
            }
        }
        unique.sort((a, b) => {
            if (a.effectiveDate < b.effectiveDate) return -1;
            if (a.effectiveDate > b.effectiveDate) return 1;
            if (a.policyNumber < b.policyNumber) return -1;
            if (a.policyNumber > b.policyNumber) return 1;
            return 0;
        });
        return unique;
    }

    async getRealEstateCompanyPolicies(realEstateCompanyId: string) {
        const buyersCompanyPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { BuyersAgentCompanyId_eq: realEstateCompanyId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const sellersCompanyPolicies = await this.api.getArrayNode(`PolicySummaryV2`, { SellersAgentCompanyId_eq: realEstateCompanyId, orderby: 'effectiveDate' }, () => new PolicySummaryV2());
        const policies = buyersCompanyPolicies.concat(sellersCompanyPolicies);
        return this.getUniqueSorted(policies);
    }

    getClosingOfficerPolicies(closingOfficerId: string) {
        return this.api.getArrayNode(`PolicySummaryV2`, { closingOfficerId_eq: closingOfficerId, orderby: 'EffectiveDate' }, () => new PolicySummaryV2());
    }

    getSupportsChat() {
        return this.api.getSingleDotNet('Entity/company-supports-web-chat');
    }

    getContractorACHPaymentInfo(entityId: string): Promise<EntityACH> {
        return this.api.getSingleDotNet(`EntityACH`, { entityId_eq: entityId }, () => new EntityACH());
    }

    mergeEntities(entityIdToKeep: string, entityIdToDelete: string) {
        return this.api.patchSingleDotNet(`Entity/merge/${entityIdToKeep}/${entityIdToDelete}`, null);
    }

    saveContractorACHPaymentInfo(paymentInfo: EntityACH): Promise<any> {
        return this.api.postSingleDotNet(`EntityACH`, paymentInfo);
    }

    deleteContractorACHPaymentInfo(id: string) {
        return this.api.deleteDotNet(`EntityACH/${id}`);
    }

    unDeleteContractorACHPaymentInfo(id: string) {
        return this.api.unDeleteDotNet(`EntityACH/${id}`);
    }

    getContractorJobExclusionConditions(contractorId: string): Promise<ContractorJobExclusionCondition[]> {
        return this.api.getArrayDotNet(`ContractorJobExclusionCondition`, { entityId_eq: contractorId, orderby: 'sort' }, () => new ContractorJobExclusionCondition());
    }

    saveContractorJobExclusionConditions(condition: ContractorJobExclusionCondition) {
        return this.api.postSingleDotNet(`ContractorJobExclusionCondition`, condition);
    }

    deleteContractorJobExclusionCondition(condition: ContractorJobExclusionCondition) {
        return this.api.deleteDotNet(`ContractorJobExclusionCondition/${condition.id}`);
    }


    updateContractorJobExclusionConditionSort(condition: ContractorJobExclusionCondition) {
        return this.api.patchSingleDotNet(`ContractorJobExclusionCondition/${condition.id}`, { sort: condition.sort });
    }

    sendSlyBroadcast(broadcast: SlyBroadcastMessage) {
        return this.api.postSingleDotNet('SlyBroadcast', broadcast);
    }

    sendPushNotification(id: string, notification: UserNotification) {
        return this.api.postSingleDotNet(`UserNotification`, notification);
    }

    async sendPushNotificationToRoles(role: Role, notification: UserNotification) {
        const employeeIds = await this.api.getArrayDotNet('Entity', { type_eq: 'Employee', roleId_eq: role.id, select: 'id' });
        const promises = [];

        for (const id of employeeIds) {
            const notificationToSend: UserNotification = JSON.parse(JSON.stringify(notification));
            notificationToSend.id = UtilitiesService.newid();
            notificationToSend.userId = id;
            promises.push(this.sendPushNotification(id, notificationToSend));
        }

        return Promise.all(promises);
    }

    sendAppPushNotification(entityId: string, title: string, message: string) {
        return this.api.getSingleDotNet(`rest/send-app-push-notification`, { entityId, title, body: message });
    }

    getFavImageUrl(entityId: string): string {
        if (!entityId) {
            return null;
        }

        return `${ApiService.endPointDotNet}entity/${entityId}/fav-icon`;
    }

    getTags(entityId: string): Promise<Tag[]> {
        return this.api.getArrayDotNet(`Entity/${entityId}/tags`);
    }

    getTasks(entityId: string): Promise<Task[]> {
        return this.api.getArrayDotNet('task', {
            entityId_eq: entityId,
            orderBy: "Priority descending",
        }, () => new Task());
    }

    addTags(entityId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Entity/${entityId}/add-tags`, tags);
    }

    removeTags(entityId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Entity/${entityId}/remove-tags`, tags);
    }



    replaceTags(entityId: string, tags: Tag[]) {
        return this.api.patchSingleDotNet(`Entity/${entityId}/replace-tags`, tags);
    }

    async getUserPermissions(id: string): Promise<Permission[]> {
        return this.api.getArrayDotNet(`Entity/${id}/permissions`, null, () => new Permission());
    }

    getEntityDocumentTypes(): Promise<ContractorDocumentType[]> {
        return this.api.getArrayDotNet(`ContractorDocumentType`, { orderby: 'name' }, () => new ContractorDocumentType());
    }

    getEntityDocuments(id: string): Promise<ContractorDocument[]> {
        return this.api.getArrayDotNet(`ContractorDocument`, { entityId_eq: id }, () => new ContractorDocument());
    }

    saveEntityDocument(document: ContractorDocument): Promise<any> {
        return this.api.postSingleDotNet(`ContractorDocument`, document);
    }

    saveEntityDocumentFile(document: ContractorDocument): Promise<any> {
        return this.api.postSingleDotNet(`ContractorDocument/${document.entityId}/document/${document.contractorDocumentTypeId}`, document);
    }

    getEntityDocumentFileExists(document: ContractorDocument): Promise<boolean> {
        return this.api.getSingleDotNet(`ContractorDocument/${document.entityId}/document/${document.contractorDocumentTypeId}/exists`);
    }

    getEntitiesByTagId(id: string) {

    }

    getEntities(type: string, keyword: string): Promise<EntityKeywordSearch[]> {
        if (type === "keyword")
            return this.api.getArrayNode(`entity/get-entities-keyword/${keyword}/none/none/none`);
        if (type === "email")
            return this.api.getArrayNode(`entity/get-entities-keyword/none/${keyword}/none/none`);
        if (type === "phone")
            return this.api.getArrayNode(`entity/get-entities-keyword/none/none/${keyword}/none`);
        if (type === "recent")
            return this.api.getArrayNode(`entity/get-entities-keyword/none/none/none/${keyword}`);
        if (type === "idNumber")
            return this.api.getArrayNode(`entity/get-entities/${keyword}`);
        return null;
    }

    async getEntityDuplicates(id: string, name: string, email: string, type: string) {
        return this.api.getArrayNode(`entity/get-entity-duplicates/${id}/${name}/${email}/${type}`);
    }

    async getBranchAgents(parentId: string) {
        return this.api.getArrayNode(`EntitySummary`, { parentId_eq: parentId, orderby: 'name', type_eq: 'Agent' });
    }

    getRealEstateBranches(realEstateCompanyId: string) {
        return this.api.getArrayNode(`EntitySummary`, { parentId_eq: realEstateCompanyId, orderby: 'name', type_eq: 'Branch' });
    }

    getEntitiesByType(type: string, includeSettings = false): Promise<Entity[]> {
        const select = includeSettings ? 'id,name,parentId,settings' : 'id,name,parentId';
        return this.api.getArrayDotNet(`Entity`, { type_eq: type, select: select, orderBy: 'name' }, () => new Entity());
    }


    getEntitiesByType2(type: string) {
        return this.api.getArrayNode('Entity', { type_eq: type, orderby: 'name' });
    }

    getAgentsByTeam(id: string) {
        return this.api.getArrayNode('Entity', { teamId_eq: id, orderby: 'name' });
    }

    getActiveEntitiesWithNameByType(type: string): Promise<Entity[]> {
        return this.api.getArrayDotNet(`Entity`, { type_eq: type, inactive_eq: false, select: 'id,name,parentId', orderBy: 'name' });
    }

    getTwilioNumber(entityId: string): Promise<TwilioNumber> {
        return this.api.getSingleDotNet('TwilioNumber', { entityId_eq: entityId }, () => new TwilioNumber());
    }

    getLanguages(): Promise<Language[]> {
        return this.api.getArrayDotNet('customeronboarding/languages', { orderBy: 'name' }, () => new Language());
    }

    getLanguage(id: string): Promise<Language> {
        return this.api.getSingleDotNet(`Language/${id}`, null, () => new Language());
    }

    async getSmsSummary(userId: string = null, startDate: Date, endDate: Date): Promise<SmsSummary[]> {
        if (!userId) {
            userId = (await this.getLoggedInUser()).id;
        }

        return this.api.getArrayNode(`SmsSummary`, { entityId_eq: userId, messageDate_gte: startDate, messageDate_lte: endDate }, () => new SmsSummary());
    }

    async getSmsSummaries(correspondent: string, entityId: string): Promise<SmsSummary[]> {
        return this.api.getArrayNode(`Entity/get-sms-summaries/${correspondent}/${entityId}`, null, () => new SmsSummary());
    }

    async getConversationHistorySummary(userId: string = null, filter: string): Promise<SMSConversationSummary[]> {
        if (!userId) {
            userId = (await this.getLoggedInUser()).id;
        }

        return this.api.postArrayNode(`users/sms/get-sms-conversation-summary`, { entityId: userId, filter: filter }, () => new SMSConversationSummary());
    }

    async getPhoneNumberLabel(number: string) {
        const userId = (await this.getLoggedInUser()).id;

        return this.api.getSingleDotNet(`PhoneNumberLabel`, { entityId_eq: userId, number_eq: number });
    }

    async archiveTaskMessage(id: string) {
        return await this.api.patchSingleNode(`TaskMessage/${id}`, { isArchived: true });
    }

    async unArchiveTaskMessage(id: string) {
        return await this.api.patchSingleNode(`TaskMessage/${id}`, { isArchived: null });
    }

    archiveTaskMessages(correspondent: string) {
        return this.api.postNode(`Entity/archive-messages/${correspondent}`);
    }

    unArchiveTaskMessages(correspondent: string) {
        return this.api.postNode(`Entity/unarchive-messages/${correspondent}`);
    }

    savePhoneNumberLabel(label: PhoneNumberLabel) {
        return this.api.postSingleDotNet(`PhoneNumberLabel`, label);
    }

    async getConversationHistoryItems(internalSMSNumber: string, externalSMSNumber: string, lastMessageDate: Date = null): Promise<TaskMessage[]> {
        if (!lastMessageDate) {
            lastMessageDate = new Date();
            lastMessageDate.setHours(lastMessageDate.getHours() + 20);
        }

        return this.api.getArrayDotNet(`TaskMessage`, { internalSMSNumber_eq: internalSMSNumber, externalSMSNumber_eq: externalSMSNumber, orderby: 'CreatedDate desc', take: 50 });
    }

    async createTwilioNumber(entityId: string): Promise<any> {
        const twilioNumber = await this.api.postSingleDotNet(`Twilio/create-number/${entityId}`, null, null, () => new TwilioNumber());
        return this.api.postNode(`twilio/add-number-to-messaging-service/${twilioNumber.id}`);
    }

    deleteTwilioNumber(twilioNumberId: string) {
        return this.api.deleteDotNet(`Twilio/delete-number/${twilioNumberId}`);
    }

    getAssignedAEForEntity(entityId: string) {
        return this.api.getSingleDotNet(`entity/${entityId}/get-assigned-ae`);
    }

    async changeLocalLoginPassword(oldPassword: string, newPassword: string, entityId: string = null) {
        if (!entityId) {
            const loggedInUser = await this.getLoggedInUser(false);
            entityId = loggedInUser.id;
        }

        return this.api.patchSingleDotNet(`${await this.auth.getAuthority()}login/change-password?entityId=${entityId}&oldPassword=${oldPassword}&newPassword=${newPassword}`, null);
    }

    async changeLocalLoginPasswordForLoginId(oldPassword: string, newPassword: string, loginId: string = null) {
        return this.api.patchSingleDotNet(`${await this.auth.getAuthority()}login/change-password-for-login-id?loginId=${loginId}&oldPassword=${oldPassword}&newPassword=${newPassword}`, null);
    }

    async getHomeDashboardConfiguration(): Promise<EntityConfiguration> {
        const entity = await this.getLoggedInUser(false);
        return this.api.getSingleDotNet(`EntityConfiguration`, { entityId_eq: entity.id, configurationType_eq: 'HOME_PAGE' });
    }

    deleteConfiguration(id: string) {
        return this.api.deleteDotNet(`EntityConfiguration/${id}`);
    }

    async getPivotDefinitions(): Promise<EntityConfiguration[]> {
        const entity = await this.getLoggedInUser(false);
        return this.api.getArrayDotNet(`EntityConfiguration`, { entityId_eq: entity.id, configurationType_eq: 'PIVOT_REPORT', select: 'id,name' }, () => new EntityConfiguration());
    }


    async getDefaultHomeDashboardConfiguration(): Promise<EntityConfiguration> {
        return this.api.getSingleDotNet(`EntityConfiguration`, { entityid_eq: '{{null}}', configurationType_eq: 'HOME_PAGE' });
    }

    saveEntityConfiguration(configuration: EntityConfiguration) {
        return this.api.postSingleDotNet(`EntityConfiguration`, configuration);
    }

    async getEntityConfiguration(key: string): Promise<EntityConfiguration> {
        const entity = await this.getLoggedInUser(false);
        return this.api.getSingleDotNet(`EntityConfiguration`, { ConfigurationType_eq: key, EntityId_eq: entity?.id }, () => new EntityConfiguration());
    }

    async getEntityConfigurationById(id: string): Promise<EntityConfiguration> {
        return this.api.getSingleDotNet(`EntityConfiguration/${id}`);
    }

    async getConfigurationByKey(key: string): Promise<EntityConfiguration> {
        return this.api.getSingleDotNet(`EntityConfiguration`, { ConfigurationType_eq: key, EntityId_eq: '{{null}}' }, () => new EntityConfiguration());

    }

    async getConfigurationByKeyAndEntity(key: string, entityId: string): Promise<EntityConfiguration> {
        return this.api.getSingleDotNet(`EntityConfiguration`, { ConfigurationType_eq: key, EntityId_eq: entityId }, () => new EntityConfiguration());

    }


    async getEntityConfigurationId(key: string): Promise<string> {
        const entity = await this.getLoggedInUser(false);
        return this.api.getSingleDotNet(`EntityConfiguration`, { ConfigurationType_eq: key, EntityId_eq: entity?.id, Select: 'Id' });
    }

    private delay(milliseconds: number) { return new Promise<void>(completeAction => setTimeout(completeAction, milliseconds)); }

    async getUserTextSnippets(): Promise<UserTextSnippet[]> {

        const loggedInUser = await this.getLoggedInUser(false);

        return this.api.getArrayDotNet('UserTextSnippet', { entityid_eq: loggedInUser?.id, orderby: 'key' }, () => new UserTextSnippet());
    }

    saveUserTextSnippet(snippet: UserTextSnippet) {
        return this.api.postSingleDotNet('UserTextSnippet', snippet);
    }

    deleteUserTextSnippet(id: string) {
        return this.api.deleteDotNet(`UserTextSnippet/${id}`);
    }

    unDeleteUserTextSnippet(id: string) {
        return this.api.unDeleteDotNet(`UserTextSnippet/${id}`);
    }

    async getAvailableRoutes(): Promise<string[]> {
        const user = await this.getLoggedInUser();
        if (!user) { return []; }

        if (this.cachedRoutes && this.cachedRoutes.length > 0 && this.cachedRoutesUserToken === this.auth.authorizationHeader) {
            return this.cachedRoutes.filter(i => i);
        } else {
            this.cachedRoutes = await this.api.getArrayDotNet<string>("Login/AvailableRoutes");
            this.cachedRoutes.push('users/sms-conversation-history');
            this.cachedRoutes.push('users/settings');
            this.cachedRoutes.push('users/tasks');
            this.cachedRoutes.push('users/user-notifications');

            this.cachedRoutesUserToken = this.auth.authorizationHeader;
        }
        return this.cachedRoutes.filter(i => i);
    }

    async logRouteDenied(route: string) {
        this.api.postVoidDotNet("Login/LogRouteDenied", { route });
    }

    getUserIsPotentialMaintenanceServiceContractor(id: string) {
        return this.api.getSingleNode(`entity/is-maint-service-contractor/${id}`);
    }

    async getLoggedInUser(getFullInfo = false, returnAnonymous = false): Promise<Entity> {
        let loggedInUser: Entity;
        if (!this.auth.isLoggedIn) {
            if (returnAnonymous) {
                loggedInUser = this.cachedUser;
                if (!loggedInUser) {
                    loggedInUser = new Entity();
                    loggedInUser.id = UtilitiesService.newid();
                    loggedInUser.name = 'Anonymous';
                    if (localStorage.getItem('anonymousUserId')) {
                        loggedInUser.id = localStorage.getItem('anonymousUserId');
                    }
                    localStorage.setItem('anonymousUserId', loggedInUser.id);
                    this.cachedUser = loggedInUser;
                }
                return loggedInUser;
            } else {
                return null;
            }
        }

        let firstTime = true;

        let count = 0;
        while (!loggedInUser) {
            count++;

            if (count > 100) {
                if (document.URL.indexOf('4201') > -1 || document.URL.indexOf('service-pros') > -1) {
                    // Not sure how safe a change this is, so I'm only enabling it for the service pro portal for now
                    this.auth.logOut();
                }
                throw 'Something went wrong with getting the logged in user';

            }
            if (firstTime) {
                firstTime = false;
            } else {
                await this.delay(1);
            }
            
            if (this.cachedUser && this.cachedUserToken === this.auth.authorizationHeader) {
                loggedInUser = this.cachedUser;
            } else {
                if (!this.gettingLoggedInUser) {
                    try {
                        this.gettingLoggedInUser = true;
                        loggedInUser = await this.api.getSingleDotNet<Entity>("Login/LoggedInEntity", null, () => new Entity());
                        this.cachedUser = loggedInUser;
                        this.cachedUserToken = this.auth.authorizationHeader;
                        this.gettingLoggedInUser = false;
                    } catch (error: any) {
                        if (error.status == 401) {
                            await this.auth.logIn();
                            return;
                        }
                        console.error(error);
                        this.gettingLoggedInUser = false;
                        await this.delay(10000);
                    }
                } else {
                    // Not completely sure this is needed
                    await this.delay(25);
                }
            }
        }
        // When getting full info, we always pull back the address and phone numbers from the database to make sure we have the latest.
        if (getFullInfo) {
            const promises = [];
            if (loggedInUser.addressId) {
                promises.push(this.api.getSingleDotNet<Address>("Address/" + loggedInUser.addressId, null, () => new Address())
                    .then(result => loggedInUser.address = result));
            } else {
                loggedInUser.address = new Address();
            }
            promises.push(this.api.getArrayDotNet<PhoneNumber>("PhoneNumber", { where: 'entityId="' + loggedInUser.id + '"' }, () => new PhoneNumber())
                .then(result => loggedInUser.phoneNumbers = result));
            await Promise.all(promises);
        }

        this.cachedUser = loggedInUser;
        this.cachedUserToken = this.auth.authorizationHeader;

        return loggedInUser;
    }

    getEntityByName(name: string): Promise<Entity> {
        return this.api.getSingleNode('Entity', { name_eq: name }, () => new Entity());
    }

    getBasicEntity(entityId: string): Promise<Entity> {
        return this.api.getSingleDotNet('Entity/' + entityId, null, () => new Entity());
    }

    getContractorTrade(entityId: string, tradeId: string): Promise<ContractorTrade> {
        return this.api.getSingleDotNet('ContractorTrade', { entityId_eq: entityId, tradeId_eq: tradeId });
    }

    deleteContractorTradeRegion(item: ContractorTradeRegion): Promise<any> {
        return this.api.deleteDotNet(`ContractorTradeRegion/${item.id}`);
    }

    getGeoJsonFromKml(kmlData) {
        return this.api.postSingleDotNet('PostalCodeGeography/geo-json-from-kml', { file: kmlData });
    }

    getRegionPostalCodes(regionId: string): Promise<EntityRegionPostalCode[]> {

        return this.api.getArrayDotNet('EntityRegionPostalCode',
            {
                entityId_eq: regionId,
                orderBy: 'PostalCode',
            }, () => new EntityRegionPostalCode());
    }

    saveRegionPostalCode(regionPostalCode: EntityRegionPostalCode): Promise<string> {
        return this.api.postIdDotNet('EntityRegionPostalCode', regionPostalCode);
    }

    deleteRegionPostalCode(regionPostalCode: EntityRegionPostalCode) {
        return this.api.deleteDotNet('EntityRegionPostalCode/' + regionPostalCode.id);
    }


    getOverdueContractorVisitCount(userId: string, areas: string[], excludedAreas: string[]): Promise<number> {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const parameters: any = {
            contractorRelationsManagerId_eq: userId,
            nextVisit_lt: date
        };
        if (areas && areas.length > 0) {
            let qString = '';
            areas.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            parameters.AreaId_contains = qString;
        }
        if (excludedAreas && excludedAreas.length > 0) {
            let qString = '';
            excludedAreas.forEach(item => {
                if (qString) {
                    qString += ',';
                }
                qString += item;
            });

            parameters.AreaId_ncontains = qString;
        }

        return this.api.getSingleDotNet<number>('ContractorVisitSummary/Count', parameters);
    }

    async getMyOverdueContractorVisitCount(areas: string[], excludedAreas: string[]): Promise<number> {
        const entity = await this.getLoggedInUser(false);
        const visits = await this.getOverdueContractorVisitCount(entity.id, areas, excludedAreas);
        return visits;
    }

    uploadKmlFile(entityId: string, kmlFile: string) {
        return this.api.postVoidDotNet(`Entity/${entityId}/upload-kml`, { file: kmlFile });
    }

    saveContractorTradeRegion(item: ContractorTradeRegion): Promise<string> {
        return this.api.postIdDotNet('ContractorTradeRegion', item);
    }

    saveEntityRegionPostalCode(item: EntityRegionPostalCode): Promise<string> {
        return this.api.postIdDotNet('EntityRegionPostalCode', item);
    }

    deleteEntityRegionPostalCode(item: EntityRegionPostalCode) {
        return this.api.deleteDotNet(`EntityRegionPostalCode/${item.id}`);
    }

    async isLoggedInUserInRole(role: string): Promise<boolean> {
        // TODO: wire this up to a true authentication service -- Task 63
        return true;
    }

    async saveEntityReportDefinition(reportDefinition: EntityReportDefinition, parameters: any) {
        reportDefinition.reportParameters = JSON.stringify(parameters);
        const user = await this.getLoggedInUser(false);
        reportDefinition.entityId = user.id;
        const results = await this.api.postIdDotNet('EntityReportDefinition', reportDefinition);
        return results;
    }

    getContractorRegions(): Promise<ContractorStateGrouping[]> {
        return this.api.getArrayDotNet('entity/get-contractor-regions', null, () => new ContractorStateGrouping());
    }
    getContractorRegionsForContractor(contractorId: string): Promise<ContractorStateGrouping[]> {
        return this.api.getArrayDotNet(`entity/${contractorId}/get-contractor-regions-for-contractor`, null, () => new ContractorStateGrouping());
    }

    //

    getContractorTradeRegions(contractorId: string): Promise<ContractorTradeRegion[]> {
        return this.api.getArrayDotNet(`ContractorTradeRegion/for-contractor/${contractorId}`, null,
            () => new ContractorTradeRegion());
    }

    async getEntityReportDefinitions(reportKey: string): Promise<EntityReportDefinition[]> {
        const user = await this.getLoggedInUser(false);
        const definitions = await this.api.getArrayDotNet('EntityReportDefinition', {
            properties: 'name,id,entityId,reportParameters',
            entityId_eq: user.id,
            reportKey_eq: reportKey,
        }, () => new EntityReportDefinition());
        return definitions;
    }

    deleteEntityReportDefinition(definition: EntityReportDefinition) {
        return this.api.deleteDotNet('EntityReportDefinition/' + definition.id);
    }

    undoDeleteEntityReportDefinition(definition: EntityReportDefinition) {
        return this.api.unDeleteDotNet('EntityReportDefinition/' + definition.id);
    }

    deleteEntityConfiguration(configuration: EntityConfiguration) {
        return this.api.deleteDotNet(`EntityConfiguration/${configuration.id}`);
    }

    unDeleteEntityConfiguration(configuration: EntityConfiguration) {
        return this.api.unDeleteDotNet(`EntityConfiguration/${configuration.id}`);
    }

    async getTeams() {
        return this.api.getArrayNode(`Entity`, { type_eq: 'team' }, () => new Entity());
    }

    async getChildEntitiesOfType(parentId: string, type: string): Promise<Entity[]> {
        return this.api.getArrayDotNet("Entity", { parentId_eq: parentId, type_eq: type }, () => new Entity());
    }

    async getChildEntities(parentId: string): Promise<Entity[]> {
        return this.api.getArrayDotNet('Entity', { parentId_eq: parentId }, () => new Entity());
    }


    async getEntityWithZipCodes(type: 'ServiceProManagerArea' | 'ServiceProRegion' | 'ServiceProTerritory' | 'SalesRegion' | 'SalesArea' | 'SalesTerritory' | 'ProductArea' | 'PricingArea' | 'AccountingArea') {
        const areas = await this.api.getArrayDotNet('Entity', { type_eq: type, orderBy: 'name' }, () => new Entity());
        for (const area of areas) {
            if (area.settings) {
                const settings = JSON.parse(area.settings);
                if (settings.zipCodes) {
                    area.zipCodes = settings.zipCodes;
                } else {
                    area.zipCodes = [];
                }
            } else {
                area.zipCodes = [];
            }
        }
        return areas;
    }

    async getQuickbooksAccounts(areaId: string) {
        return this.api.getArrayNode('accounting/quickbooks-accounts/' + areaId);
    }

    async getZipCodes(): Promise<ZipCode[]> {
        return this.api.getArrayNode('ZipCode', {
            select: "zip,primaryCity,state,statefullname,county,coordinates", orderBy: "stateFullName,county,primaryCity,zip",
        }, () => new ZipCode());
    }

    async getStates(zipCodes: ZipCode[]): Promise<State[]> {
        const states: State[] = [];
        for (const zip of zipCodes) {
            if (!states.find(s => s.state === zip.state)) {
                for (const item of await this.api.getArrayNode("State", { state_eq: zip.state }, () => new State()))
                    states.push(item);
            }
        }
        return states;
    }

    async getCounties(states: State[]): Promise<County[]> {
        const counties: County[] = [];
        for (const state of states) {
            for (const item of await this.api.getArrayNode("County", { state_eq: state.state }, () => new County()))
                counties.push(item);
        }

        return counties;
    }


    async saveEntityWithZipCodes(entity: Entity, additionalSettings: any = null) {
        const zipCodes: string[] = entity.zipCodes;
        if (!additionalSettings) {
            additionalSettings = { zipCodes };
        } else {
            additionalSettings['zipCodes'] = zipCodes;
        }
        entity.settings = JSON.stringify(additionalSettings);
        await this.api.postVoidDotNet(`Entity`, entity);
    }

    async deleteSEntity(entity: Entity) {
        return this.api.deleteDotNet(`Entity/${entity.id}`);
    }

    async getEntityPhoneNumbers(entity: Entity) {
        const phoneNumbers = await this.api.getArrayDotNet('PhoneNumber', { entityId_eq: entity.id }, () => new PhoneNumber());
        entity.phoneNumbers = phoneNumbers;
    }

    async getEntityUnAuthenticated(entityId: string): Promise<Entity> {
        const entitySummary: EntitySummary = await this.api.getSingleDotNet(`Entity/${entityId}/get-by-id`);
        const entity = new Entity();
        UtilitiesService.copyObject(entitySummary, entity);
        entity.phoneNumbers = [];
        if (entitySummary.homeNumber) {
            entity.phoneNumbers.push(new PhoneNumber(entitySummary.homeNumber, 'Home'));
        }
        if (entitySummary.mobileNumber) {
            entity.phoneNumbers.push(new PhoneNumber(entitySummary.mobileNumber, 'Mobile'));
        }
        if (entitySummary.workNumber) {
            entity.phoneNumbers.push(new PhoneNumber(entitySummary.workNumber, 'Office'));
        }
        entity.address = new Address();
        entity.address.address1 = entitySummary.address1;
        entity.address.city = entitySummary.city;
        entity.address.state = entitySummary.state;
        entity.address.postalCode = entitySummary.postalCode;

        return entity;
    }

    async getEntitySummary(id: string) {
        return this.api.getSingleNode("Entity/get-entity-summary/" + id, null, () => new EntitySummary());
    }

    async getFullEntity(entityId: string): Promise<Entity> {
        const entity = await this.api.getSingleDotNet("Entity/" + entityId, null, () => new Entity());

        const promises = [];
        if (!entity) {
            console.error(`Could not find entity: ${entityId}`);
        }

        if (entity) {
            promises.push(
                this.api.getArrayDotNet('PhoneNumber', { entityId_eq: entityId }, () => new PhoneNumber())
                    .then(phoneNumbers => { entity.phoneNumbers = phoneNumbers; })
            );
        }

        if (entity && entity.addressId) {
            promises.push(
                this.api.getSingleDotNet('Address/' + entity.addressId, null, () => new Address())
                    .then(address => {
                        if (address) {
                            entity.address = address;
                        } else {
                            entity.address = new Address();
                            entity.address.id = UtilitiesService.newid();
                            entity.addressId = entity.address.id;
                        }
                    })
            );
        } else if (entity) {
            entity.address = new Address();
            entity.address.id = UtilitiesService.newid();
            entity.addressId = entity.address.id;
            entity.address.address1 = '';
            entity.address.city = '';
            entity.address.state = '';
            entity.address.postalCode = '';
        }

        if (entity && entity.parentId) {
            promises.push(
                this.getFullEntity(entity.parentId).then(parent => {
                    entity.parent = parent;
                })
            );
        }

        await Promise.all(promises);
        return entity;
    }


    getContractorBrands(contractorId: string) {
        return this.api.getArrayDotNet('ContractorBrand', { contractorId_eq: contractorId }, () => new ContractorBrand());
    }

    saveContractorBrands(contractorId: string, contractorBrands: ContractorBrand[]) {
        return this.api.postVoidDotNet('ContractorBrand/' + contractorId + '/save', contractorBrands);
    }

    getCompanyInfo() {
        return this.api.getSingleDotNet('entity/companyInfo');
    }

    async getCompanyStatesAndRegions(): Promise<CompanyStatesAndRegions[]> {
        const user = await this.getLoggedInUser();
        let statesAndRegions = await this.api.getArrayDotNet('entity/company/states-and-regions', null, () => new CompanyStatesAndRegions(user.id));
        statesAndRegions = statesAndRegions.sort((a, b) => a.name > b.name ? 1 : -1);
        statesAndRegions.forEach(state => {
            state.regions = state.regions.sort((a, b) => a.name > b.name ? 1 : -1);
        });
        return statesAndRegions;
    }

    async getCompanyStatesAreasAndSalesRegions(): Promise<CompanyStatesAndRegions[]> {
        const user = await this.getLoggedInUser();
        let statesAndRegions = await this.api.getArrayDotNet('entity/company/states-areas-and-sales-regions', null, () => new CompanyStatesAndRegions(user.id));
        statesAndRegions = statesAndRegions.sort((a, b) => a.name > b.name ? 1 : -1);
        statesAndRegions.forEach(state => {
            state.regions = state.regions.sort((a, b) => a.name > b.name ? 1 : -1);
            state.regions = state.regions.map(area => this.api.convertToType(area, () => new CompanyRegion()));
        });
        return statesAndRegions;
    }

    getNotes(entityId: string): Promise<NoteClient[]> {
        return this.api.getArrayDotNet('note', {
            entityId_eq: entityId,
            orderBy: 'CreatedDate descending',
        }, () => new NoteClient());
    }

    getEmployees(): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity', {
            type_eq: 'Employee',
            orderBy: 'lastName',
        }, () => new Entity());
    }

    getAdministrators(): Promise<Entity[]> {
        return this.api.getArrayNode('entity', {
            type_eq: 'Employee',
            roleId_eq: '328c6d44-0d10-4c45-bb9e-1654a0ccca8c',
            orderBy: 'lastName'
        }, () => new Entity());
    }

    async getAccountExecutives(): Promise<Entity[]> {
        return this.api.getArrayNode("entity/get-account-executives", null, () => new Entity());
    }

    getInsideSalesReps() {
        return this.api.getArrayNode(`entity/get-inside-sales-reps`, null, () => new Entity());
    }

    getActiveEmployees(): Promise<Entity[]> {

        return this.api.getArrayNode('entity', {
            type_eq: 'Employee',
            orderBy: 'lastName',
            inactive_eq: 0
        }, () => new Entity());
    }

    getRoles(): Promise<any[]> {
        return this.api.getArrayDotNet("role", { select: 'id,name' }, () => new Role());
    }

    getVendors(): Promise<Entity[]> {
        return this.api.getArrayDotNet('entity', {
            type_eq: 'Vendor',
            orderBy: 'lastName'
        }, () => new Entity());
    }

    updateSettings(entity: Entity) {
        return this.api.patchDotNet('entity/' + entity.id, { Settings: entity.settings });
    }

    getCompanyTimeZone(): Promise<any> {
        return this.api.getSingleDotNet('entity/company/timezone');
    }

    // deleteEntity(entity: Entity) {
    //     return this.api.deleteDotNet('Entity/' + entity.id);
    // }

    deleteEntity(entity: Entity) {
        return this.api.deleteNode('Entity/' + entity.id);
    }

    undoDelete(entity: Entity): Promise<any> {
        return this.api.unDeleteDotNet('Entity/' + entity.id);
    }

    async updateEntity(entity: Entity, showToastComplete = true): Promise<Entity> {
        if (entity.address && !entity.address.id) {
            entity.address.id = UtilitiesService.newid();
        }

        if (entity.address) {
            entity.addressId = entity.address.id;
        }

        if (entity.settingsModel) {
            entity.settings = JSON.stringify(entity.settingsModel);
        } else {
            entity.settings = null;
        }

        if (entity.type === 'Employee') {
            entity.name = entity.firstName + ' ' + entity.lastName;
        }


        if (entity.address) {
            entity.addressId = await this.api.postIdDotNet('Address', entity.address);
        } else {
            entity.addressId = null;
        }


        entity.id = await this.api.postIdDotNet('Entity', entity);

        const promises = [];
        let numbers = entity.phoneNumbers.filter(number => number.type === 'Home');
        await this.processPhoneNumber(entity.id, numbers);

        numbers = entity.phoneNumbers.filter(number => number.type === 'Work');
        await this.processPhoneNumber(entity.id, numbers);

        numbers = entity.phoneNumbers.filter(number => number.type === 'Mobile');
        await this.processPhoneNumber(entity.id, numbers);


        if (showToastComplete) {
            this.missionService.showSuccessToast('Save Complete');
        }
        return entity;
    }

    async processPhoneNumber(entityId: string, numbers: PhoneNumber[]) {
        if (numbers?.length === 0) return;

        if (numbers?.length > 1) {
            for (const num of numbers) {
                if (!num.id) num.id = UtilitiesService.newid();
                num.entityId = entityId;
                const exists = await this.api.getSingleDotNet('PhoneNumber', { id_eq: num.id });
                if (num.number && num.number != exists.number) {
                    await this.api.postIdDotNet('PhoneNumber', num);

                } else {
                    await this.api.deleteDotNet('PhoneNumber/' + num.id);
                }
            }
        }
        else {
            if (!numbers[0].id) numbers[0].id = UtilitiesService.newid();
            numbers[0].entityId = entityId;

            if (numbers[0].number) {
                await this.api.postIdDotNet('PhoneNumber', numbers[0]);
            }
            else {
                await this.api.deleteDotNet('PhoneNumber/' + numbers[0].id);
            }
        }
    }

    async updateEntityUnAuthenticated(entity: Entity): Promise<Entity> {
        if (entity.address && !entity.address.id) {
            entity.address.id = UtilitiesService.newid();
        }

        if (entity.address) {
            entity.addressId = entity.address.id;
        }

        if (entity.settingsModel) {
            entity.settings = JSON.stringify(entity.settingsModel);
        } else {
            entity.settings = null;
        }

        if (entity.type === 'Employee') {
            entity.name = entity.firstName + ' ' + entity.lastName;
        }


        if (entity.address && entity.address.address1) {
            entity.addressId = await this.api.postIdDotNet('Entity/Anonymous/Address', entity.address);
        } else {
            entity.addressId = null;
        }

        entity.id = await this.api.postIdDotNet('Entity/Anonymous/Entity', entity);
        const promises = [];
        entity.phoneNumbers.forEach(phoneNumber => {
            if (!phoneNumber.id) {
                phoneNumber.id = UtilitiesService.newid();
            }
            phoneNumber.entityId = entity.id;

            if (phoneNumber.number) {
                promises.push(this.api.postIdDotNet('Entity/Anonymous/PhoneNumber', phoneNumber));
            } else {
                promises.push(this.api.deleteDotNet('Entity/Anonymous/PhoneNumber/' + phoneNumber.id));
            }
        });
        await Promise.all(promises);
        return entity;
    }



    async updateProfilePicture(base64: string): Promise<void> {
        const currentUser = await this.getLoggedInUser();
        const url = 'Entity/Photo/' + currentUser.id;
        const parameters = { file: base64 };
        await this.api.postVoidDotNet(url, parameters);
        this.missionService.sendNotification('RELOAD_PROFILE_PICTURE');
    }

    updateEntityPhoto(entityId: string, base64: string, name = ''): Promise<void> {
        const url = 'Entity/Photo/' + entityId;
        if (!name) {
            name = entityId + '.jpg';
        }
        const parameters = { file: base64, name };
        return this.api.postVoidDotNet(url, parameters);
    }

    updateWhiteEntityPhoto(entityId: string, base64: string, name = ''): Promise<void> {
        const url = 'Entity/Photo/' + entityId + '/white';
        if (!name) {
            name = entityId + '.jpg';
        }
        const parameters = { file: base64, name };
        return this.api.postVoidDotNet(url, parameters);
    }

    updateEntityFavIcon(entityId: string, base64: string, name = ''): Promise<void> {
        const url = 'Entity/' + entityId + '/fav-icon';
        if (!name) {
            name = entityId + '.jpg';
        }
        const parameters = { file: base64, name };
        return this.api.postVoidDotNet(url, parameters);
    }

    async getContractorTechnicians(entityId: string): Promise<Entity[]> {
        const employees = await this.api.getArrayDotNet('Entity', {
            parentId_eq: entityId,
            type_eq: 'Technician',
            orderBy: 'Name'
        }, () => new Entity());

        return employees;
    }

    async getContractorContacts(entityId: string): Promise<EntitySummary[]> {
        const employees = await this.api.getArrayDotNet('EntitySummary', {
            parentId_eq: entityId,
            orderBy: 'Name'
        }, () => new EntitySummary());

        return employees;
    }

    getContractorTechnicians2(entityId: string): Promise<EntitySummary[]> {
        return this.api.getArrayNode('EntitySummary', { parentId_eq: entityId, type_eq: 'Technician', orderBy: 'Name' }, () => new EntitySummary());
    }

    getContractorTrades(entityId: string): Promise<any[]> {
        return this.api.getArrayDotNet('ContractorTrade', { entityId_eq: entityId, orderby: 'tradeName' });
    }

    updateParent(entityId: string, parentId: string) {
        return this.api.patchDotNet(`Entity/${entityId}`, { parentId });
    }

    async updatePassword(password: string, entityId: string = null): Promise<void> {
        const otherEntity = entityId ? true : false;
        if (entityId == null) {
            entityId = (await this.getLoggedInUser()).id;
        }
        await this.api.patchDotNet('users/Login/' + entityId, { password, });
        if (!otherEntity) {
            this.missionService.showSuccessToast('Your password has been updated.');
            if (localStorage.getItem('login-info')) {
                const loginInfo = JSON.parse(localStorage.getItem('login-info'));
                loginInfo.password = password;
                localStorage.setItem('login-info', JSON.stringify(loginInfo));
            } else if (sessionStorage.getItem('login-info')) {
                const loginInfo = JSON.parse(sessionStorage.getItem('login-info'));
                loginInfo.password = password;
                sessionStorage.setItem('login-info', JSON.stringify(loginInfo));
            }
        }
    }

    getContractorSummaryReport(tradeId: string, areaId: string, includeInactive: boolean): Promise<ContractorSummary[]> {
        // const query: any = { areaId_eq: areaId, tradeId_eq: tradeId };

        const query: any = {};
        if (areaId) {
            query.areaId_eq = areaId;
        }
        if (tradeId) {
            query.tradeId_eq = tradeId;
        }

        if (!includeInactive) {
            query.inactive_eq = false;
        }

        return this.api.getArrayDotNet('ContractorSummary', query, () => new ContractorSummary());
    }

    getVendorSummaryReport(includeInactive: boolean) {
        const query: any = { orderby: 'VendorName' };
        if (!includeInactive) {
            query.inactive_eq = false;
        }
        return this.api.getArrayDotNet('VendorSummary', query);
    }

    getStripePublicKey(): Promise<any> {
        return this.api.getSingleDotNet('entity/company/StripePublicKey');
    }

    getRecaptchaSiteKey() {
        return this.api.getSingleDotNet('entity/company/RecaptchaSiteKey')
    }

    // getQueue(queueQuery: QueueQuery, fieldsToInclude: string[], skip: number, take = 50): Promise<any> {

    //     let properties = '';
    //     if (fieldsToInclude.indexOf('id') === -1) {
    //         fieldsToInclude.push('id');
    //     }


    //     // Get the distinct fields values
    //     fieldsToInclude = Array.from(new Set(fieldsToInclude));
    //     if (fieldsToInclude) {
    //         fieldsToInclude.forEach(field => {
    //             if (properties !== '') {
    //                 properties += ',';
    //             }
    //             properties += field;
    //         });
    //     }

    //     const params = this.buildQueueQueryParams(queueQuery, properties, skip, take);

    //     return this.api.getArray(
    //         'EntitySummary/queue', params, () => new Entity());
    // }

    // getQueueItemCount(queueQuery: QueueQuery) {

    //     const params = this.buildQueueQueryParams(queueQuery, '', 0, 1000);

    //     delete params.take;
    //     delete params.skip;
    //     delete params.properties;
    //     delete params.orderby;

    //     return this.api.getSingle(`EntitySummary/queue/count`, params);
    // }

    // private buildQueueQueryParams(queueQuery: QueueQuery, properties: string, skip: number, take: number) {

    //     const params = {
    //         properties
    //     } as any;

    //     const createdDates = UtilitiesService.getDatesFromDateRange(queueQuery.createdDateRange);

    //     if (createdDates[0]) {
    //         params.CreatedDate_gte = createdDates[0];
    //     }
    //     if (createdDates[1]) {
    //         params.CreatedDate_lte = createdDates[0];
    //     }

    //     if (queueQuery.liabilityInsuranceStatus) {
    //         params.liabilityInsuranceStatus = queueQuery.liabilityInsuranceStatus;
    //     }
    //     if (queueQuery.workmansCompStatus) {
    //         params.workmansCompStatus = queueQuery.workmansCompStatus;
    //     }
    //     if (queueQuery.rocLicenseStatus) {
    //         params.rocLicenseStatus = queueQuery.rocLicenseStatus;
    //     }
    //     if (queueQuery.servicerAgreementStatus) {
    //         params.servicerAgreementStatus = queueQuery.servicerAgreementStatus;
    //     }
    //     if (queueQuery.additionalInsuranceStatus) {
    //         params.additionalInsuranceStatus = queueQuery.additionalInsuranceStatus;
    //     }


    //     if (queueQuery.selectedTrades && queueQuery.selectedTrades.length > 0) {
    //         let tradesString = '';
    //         queueQuery.selectedTrades.forEach(trade => {
    //             if (tradesString) {
    //                 tradesString += ',';
    //             }
    //             tradesString += trade.id;
    //         });

    //         params.trades = tradesString;
    //     }

    //     if (queueQuery.selectedTradesExcluded && queueQuery.selectedTradesExcluded.length > 0) {
    //         let tradesString = '';
    //         queueQuery.selectedTradesExcluded.forEach(trade => {
    //             if (tradesString) {
    //                 tradesString += ',';
    //             }
    //             tradesString += trade.id;
    //         });

    //         params.excludedTrades = tradesString;
    //     }

    //     if (queueQuery.selectedTags && queueQuery.selectedTags.length > 0) {
    //         let qString = '';
    //         queueQuery.selectedTags.forEach(item => {
    //             if (qString) {
    //                 qString += ',';
    //             }
    //             qString += item.id;
    //         });
    //         params.tags = qString;
    //     }

    //     if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
    //         let qString = '';
    //         queueQuery.selectedTagsExcluded.forEach(item => {
    //             if (qString) {
    //                 qString += ',';
    //             }
    //             qString += item.id;
    //         });
    //         params.excludedTags = qString;
    //     }

    //     if (queueQuery.selectedEntityTypes && queueQuery.selectedEntityTypes.length > 0) {
    //         let typeString = '';
    //         queueQuery.selectedEntityTypes.forEach(type => {
    //             if (typeString) {
    //                 typeString += ',';
    //             }
    //             typeString += type;
    //         });

    //         params.Type_contains = typeString;
    //     }

    //     if (queueQuery.selectedEntityTypesExcluded && queueQuery.selectedEntityTypesExcluded.length > 0) {
    //         let typeString = '';
    //         queueQuery.selectedEntityTypesExcluded.forEach(type => {
    //             if (typeString) {
    //                 typeString += ',';
    //             }
    //             typeString += type;
    //         });

    //         params.Type_ncontains = typeString;
    //     }

    //     if (queueQuery.selectedRegions && queueQuery.selectedRegions.length > 0) {
    //         let qString = '';
    //         queueQuery.selectedRegions.forEach(item => {
    //             if (qString) {
    //                 qString += ',';
    //             }
    //             qString += item.id;
    //         });
    //         params.contractorRegions = qString;
    //     }


    //     if (queueQuery.selectedRegionsExcluded && queueQuery.selectedRegionsExcluded.length > 0) {
    //         let qString = '';
    //         queueQuery.selectedRegionsExcluded.forEach(item => {
    //             if (qString) {
    //                 qString += ',';
    //             }
    //             qString += item.id;
    //         });
    //         params.contractorRegionsExcluded = qString;
    //     }


    //     if (queueQuery.selectedTagsExcluded && queueQuery.selectedTagsExcluded.length > 0) {
    //         let qString = '';
    //         queueQuery.selectedTagsExcluded.forEach(item => {
    //             if (qString) {
    //                 qString += ',';
    //             }
    //             qString += item.id;
    //         });
    //         params.excludedTags = qString;
    //     }

    //     params.skip = skip;
    //     params.take = take;

    //     params.orderby = '';
    //     if (queueQuery.groupByField) {
    //         params.orderby = `${queueQuery.groupByField},`;
    //     }
    //     if (queueQuery.sortByField) {
    //         params.orderby += queueQuery.sortByField + (queueQuery.sortDescending ? ' descending' : '') + ', id';
    //     } else {
    //         params.orderby += 'name,id';
    //     }

    //     return params;
    // }

    getUsersOnRoute(routeId: string, currentUserId: string) {
        return this.api.getArrayNode(`entity/user-route/${routeId}/${currentUserId}`)
    }

    postUsersOnRoute(routeId: string): Promise<{ id: string, name: string, lastModifiedDate: Date }[]> {
        return this.api.postArrayNode(`entity/user-route/${routeId}`);
    }

    getContractorTiers() {
        return this.api.getArrayNode(`entity/contractor-tiers`)
    }

}
