<div flex style="overflow-x: hidden">
  <div id="left-pane">
    <div id="mobile-toggle">
      <div flex1>Selection Criteria</div>
      <div nogrow>
        <button mat-button (click)="showQuerySelection = !showQuerySelection">
          <mat-icon class="direction-arrow" [class.rotated]="showQuerySelection">chevron_right</mat-icon>
        </button>
      </div>
      
    </div>

    <div id="selection-body" [class.body-shown]="showQuerySelection">
      <div style="line-height: 18px" class="sub-heading" bottommargin20>
        Selection<br><span class="little" style="font-size: 18px">Criteria</span>
      </div>
      <app-date-range-display-and-selector memoryKey="contractor-statistics" [(startDate)]="startDate" [(endDate)]="endDate"></app-date-range-display-and-selector>
      <mat-form-field topmargin30 appearance="outline">
          <mat-label>Trade</mat-label>
        <mat-select [(ngModel)]="selectedTradeId" >
          <mat-option *ngFor="let contractorTrade of contractorTrades" [value]="contractorTrade.tradeId">
            {{contractorTrade.tradeName}}</mat-option>
        </mat-select>
      </mat-form-field>

      <button mat-button mat-raised-button color="primary" [disabled]="loading" block large (click)="refreshAll()">
        <mat-spinner *ngIf="loading" class="thirty"></mat-spinner>  
        Load</button>
    </div>
  </div>
  <div id="content-body">
    <div flex class="add-top-margin" id="content-body-inner-container" *ngIf="kpis">
      <div flex1>


        <div>
          <app-survey-view [kpis]="kpis" [selectedTradeId]="selectedTradeId"></app-survey-view>
        </div>

        <div class="add-top-margin">

        </div>
      </div>
      <div flex1>
        <app-recall-view [kpis]="kpis"></app-recall-view>
        <div class="add-top-margin">
          <app-call-types-view [kpis]="kpis"></app-call-types-view>
        </div>
      </div>
      <div flex1>
        <mat-card>
            <div style="position: relative">
                <mat-card-header>
                    <mat-card-title>
                        <i class="material-icons" mat-card-avatar>account_balance_wallet</i>
                        Average Claim Cost
                    </mat-card-title>
                </mat-card-header>
            </div>
            <mat-card-content>
                <div flex *ngIf="currentAcc">
                    <div>
                        <div class="data-label">{{currentAcc?.reportPeriod | date: 'MMMM y'}}
                            Average Claim Cost</div>
                        <h1 id="acc-figure">${{currentAcc?.averageClaimCost | number: '1.2-2'}}
                        </h1>
                    </div>
                    <div>
                        <div class="data-label">Figures</div>

                        <div flex>
                            <div># Claims:</div>
                            <div nogrow style="text-align: right">{{currentAcc?.claimCount}}
                            </div>
                        </div>
                        <div flex>
                            <div>Claim Costs:</div>
                            <div style="text-align: right">
                                ${{currentAcc?.claimCost | number: '1.2-2'}}
                            </div>
                        </div>
                    </div>
                </div>
                <canvas topmargin30 id="acc-history-chart"></canvas>
            </mat-card-content>
        </mat-card>
        <div class="add-top-margin">

        </div>
        <mat-card>
            <div style="position: relative">
                <mat-card-header>
                    <mat-card-title>
                        <i class="material-icons" mat-card-avatar>account_balance_wallet</i>
                        Claim Count
                    </mat-card-title>
                </mat-card-header>
            </div>
            <mat-card-content>
                <div flex *ngIf="currentAcc">
                    <div>
                        <div class="data-label">{{currentAcc?.reportPeriod | date: 'MMMM y'}}
                            CLAIM COUNT</div>
                        <h1 id="acc-figure">{{currentAcc?.claimCount}}
                        </h1>
                    </div>                  
                </div>
                <canvas topmargin30 id="claim-count-chart"></canvas>
            </mat-card-content>
        </mat-card>


        <!-- <mat-card  class="add-top-margin">
          <mat-card-title class="mat-card-title">
            <i class="material-icons mat-card-avatar" mat-card-avatar="">build</i>
            1st Call Fix Rate </mat-card-title>

          <app-dashboard-contractor-kpi-chart title="1st Call Fix Rate" [kpis]="kpis" chartType="bar"
            [multiplicationFactor]="100" targetChartType="line" metricField="firstCallCompletionPercent"
            [targetFill]="false" targetField="firstCallCompleteTarget" yAxisLabel="1st Call Fix Rate %">
          </app-dashboard-contractor-kpi-chart>
        </mat-card> -->

        <!-- <mat-card class="add-top-margin">
          <mat-card-title>
            <i class="material-icons mat-card-avatar" mat-card-avatar="">assignment_turned_in</i> Service Pro Score
          </mat-card-title>
          <div class="data-label">Current Score</div>
          <h1 class="big-data">{{currentContractorScore}}</h1>
          <app-dashboard-contractor-kpi-chart title="Service Pro Score" [kpis]="kpis" chartType="bar"
            targetChartType="line" metricField="contractorRating" [targetFill]="false" targetField="scoreTarget"
            yAxisLabel="Score"></app-dashboard-contractor-kpi-chart>
        </mat-card> -->

        <!-- <mat-card class="add-top-margin">
          <mat-card-title>
            <i class="material-icons mat-card-avatar" mat-card-avatar="">account_balance_wallet</i> Claim Count
          </mat-card-title>
          <app-dashboard-contractor-kpi-chart title="Claim Count" [kpis]="kpis" metricField="claimCount"
            yAxisLabel="Count">
          </app-dashboard-contractor-kpi-chart>
        </mat-card> -->
        
      </div>
    </div>

    <div *ngIf="!kpis && showCriteria" class="fade-in-up" id="criteria-container">
        <img style="height: 200px;" src="/assets/images/filter.png">
        <h1>Criteria</h1>
        <div style="font-style: italic;">
            Select your criteria and press "Load"
        </div>
    </div>
  </div>
</div>