import { Address, Entity, EntityPhoneNumber, PolicyAdjustment, PolicyOption } from '@upkeeplabs/models/cogent';
import { DatePipe } from '@angular/common';
import { ApiEndpoints } from '@cogent/shared/models/common/api-endpoints.model';
export class PolicySummary {
    id: string;
    holder: Entity;
    coHolder: Entity;
    holderAddress: Address;
    propertyAddress: Address;
    planName: string;
    policyNumber: number;
    serviceFeeAmount: number;
    status: string;
    basePrice: number;
    basePriceRecurring: number;
    totalPrice?: number;
    totalPriceRecurring?: number;
    isMonthly: boolean;
    monthlyProcessingFee?: number;
    planId: string;
    termsAndLimitationId?: string;
    effectiveDate?: Date;
    previousPolicyId?: string;
    nextPolicyId?: string;
    canceledDate?: Date;
    totalCashOuts: number;
    totalContractorInvoices: number;
    totalPurchaseOrders: number;
    totalPayments: number;
    holderId: string;
    agentId?: string;
    sellerAgentId?: string;
    escrowNumber: string;
    estimatedClosingDate?: Date;
    closingOfficerId?: string;
    initiatorType: string;
    entityPhoneNumber: EntityPhoneNumber;
    expirationDate?: Date;
    stripeCardId: string;
    coverageType: string;
    options: PolicyOption[];
    canRenew: boolean;
    accountExecutiveId: string;
    accountExecutiveName: string;
    createdDate: Date;
    doNotRenew: boolean;
    monthsOfCoverage: number;
    language: string;
    languageId: string;
    closingOfficer: Entity;
    buyersAgent: Entity;
    titleInvoiceAmount: number;
    adjustments: PolicyAdjustment[];
    createdById: string;
    areaId: string;
    homeownerWaitingPeriod: number;
    salesRegionId; string;
    marketingSourceId: string;
    propertyManagerId: string;
    alwaysSendDocumentsToHomeowner: boolean;
    alwaysSendDocumentsToPropertyManager: boolean;
    propertyId: string;
    autoRenew: boolean;
    versionId: string;
    buyersAgentName: string;
    sellersAgentName: string;
    sellerAgentEmail: string;
    sellersAgentBranchName: string;
    upgradeSuggestionPlanId: string;
    manuallySuspended: boolean;
    billTo: string;
    mlsNumber: string;
    hasPayments: boolean;
    renewalEmployeeId?: string;
    totalPremiumPayments: number;
    promotionCodeId: string;
    altKey: string;
    additionalTitleInvoiceEmails: string;
    newConstructionWaitMonths?: number;
    commissionable?: boolean;
    autoRenewsToYearly: boolean;

    get premiumAmountDue() {
        return this.basePrice - this.totalPremiumPayments;
    }

    get expirationDateShort() {

        if (this.expirationDate == null) {
            return "";
        }

        return this.expirationDate.toLocaleDateString();
    }

    get isWithin60Days() {
        if (!this.effectiveDate) return true;
        const start = Date.UTC(this.createdDate.getFullYear(), this.createdDate.getMonth(), this.createdDate.getDate())
        const end = Date.UTC(this.effectiveDate.getFullYear(), this.effectiveDate.getMonth(), this.effectiveDate.getDate())
        const oneDay = 1000 * 60 * 60 * 24;
        const days = (start - end) / oneDay;
        if (days <= 60)
            return true;
        else
            return false;
    }

    get renewalEmployeeThumbnailUrl() {
        return `${ApiEndpoints.endPointDotNet}entity/thumbnail/${this.renewalEmployeeId}`;
    }

    get closingOfficerEmail() {
        if (this.closingOfficer) { return this.closingOfficer.email; }
        return "";
    }
    get closingOfficerName() {
        if (this.closingOfficer) { return this.closingOfficer.name; }
        return "";
    }

    get buyersAgentEmail() {
        return this.buyersAgent?.email;
    }

    get displayBasePrice(): number {
        if (this.isMonthly) {
            return this.basePriceRecurring + this.monthlyProcessingFee;
        }

        return this.basePrice;
    }

    get profitMargin(): number {
        if (this.totalRevenue === 0) {
            return 0;
        }
        return (this.totalRevenue - this.totalExpenses) / this.totalRevenue;
    }

    get profitMargeGaugeDisplay(): number {
        let pm = this.profitMargin * 100 + 50;

        if (pm < 0) {
            pm = 0;
        } else if (pm > 100) {
            pm = 100;
        }

        return pm;
    }

    get totalRevenue(): number {
        return this.totalPayments;
    }

    get totalExpenses(): number {
        return this.totalContractorInvoices + this.totalPurchaseOrders + this.totalCashOuts;
    }

    get customerName() {
        if (this.holder) {
            return this.holder.name;
        }
        return null;
    }

    get customerFirstName() {
        if (this.holder) {
            return this.holder.name.replace(/ .*/, '');
        }
        return null;
    }

    get customerLastName() {
        if (this.holder) {
            return this.holder.name.replace(/.* /, '');
        }
        return null;
    }

    get customerEmail() {
        if (this.holder) {
            return this.holder.email;
        }
        return null;
    }

    get customerHomeNumber() {
        if (this.entityPhoneNumber) {
            return this.entityPhoneNumber.homeNumber;
        }
        return null;
    }

    get customerMobileNumber() {
        if (this.entityPhoneNumber) {
            return this.entityPhoneNumber.mobileNumber;
        }
        return null;
    }

    get propertyAddress1() {
        if (this.propertyAddress) {
            return this.propertyAddress.address1;
        }
        return null;
    }

    get propertyAddress2() {
        if (this.propertyAddress) {
            return this.propertyAddress.address2;
        }
        return null;
    }

    get propertyCity() {
        if (this.propertyAddress) {
            return this.propertyAddress.city;
        }
        return null;
    }

    get propertyState() {
        if (this.propertyAddress) {
            return this.propertyAddress.state?.toLocaleUpperCase();
        }
        return null;
    }

    get propertyPostalCode() {
        if (this.propertyAddress) {
            return this.propertyAddress.postalCode;
        }
        return null;
    }

    get formattedExpirationDate() {
        const dateFormat = new DatePipe('en-US');

        return dateFormat.transform(this.expirationDate, 'shortDate');
    }


}
